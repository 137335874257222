import Api, { Mock } from "../api";
import loginResponse from "./mock/login.json";

const URL = `${process.env.VUE_APP_API_BASE_URL}/login`;
const URL_LOGOUT = `${process.env.VUE_APP_API_BASE_URL}/logout`;
const URL_REACQUIRE = `${process.env.VUE_APP_API_BASE_URL}/login_info_reacquire`;

/**
login_info_reacquire
こちらに対してAuthorizationをヘッダー入れた状態でリクエストしてもらえればログイン時と同じ情報を返却します。
https://shogatsu-works.slack.com/archives/C0239SPTYMD/p1626928465389800?thread_ts=1626399772.280900&cid=C0239SPTYMD
 */

// VUE_APP_NODE_ENV = local の場合はモックを返す
if (Mock) {
  Mock.onPost(URL).reply(200, {
    status_code: 200,
    message: "",
    result: true,
    contents: loginResponse,
  });
  Mock.onPost(URL_LOGOUT).reply(200, {
    status_code: 200,
    message: "",
    result: true,
    contents: {},
  });
}

export const login = {
  post: (params) => {
    return Api.post(URL, params);
  },
  postReacuire: (params) => {
    return Api.post(URL_REACQUIRE, params);
  },
  logout: () => {
    return Api.post(URL_LOGOUT);
  },
};
