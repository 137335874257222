<template>
  <div class="FormMain">
    <div class="FormMainBody" :style="getBodyStyle">
      <v-card flat>
        <v-card-text>
          <ValidationObserver>
            <v-form autocomplete="off">
              <v-container>
                <v-col cols="6" sm="6" md="6">
                  <v-row>
                    <v-col>
                      <v-list-item-content>
                        <Label
                          label="所属名"
                          class="title-input-item"
                          :editable="false"
                        >
                          <InputText
                            name="field_group_name"
                            :values="formValues"
                            :editable="false"
                            validation_label="所属名"
                            @onInput="onInput('', $event)"
                          />
                        </Label>
                      </v-list-item-content>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="6" sm="6" md="6">
                  <v-row>
                    <v-col>
                      <v-list-item-content>
                        <Label
                          label="氏名"
                          class="title-input-item"
                          :editable="false"
                        >
                          <InputText
                            name="user_name"
                            :values="formValues"
                            :editable="false"
                            validation_label="氏名"
                            @onInput="onInput('', $event)"
                          />
                        </Label>
                      </v-list-item-content>
                    </v-col>
                  </v-row>
                </v-col>
                <template v-if="formValues.role != 1 && formValues.role != 4">
                  <v-col>
                    役割
                    <v-radio-group
                      v-model="formValues.role"
                      :disabled="!editable"
                      @change="value => onInput('role', { name: 'role', value })"
                    >
                      <v-radio value="2">
                        <template v-slot:label>
                          <div class="black--text">予約者</div>
                        </template>
                      </v-radio>
                      <v-radio value="3">
                        <template v-slot:label>
                          <div class="black--text">使用者</div>
                        </template>
                      </v-radio>
                    </v-radio-group>
                  </v-col>
                </template>
                <v-col>
                  利用状態
                  <v-switch
                    class="mt-3"
                    v-model="formValues.usage_status"
                    :disabled="!editable"
                    :label="`利用中`"
                    @change="value => onInput('usage_status', { name: 'usage_status', value })"
                  ></v-switch>
                </v-col>
              </v-container>
            </v-form>
          </ValidationObserver>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>
<script>
import { ValidationObserver } from "vee-validate";
import Label from "@/components/forms/elements/Label";
import SectionLabel from "@/components/forms/elements/SectionLabel";
import InputText from "@/components/forms/elements/InputText";
import SelectSearch from "@/components/forms/elements/SelectSearch";
import SearchSelectListHight from "@/components/forms/elements/SearchSelectListHight";
import InputDatepicker from "@/components/forms/elements/InputDatepicker.vue";
import RadioGroup from "@/components/forms/elements/RadioGroup.vue";
import Select from "@/components/forms/elements/Select.vue";
import FileInput from "@/components/forms/elements/FileInput";
import _ from "lodash";
import { COMPANY_RENTAL, MODE_CREATE } from "@/constants/COMMON";
import { COMPANY_TYPE, RENTAL_USER_TYPE, ENGINEERS } from "@/constants/COMPANIES";
import { USER_ACTIVE, USER_DEACTIVE } from "@/constants/USER";

export default {
  data: () => {
    return {
      formValues: {
        field_group_name: '',
        user_name: '',
      },
      resetValue: {},
      ccusIdCheck: false,
      plateNumberPrefixCheck: false,
      usersEmailCheck: false,
      usersPhoneCheck: false,
      usersNameSeiCheck: false,
      usersNameMeiCheck: false,
      usersKanaSeiCheck: false,
      usersKanaMeiCheck: false,
      COMPANY_TYPE: COMPANY_TYPE,
      RENTAL_USER_TYPE,
      company: {},
      companyType: null,
      COMPANY_RENTAL,
      ENGINEERS,
      MODE_CREATE,
      USER_ACTIVE,
      USER_DEACTIVE,
    };
  },
  components: {
    ValidationObserver,
    Label,
    SectionLabel,
    InputText,
    InputDatepicker,
    Select,
    FileInput,
    SelectSearch,
    SearchSelectListHight,
    RadioGroup,
  },
  props: {
    isRental: Boolean,
    editable: {
      type: Boolean,
      default: false,
    },
    detailData: Object,
    mainHeight: {
      type: Number,
      default: 0,
    },
    formUpdate: Function,
    actions: String,
    active_flg: Number,
  },
  computed: {
    getBodyStyle() {
      let style = `height:${this.mainHeight}px; min-height: 300px`;
      return style;
    },
  },
  mounted() {
    this.$watch(
      () => this.detailData,
      (data) => {
        this.formValues = _.cloneDeep(data);
      },
      {
        immediate: true,
        deep: true
      }
    );
  },
  methods: {
    onInput(parent_name, { name, value }) {
      const formValues = { ...this.formValues };
      if (name == 'usage_status') {
        this.formValues.usage_status = value ? 1 : 0;
      } else {
        this.formValues[name] = value;
      }
      this.$emit("formUpdate", formValues);
    },
  },
};
</script>
<style lang="scss">
@import "@/components/forms/style/forms.scss";

.title-input-item .label {
  color: #000000;
}
<
