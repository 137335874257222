<template>
    <div class="d-flex date" :class="[ { 'satuday': isSatuday, 'sunday': isSunday}]" style="width: 25px; position: relative" :id="dateId">
                <div @mousemove="hoverRight($event)" class="cell" :class="{'active': isActive || isBooked || isBooking,
                'booking-share': isBookingShare,
                'processed-report' : checkReport == BOOKING_REPORT_STATUS.ALL_PROCESSED && checkBlockBooking == BOOKING_REPORT_STATUS.ALL_PROCESSED,
                'normal-report' : checkReport == BOOKING_REPORT_STATUS.NORMAL,
                'no-process-report': (checkReport == BOOKING_REPORT_STATUS.HAVE_NON_PROCESS) || (!isOnTabBooking && checkBlockBooking == BOOKING_REPORT_STATUS.BLOCK_PROCESS_BOOKING && checkReport != BOOKING_REPORT_STATUS.RETURN_FIELD),
                'isDelete': isDelete || isDeleting,
                'gray-field': checkReport == BOOKING_REPORT_STATUS.RETURN_FIELD,
                'processed-report' : checkBlockBooking == BOOKING_REPORT_STATUS.ALL_PROCESSED || checkReport == BOOKING_REPORT_STATUS.ALL_PROCESSED,
                'block-process-booking': isOnTabBooking && checkBlockBooking == BOOKING_REPORT_STATUS.BLOCK_PROCESS_BOOKING && checkReport != BOOKING_REPORT_STATUS.RETURN_FIELD,
                }"
                    style="width:100%" :id="dateId" 
                    @click="clickBooking($event)"
                    @dblclick="dbClickBooking()"
                    >
                <div v-if="isOnTabBooking && isFistCellOfBooked && checkBlockBooking != BOOKING_REPORT_STATUS.BLOCK_PROCESS_BOOKING" :style="{ maxWidth: numberOfCellOfBooked*25 - 5 + 'px', minWidth: numberOfCellOfBooked*25 - 5 + 'px' }"
                    class="company-text"
                >
                {{ formatFieldGroup(bookedId.booking_field_group_name, bookedId.booking_user_name_sei, bookedId.booking_user_name_mei) }}
                </div>
                <div v-if="isOnTabBooking && isFistCellOfBlock && checkBlockBooking == BOOKING_REPORT_STATUS.BLOCK_PROCESS_BOOKING" class="company-text-block" :style="{ maxWidth: numberOfCellOfBlock*25 - 5 + 'px', minWidth: numberOfCellOfBlock*25 - 5 + 'px' }">
                  <v-icon class="company-text-block-icon">mdi-block-helper</v-icon> 故障中
                </div>
                <div v-if="!isOnTabBooking && isFistCellOfBooked" :style="{ maxWidth: numberOfCellOfBooked*25 - 5 + 'px', minWidth: numberOfCellOfBooked*25 - 5 + 'px' }"
                    class="company-text-achievement-top"
                >
                  {{ formatFieldGroup(bookedId.booking_field_group_name, bookedId.booking_user_name_sei, bookedId.booking_user_name_mei) }}
                </div>
                <div v-if="!isOnTabBooking && checkStartBooking" :style="{ maxWidth: '25px', minWidth: '25px' }"
                    class="company-text-achievement-bottom"
                >
                  ◯
                </div>
                <div v-if="!isOnTabBooking && checkBlockBooking == BOOKING_REPORT_STATUS.BLOCK_PROCESS_BOOKING" class="company-text-block" :style="{ maxWidth: numberOfCellOfBlock*25 - 5 + 'px', minWidth: numberOfCellOfBlock*25 - 5 + 'px' }"></div>
                </div>
            <div v-if="isBooked && checkBlockBooking != BOOKING_REPORT_STATUS.BLOCK_PROCESS_BOOKING" class="tooltip" ref="infoBox">
                <div class="d-flex flex-column white--text">
                    <div>{{bookedId ? formatFieldGroup(bookedId.booking_field_group_name, bookedId.booking_user_name_sei, bookedId.booking_user_name_mei) : ''}}</div>
                    <div>{{bookedId ? formatDate(bookedId.use_start_date) : ''}} ~ {{bookedId ? formatDate(bookedId.use_end_date) : ''}}</div>
                </div>
            </div>
    </div>
</template>
<script>

import moment from "moment";
import _ from "lodash";
import {
    BOOKING_TAB_SELECT,
    BOOKING_REPORT_STATUS,
} from "@/constants/BOOKING"
import { format } from 'date-fns';

export default {
    name: 'ToPress',
    components: {},
    props: {
        isPan: {
            type: Boolean,
        },
        index: {
            type: Number,
        },
        period: {
            type: String,
        },
        totalDate: {
            type: Number,
        },
        rowId: {
            type: Number,
        },
        currentBooking: {
            type: Object,
        },
        currentDeleting: {
            type: Object,
        },
        listDate: {
            type: Array,
        },
        bookedTime: {
            type: Array,
        },
        resultTenken: {
            type: Array,
        },
        tab: {
            type: Number,
        },
        returnDate: {
            type: String
        },
        startBookable: {
            type: String
        },
        //MMSV2-1573-start
        dateRangeRepair: {
            type: Array,
        },
        dateRangeRepairStock: {
            type: Array,
        },
        //MMSV2-1573-end
        placeName: {
            type: String,
        },
        useTime: {
          type: Object,
        }
    },
    data() {
        return {
            isActive: false,
            isDelete: false,
            BOOKING_TAB_SELECT,
            BOOKING_REPORT_STATUS,
            spanClass: 'col-1 p-' + this.totalDate + ' bg-light border',
            dateId: 'spanId' + this.rowId + '_' + this.index,
            returnField: false,
        }
    },
    computed: {
        data() {
            return {
                ref: this.rowId + '_' + this.index + '_' + this.period,
                period: this.period,
                date: this.listDate[this.index - 1],
                rowId: this.rowId,
                placeName: this.placeName,
            }
        },
        TAB_SELECT() {
            return Object.keys(BOOKING_TAB_SELECT).map((key) => {
                return BOOKING_TAB_SELECT[key];
            });
        },
        bookedId() {
            let booking = 0;
            this.bookedTime.forEach((value) => {
                let startDate = new Date(value.use_start_date + 'T00:00:00').getTime();
                let endDate = new Date(value.use_end_date + 'T00:00:00').getTime();
                let cellDate = this.listDate[this.index - 1].getTime();
                let startTime = value.use_start_time;
                let endTime = value.use_end_time;
                if((startDate < cellDate || (startDate == cellDate && startTime <= this.period)) 
                && (endDate > cellDate || (endDate == cellDate && endTime >= this.period))){
                    booking = value;
                }
            })
            return booking;
        },
        isOnTabBooking() {
            return this.TAB_SELECT[this.tab].id === this.BOOKING_TAB_SELECT.BOOKING_TAB.id;
        },
        isOnTabAchievement() {
            return this.TAB_SELECT[this.tab].id === this.BOOKING_TAB_SELECT.ACHIEVEMENT_TAB.id;
        },
        checkReport() {
            if(this.isOnTabBooking){
                //Display gray field for return machine
                const dateOnSearchStr = moment(this.listDate[this.index-1]).format('YYYY/MM/DD');
                const today = new Date();
                const date = today.getFullYear()+'/'+(today.getMonth()+1)+'/'+today.getDate(); 
                const currentDateString = moment(date).format('YYYY/MM/DD');
                const returnDateStr = this.returnDate ? moment(this.returnDate).format('YYYY/MM/DD') : null;
                const startBookableStr = this.startBookable ? moment(this.startBookable).format('YYYY/MM/DD') : null;
                if (returnDateStr && dateOnSearchStr >= returnDateStr + 1) {
                    this.returnField = true;
                    return BOOKING_REPORT_STATUS.RETURN_FIELD;
                }

                //Display gray field before start_bookable_date
                if (startBookableStr && dateOnSearchStr < startBookableStr && currentDateString < startBookableStr) {
                    return BOOKING_REPORT_STATUS.RETURN_FIELD;
                }

                return BOOKING_REPORT_STATUS.NO_REPORT;
            }
            const rangeRepairStock = this.dateRangeRepairStock ? this.dateRangeRepairStock.filter(e => {
                return new Date(e.repair_at + 'T00:00:00').getTime() == this.listDate[this.index - 1].getTime() && this.period == e.repair_at_time
            }) : []
            let reportInCell = this.resultTenken.filter(e => {
                return new Date(e.inspection_at_date + 'T00:00:00').getTime() == this.listDate[this.index - 1].getTime() && this.period == e.inspection_at_time
            })
            if(reportInCell.length == 0) {
                if (this.useTime !== undefined && this.useTime?.endByBooking?.length > 0 && this.useTime?.startByBooking?.length > 0 && this.bookedId !== 0) {
                  let endTime = [];
                  let startTime = [];
                  this.useTime?.endByBooking.reduce((acc, booking) => {
                    if (booking.booking_id == this.bookedId?.booking_id) {
                      endTime.push(booking?.use_end_time);
                    }
                  }, {});
                  this.useTime?.startByBooking.reduce((acc, booking) => {
                    if (booking.booking_id == this.bookedId?.booking_id) {
                      startTime.push(booking?.inspection_at);
                    }
                  }, {});
                  let periodEndDate = '';
                  let dateEnd = '';
                  let periodStartDate = '';
                  let dateStart = '';
                  if (startTime?.length == 0 || endTime?.length == 0) {
                    return BOOKING_REPORT_STATUS.NO_REPORT;
                  }
                  let datePress = format(new Date(this.data?.date), "yyyy-MM-dd");
                  if (startTime?.length > 0) {
                    let startTimeFilter = []
                    startTimeFilter = startTime.filter((dateString) => {
                      let date = format(new Date(dateString.substring(0, 10)), "yyyy-MM-dd");
                      return date == datePress;
                    });

                    if (startTimeFilter?.length === 0) {
                      return BOOKING_REPORT_STATUS.NO_REPORT;
                    }
                    let minTime = startTimeFilter.reduce((a, b) => a < b ? a : b);
                    periodStartDate = minTime.substring(11, 13) >= 12 ? 1 : 0;
                    dateStart = format(new Date(minTime.substring(0, 10)), "yyyy-MM-dd");
                  }
                  if (endTime?.length > 0) {
                    let endTimeFilter = []
                    endTimeFilter = endTime.filter((dateString) => {
                      let date = format(new Date(dateString.substring(0, 10)), "yyyy-MM-dd");
                      return date == datePress;
                    });
                    if (endTimeFilter?.length === 0) {
                      return BOOKING_REPORT_STATUS.NO_REPORT;
                    }
                    let maxTime = endTimeFilter.reduce((a, b) => a > b ? a : b);
                    periodEndDate = maxTime.substring(11, 13) >= 12 ? 1 : 0;
                    dateEnd = format(new Date(maxTime.substring(0, 10)), "yyyy-MM-dd");
                  }

                  let dateEndBooked = format(new Date(this.bookedId?.use_end_date), "yyyy-MM-dd");
                  let dateStartBooked = format(new Date(this.bookedId?.use_start_date), "yyyy-MM-dd");
                  if (((dateEnd < dateEndBooked && dateStart > dateStartBooked)
                      || (dateEnd == dateEndBooked && dateStartBooked == dateStart && periodStartDate >= this.bookedId?.use_start_time && periodEndDate <= this.bookedId?.use_end_time)
                      || (dateEnd == dateEndBooked && dateStart > dateStartBooked && periodEndDate <= this.bookedId?.use_end_time)
                      || (dateEnd < dateEndBooked && dateStart == dateStartBooked && periodStartDate >= this.bookedId?.use_start_time)
                      ) && ((periodStartDate < periodEndDate) || (periodStartDate == this.period && periodEndDate == this.period))
                  ) {
                    return BOOKING_REPORT_STATUS.NORMAL;
                  }
                }
                return BOOKING_REPORT_STATUS.NO_REPORT;
            }
            let isHaveNonProcess = 0;
            let isAllProcessed = 0;
            //MMSV2-1573-start
            let isNormal = 1;
            //MMSV2-1573-end
            reportInCell.forEach(e => {
                if(e.result != 0){
                    if(e.repair_status == null){
                        isHaveNonProcess = 1;
                    } else {
                        isAllProcessed = 1;
                    }
                  //MMSV2-1573-start
                  isNormal = 0;
                  //MMSV2-1573-end
                }
            })
            if(isHaveNonProcess) {
                return BOOKING_REPORT_STATUS.HAVE_NON_PROCESS; //have a abnormal report non process
            }
            if(isAllProcessed) {
                return BOOKING_REPORT_STATUS.ALL_PROCESSED; //all abnormal report is processed
            }
            //MMSV2-1573-start
            if(isNormal && rangeRepairStock.length == 0) {
              return BOOKING_REPORT_STATUS.NORMAL;
            }
            return BOOKING_REPORT_STATUS.NO_REPORT;
            //MMSV2-1573-end
        },
        //MMSV2-1573-start
        checkBlockBooking() {
          const rangeRepair = this.dateRangeRepair ? this.dateRangeRepair : []
          // get date current cell
          const dayCell = this.listDate[this.index - 1].getTime();
          if(this.isOnTabBooking){
            // get date current cell
            if(rangeRepair.length > 0) {
              for (let i = 0; i < rangeRepair.length; i++) {
                const e = rangeRepair[i];
                if(e.length == 0) {
                  return BOOKING_REPORT_STATUS.NO_REPORT;
                }
                // case no repair
                if(e.length == 1) {
                  if(new Date(e[0].repair_at + 'T00:00:00').getTime() == dayCell && e[0].repair_at_time <= this.period)
                    return BOOKING_REPORT_STATUS.BLOCK_PROCESS_BOOKING;
                  if(new Date(e[0].repair_at + 'T00:00:00').getTime() < dayCell)
                    return BOOKING_REPORT_STATUS.BLOCK_PROCESS_BOOKING;
                }
              }
            }
            return BOOKING_REPORT_STATUS.NO_REPORT;
          }

          const rangeRepairStock = this.dateRangeRepairStock ? this.dateRangeRepairStock.filter(e => {
              return new Date(e.repair_at + 'T00:00:00').getTime() == this.listDate[this.index - 1].getTime() && this.period == e.repair_at_time
          }) : []
          if(!rangeRepairStock) return BOOKING_REPORT_STATUS.NO_REPORT;
          let isAllProcessed =true;
          rangeRepairStock.forEach(e => {
            if(e.repair_status_stock == null){
              isAllProcessed = false;
            }
          })
          if(rangeRepair.length > 0) {
            for (let i = 0; i < rangeRepair.length; i++) {
              const e = rangeRepair[i];
              if(new Date(e[0].repair_at + 'T00:00:00').getTime() == dayCell && e[0].repair_at_time == this.period) {
                if(isAllProcessed) {
                  //all abnormal report is processed
                  return BOOKING_REPORT_STATUS.ALL_PROCESSED;
                }
                return BOOKING_REPORT_STATUS.BLOCK_PROCESS_BOOKING;
              }
            }
          }
          return BOOKING_REPORT_STATUS.NO_REPORT;
        },
        //MMSV2-1573-end
        isBooking() {
            if(!this.currentBooking.use_start_date || !this.currentBooking.use_end_date || this.currentBooking.rowId != this.rowId) {
                return false;
            }
            let currentBookingStartDate = this.currentBooking.use_start_date.getTime();
            let currentBookingEndDate = this.currentBooking.use_end_date.getTime();
            let cellDate = this.listDate[this.index - 1].getTime();
            let currentBookingStartTime = this.currentBooking.use_start_time;
            let currentBookingEndTime = this.currentBooking.use_end_time;

            if((currentBookingStartDate < cellDate || (currentBookingStartDate == cellDate && currentBookingStartTime <= this.period)) 
            && (currentBookingEndDate > cellDate || (currentBookingEndDate == cellDate && currentBookingEndTime >= this.period))){
                return true;
            }
            return false;
        },
        isDeleting() {
            if (!this.isBooked) {
              return false;
            }
            if(!this.currentDeleting.use_start_date || !this.currentDeleting.use_end_date || this.currentDeleting.rowId != this.rowId) {
              return false;
            }
            let currentDeletingStartDate = this.currentDeleting.use_start_date.getTime();
            let currentDeletingEndDate = this.currentDeleting.use_end_date.getTime();
            let cellDate = this.listDate[this.index - 1].getTime();
            let currentDeletingStartTime = this.currentDeleting.use_start_time;
            let currentDeletingEndTime = this.currentDeleting.use_end_time;

            if((currentDeletingStartDate < cellDate || (currentDeletingStartDate == cellDate && currentDeletingStartTime <= this.period))
                && (currentDeletingEndDate > cellDate || (currentDeletingEndDate == cellDate && currentDeletingEndTime >= this.period))){
                return true;
            }
            return false;
        },
        isBooked() {
            return !!this.bookedId;
        },
        isFistCellOfBooked() {
            if(this.isBooked) {
                let bookedStartDate = new Date(this.bookedId.use_start_date + 'T00:00:00').getTime();
                let bookedStartTime = this.bookedId.use_start_time;
                let cellDate = this.listDate[this.index - 1].getTime();
                if((this.index == 1 && this.period == 0) || (bookedStartDate == cellDate && bookedStartTime == this.period)) {
                    return true;
                }
            }
            return false;
        },
        //MMSV2-1573-start
        isNextColumnBlock () {
            if(this.isOnTabBooking && this.checkEndTimeBlock){
                let cellDate = this.listDate[this.index - 1].getTime();
                if (this.checkEndTimeBlock?.date == cellDate && this.checkEndTimeBlock?.period == this.period) return true
                return false
            }
        },
        checkEndTimeBlock () {
            if(this.isOnTabBooking){
                const dayCell = this.listDate[this.index - 1].getTime();
                if(this.dateRangeRepair) {
                    for (let i = 0; i < this.dateRangeRepair.length; i++) {
                        const e = this.dateRangeRepair[i];
                        if(e.length == 0 || e.length == 1) {
                            return  null;
                        }
                        if(e.length == 2) {
                          const eiDateStart = new Date(e[0].repair_at + 'T00:00:00').getTime()
                          const bookUseStartDate = new Date(this.bookedId.use_start_date + 'T00:00:00').getTime()
                          if(eiDateStart <= bookUseStartDate && e[0].repair_at_time == this.bookedId.use_start_time) {
                            let bookedStartDate = new Date(e[1].repair_at + 'T00:00:00').getTime();
                            if(bookedStartDate == dayCell && e[1].repair_at_time == 0) {
                                return  {
                                    period: 1,
                                    date: bookedStartDate
                                }
                            }
                            if (bookedStartDate == dayCell - 86400000 && e[1].repair_at_time == 1) {
                                return {
                                    period: 0,
                                    date: dayCell
                                }
                            }
                          }
                        }
                    }
                    return null;
                }
            }
        },
        isFistCellOfBlock() {
          if(this.isOnTabBooking){
            // get date current cell
            const dayCell = this.listDate[this.index - 1].getTime();
            const rangeRepair = this.dateRangeRepair
            if(rangeRepair && rangeRepair.length > 0) {
              for (let i = 0; i < rangeRepair.length; i++) {
                const e = rangeRepair[i];
                let repairAt = new Date(e[0].repair_at + 'T00:00:00').getTime()
                let repairAtTime = e[0].repair_at_time
                if(repairAt == dayCell && repairAtTime == this.period) return true;
              }
            }
            return false;
          }
        },
        numberOfCellOfBlock() {
          if(this.isOnTabBooking){
            // get date current cell
            const dayCell = this.listDate[this.index - 1].getTime();
            const dayEndListDate = this.listDate[this.listDate.length-1].getTime();
            const dayReturnMachine = this.returnDate ? new Date(moment(this.returnDate).format('YYYY-MM-DD') + 'T00:00:00').getTime() : null;
            let maxDayOnlistdate = dayEndListDate
            const rangeRepair = this.dateRangeRepair
            if(rangeRepair && rangeRepair.length > 0) {
              const endOfRangeItem = rangeRepair[rangeRepair.length - 1];
              //if date return machine less than end list date, used date return machine to calculator instead of end list date
              if(dayReturnMachine && dayReturnMachine < dayEndListDate) {
                maxDayOnlistdate = dayReturnMachine
              }
              if(endOfRangeItem.length == 1) {
                const endOfRangeItemRepairAt = new Date(endOfRangeItem[0].repair_at + 'T00:00:00').getTime()
                if(endOfRangeItemRepairAt <= dayCell && endOfRangeItem[0].repair_at_time == this.period) {
                  let res = 2 * (moment(maxDayOnlistdate).diff(moment(endOfRangeItem[0].repair_at), 'days') + 1)
                  res > 4 ? res = 4 : res
                  if(endOfRangeItem[0].repair_at_time == 1) res > 0 ? res-- : 1
                  return res
                }
              }
              let sizeRange = 2
              for (let i = 0; i < rangeRepair.length; i++) {
                const ei = rangeRepair[i]
                const eiDateStart = new Date(ei[0].repair_at + 'T00:00:00').getTime()
                const eiDateEnd = new Date(ei[1].repair_at + 'T00:00:00').getTime()
                if(eiDateStart == dayCell && ei[0].repair_at_time == this.period) {
                  const j = i+1
                  if(j < rangeRepair.length) {
                    const ej = rangeRepair[j]
                    const ejDateStart = new Date(ej[0].repair_at + 'T00:00:00').getTime()
                    if(eiDateEnd <= ejDateStart) {
                      if(eiDateEnd <= maxDayOnlistdate) {
                        sizeRange = 2 * (moment(eiDateEnd).diff(moment(eiDateStart), 'days') + 1)
                      } else {
                        sizeRange = 2 * (moment(maxDayOnlistdate).diff(moment(eiDateStart), 'days') + 1)
                      }
                      sizeRange > 4 ? sizeRange = 4 : sizeRange
                      if(ei[0].repair_at_time == 1) sizeRange > 0 ? sizeRange-- : 1
                      if(ei[1].repair_at_time == 0) sizeRange > 0 ? sizeRange-- : 1
                    } else {
                      if(ejDateStart <= maxDayOnlistdate) {
                        sizeRange = 2 * (moment(ejDateStart).diff(moment(eiDateStart), 'days') + 1)
                      } else {
                        sizeRange = 2 * (moment(maxDayOnlistdate).diff(moment(eiDateStart), 'days') + 1)
                      }
                      sizeRange > 4 ? sizeRange = 4 : sizeRange
                      if(ei[0].repair_at_time == 1) sizeRange > 0 ? sizeRange-- : 1
                      if(ej[0].repair_at_time == 0) sizeRange > 0 ? sizeRange-- : 1
                    }
                    return sizeRange
                  } else {
                    if(eiDateEnd <= maxDayOnlistdate) {
                      sizeRange = 2 * (moment(eiDateEnd).diff(moment(eiDateStart), 'days') + 1)
                    } else {
                      sizeRange = 2 * (moment(maxDayOnlistdate).diff(moment(eiDateStart), 'days') + 1)
                    }
                    sizeRange > 4 ? sizeRange = 4 : sizeRange
                    if(ei[0].repair_at_time == 1) sizeRange > 0 ? sizeRange-- : 1
                    if(ei[1].repair_at_time == 0) sizeRange > 0 ? sizeRange-- : 1
                    return sizeRange
                  }
                }
              }
              return 2 * sizeRange
            }
            return 0
          }
        },
        //MMSV2-1573-end
        numberOfCellOfBooked() {
          if(!this.isBooked) {
              return 0;
          }
          let res = 0;
          //MMSV2-1573-start
          const bookUseStartDate = this.bookedId.use_start_time == 0 
            ? new Date(this.bookedId.use_start_date + 'T00:00:00').getTime()
            : new Date(this.bookedId.use_start_date + 'T12:00:00').getTime()
          const bookUseEndDate = this.bookedId.use_end_time == 0 
            ? new Date(this.bookedId.use_end_date + 'T11:59:59').getTime()
            : new Date(this.bookedId.use_end_date + 'T23:59:59').getTime()
          const endListDate = new Date(this.listDate[this.listDate.length-1]).getTime()
          const rangeRepair = this.dateRangeRepair
          if(this.index == 1) {
            if(bookUseEndDate <= endListDate) {
              res = 2 * (moment(this.bookedId.use_end_date).diff(moment(this.listDate[this.index - 1]), 'days') + 1)
              if(rangeRepair && rangeRepair.length > 0) {
                for (let i = 0; i < rangeRepair.length; i++) {
                  const ei = rangeRepair[i]
                  const eiDateStart = ei[0].repair_at_time == 0
                    ? new Date(ei[0].repair_at + 'T00:00:00').getTime()
                    : new Date(ei[0].repair_at + 'T12:00:00').getTime()
                  if (this.isOnTabBooking) {
                    if(bookUseStartDate < eiDateStart && eiDateStart <= bookUseEndDate && rangeRepair[rangeRepair.length - 1].length === 1) {
                      res = 2 * (moment(ei[0].repair_at).diff(moment(this.listDate[this.index - 1]), 'days') + 1);
                      if(ei[0].repair_at_time == 1) res--
                      if(ei[0].repair_at_time == 0) res = res - 2
                    }
                  }
                  if(bookUseStartDate == eiDateStart) {
                    if(ei[0].repair_at_time == 1) res--
                  }
                }
              }
            } else {
              res = 2 * (moment(this.listDate[this.listDate.length-1]).diff(moment(this.listDate[this.index - 1]), 'days') + 1)
              if(rangeRepair && rangeRepair.length > 0) {
                for (let i = 0; i < rangeRepair.length; i++) {
                  const ei = rangeRepair[i]
                  const eiDateStart = ei[0].repair_at_time == 0
                    ? new Date(ei[0].repair_at + 'T00:00:00').getTime()
                    : new Date(ei[0].repair_at + 'T12:00:00').getTime()
                  if(bookUseStartDate < eiDateStart && eiDateStart <= endListDate) {
                    res = 2 * (moment(ei[0].repair_at).diff(moment(this.listDate[this.index - 1]), 'days') + 1);
                    if(ei[0].repair_at_time == 1) res--
                    if(ei[0].repair_at_time == 0) res = res - 2
                  }
                  if(bookUseStartDate == eiDateStart) {
                    if(ei[0].repair_at_time == 1) res--
                  }
                }
              }
            }
            if(this.period == 1) {
                res--;
            }
            if(this.bookedId.use_end_time == 0 && moment(this.bookedId.use_end_date).diff(moment(this.listDate[this.listDate.length-1])) <= 0) {
                res--;
            }
          } else if(this.index == 31){
              res = 2;
              if(this.bookedId.use_start_time == 1 ){
                res--
              }
              if(this.bookedId.use_end_time == 0 && moment(this.bookedId.use_end_date).diff(moment(this.listDate[this.listDate.length-1])) <= 0) {
                res--
              }
          } else {
            if(bookUseEndDate > endListDate) {
              res = 2 * (moment(this.listDate[this.listDate.length-1]).diff(moment(this.bookedId.use_start_date), 'days') + 1);
              if(rangeRepair && rangeRepair.length > 0) {
                for (let i = 0; i < rangeRepair.length; i++) {
                  const ei = rangeRepair[i]
                  const eiDateStart = ei[0].repair_at_time == 0
                    ? new Date(ei[0].repair_at + 'T00:00:00').getTime()
                    : new Date(ei[0].repair_at + 'T12:00:00').getTime()
                  if (this.isOnTabBooking) {
                    if(bookUseStartDate < eiDateStart && eiDateStart <= endListDate && rangeRepair[rangeRepair.length - 1].length === 1) {
                      res = 2 * (moment(ei[0].repair_at).diff(moment(this.bookedId.use_start_date), 'days') + 1);
                      if(ei[0].repair_at_time == 1) res--
                      if(ei[0].repair_at_time == 0) res = res - 2
                    }
                  }
                  if(bookUseStartDate == eiDateStart) {
                    if(ei[0].repair_at_time == 1) res--
                  }
                }
              }
            } else {
              res = 2 * (moment(this.bookedId.use_end_date).diff(moment(this.bookedId.use_start_date), 'days') + 1);
              if(rangeRepair && rangeRepair.length > 0) {
                for (let i = 0; i < rangeRepair.length; i++) {
                  const ei = rangeRepair[i]
                  const eiDateStart = ei[0].repair_at_time == 0
                    ? new Date(ei[0].repair_at + 'T00:00:00').getTime()
                    : new Date(ei[0].repair_at + 'T12:00:00').getTime()
                  if (this.isOnTabBooking) {
                    if(bookUseStartDate < eiDateStart && eiDateStart <= bookUseEndDate && rangeRepair[rangeRepair.length - 1].length === 1) {
                      res = 2 * (moment(ei[0].repair_at).diff(moment(this.bookedId.use_start_date), 'days') + 1);
                      if(ei[0].repair_at_time == 1) res--
                      if(ei[0].repair_at_time == 0) res = res - 2
                    }
                  }
                  if(bookUseStartDate == eiDateStart) {
                    if(ei[0].repair_at_time == 1) res--
                  }
                }
              }
            }
            if(this.bookedId.use_start_time == 1 ){
              res--;
            }
            if(this.bookedId.use_end_time == 0 && moment(this.bookedId.use_end_date).diff(moment(this.listDate[this.listDate.length-1])) <= 0) {
              res--;
            }
          }

          if (this.isNextColumnBlock && this.checkEndTimeBlock) {
            if(this.index < 30) {
              res = 2 * (moment(this.bookedId.use_end_date).diff(moment(new Date(this.checkEndTimeBlock.date)), 'days') + 1);
              if(rangeRepair && rangeRepair.length > 0) {
                for (let i = 0; i < rangeRepair.length; i++) {
                  const ei = rangeRepair[i]
                  const eiDateStart = ei[0].repair_at_time == 0
                    ? new Date(ei[0].repair_at + 'T00:00:00').getTime()
                    : new Date(ei[0].repair_at + 'T12:00:00').getTime()
                  if(bookUseStartDate < eiDateStart && eiDateStart <= bookUseEndDate && rangeRepair[rangeRepair.length - 1].length === 1) {
                    res = 2 * (moment(ei[0].repair_at).diff(moment(new Date(this.checkEndTimeBlock.date)), 'days') + 1);
                    if(ei[0].repair_at_time == 1) res--
                    if(ei[0].repair_at_time == 0) res = res - 2
                  }
                  if(bookUseStartDate == eiDateStart) {
                    if(ei[0].repair_at_time == 1) res--
                  }
                }
              }
            }
            if (this.bookedId.use_end_time == 0) {
              res--
            }
            if (this.checkEndTimeBlock?.period == 1) {
              res--
            }
          }
          return res > 0 ? res : 1;
          //MMSV2-1573-end
        },
        isSatuday() {
            return this.listDate[this.index - 1]?.getDay() === 6;
        },
        isSunday() {
            return this.listDate[this.index - 1]?.getDay() === 0;
        },
        isBookingShare () {
            return this.bookedId.booking_id && !this.bookedId.booking_company_id && !this.bookedId.booking_user_id
        },
        checkStartBooking () {
          if (this.useTime) {
            let isStartBooking = false;
            const date = format(this.listDate[this.index - 1], 'yyyy-MM-dd');

            const startTime = _.cloneDeep(this.useTime.start);
            let maxStartTime = null;
            if (startTime.length) {
              startTime.sort((a, b) => {
                const dateA = new Date(a);
                const dateB = new Date(b);
                return dateA - dateB;
              });

              //get maxStartTime
              for (let i = startTime.length - 1; i >= 0; i--) {
                if (this.period == 0) {
                  if (
                    startTime[i].substring(0,10) === date &&
                    startTime[i].substring(11, 13) < 12
                  ) {
                    maxStartTime = startTime[i];
                    break; 
                  }
                } else {
                  if (
                    startTime[i].substring(0,10) === date &&
                    startTime[i].substring(11, 13) >= 12
                  ) {
                    maxStartTime = startTime[i];
                    break; 
                  }
                }
              }
            }

            const endTime = _.cloneDeep(this.useTime.end);
            let maxEndTime = null;
            if (endTime.length) {
              endTime.sort((a, b) => {
                const dateA = new Date(a);
                const dateB = new Date(b);
                return dateA - dateB;
              });

              for (let i = endTime.length - 1; i >= 0; i--) {
                // get maxEndTime
                if (
                  endTime[i].substring(0,10) === date
                ) {
                  maxEndTime = endTime[i];
                  break; 
                }
              }
            }

            if (!maxEndTime) {
              if (maxStartTime) {
                isStartBooking = true;
              }
            } else {
              if (maxStartTime && new Date(maxStartTime) > new Date(maxEndTime)) {
                isStartBooking = true;
              }
            }
            return isStartBooking;
          } else {
            return false
          }
        },
    },
    created() { 
    },
    methods: {
        clickBooking(e){
            if(!this.returnField) {
                //MMSV2-1573-start
                if(this.isOnTabBooking){
                  if(this.checkBlockBooking == BOOKING_REPORT_STATUS.BLOCK_PROCESS_BOOKING) {
                    return;
                  }
                }
                //MMSV2-1573-end
                if (e.detail == 1) {
                    if(this.isOnTabAchievement) {
                      //MMSV2-1573-start
                      if(
                        this.checkReport == BOOKING_REPORT_STATUS.ALL_PROCESSED 
                        || this.checkReport == BOOKING_REPORT_STATUS.HAVE_NON_PROCESS 
                        || this.checkReport == BOOKING_REPORT_STATUS.NORMAL
                        || this.checkBlockBooking == BOOKING_REPORT_STATUS.BLOCK_PROCESS_BOOKING
                        || this.checkBlockBooking == BOOKING_REPORT_STATUS.ALL_PROCESSED
                      ){
                      //MMSV2-1573-end
                          this.$emit('open-form-list-tenken', {
                              tenken_date: this.data.date,
                              half_day: this.data.period,
                              field_machine_id: this.data.rowId,
                          });
                      }
                      return;
                    }
                    if(this.isBooked) {
                        this.isDelete = true;
                        this.data.booking_id = this.bookedId.booking_id;
                        const div = e.target;
                        const rect = div.getBoundingClientRect();
                        this.data.clientX = rect.left;
                        this.data.clientY = rect.top;
                        this.data.x = rect.x;
                        this.data.innerWidth = window.innerWidth;
                        this.data.bookedId = this.bookedId;
                        this.$emit('delete-booking', this.data);
                        return;
                    }

                    this.isActive = true;
                    this.$emit('click-booking', this.data);
                }
            }
            return;
        },
        dbClickBooking(){
            this.inDelete();
            //MMSV2-1573-start
            if(this.isBooked && !this.isOnTabAchievement && this.checkBlockBooking != BOOKING_REPORT_STATUS.BLOCK_PROCESS_BOOKING) {
            //MMSV2-1573-end
                this.$emit('open-booked', this.bookedId);
                return;
            }
        },
        inActive() {
            this.isActive = false;
        },
        inDelete() {
          this.isDelete = false;
        },
        formatDate(date) {
            return moment(date, 'YYYY-MM-DD').format('YYYY/MM/DD');
        },
        formatFieldGroup(company, nameSei, nameMei) {
            if (this.isBookingShare) return '共用期間'
            return `${company} (${nameSei} ${nameMei})`
        },
        hoverRight(e) {
            const tooltipEl = e.currentTarget.nextElementSibling;
            if (tooltipEl) {
                let widthTooltip = this.$refs.infoBox.clientWidth;
                tooltipEl.classList.remove("tooltip-right");
                if (window.innerWidth - e.clientX < widthTooltip) {
                    tooltipEl.classList.add("tooltip-right");
                }
            }
        }
    },
    watch: {
        selectedList(val) {
            if(!val.includes(this.data)) {
                this.isActive = false;
            }
        }
    }
};
</script>
<style lang="scss" scoped>
    .bg-light {
        background-color: #33b857;
    }
    .bg-dark {
        background-color: #631899;
    }
    .col-1 {
        height: 80px;
    }
    .date{
        background: white;
        &:hover .tooltip{
            display: block;
        }
    }
    .date.satuday {
        background: #EEF1FA;
    }
    .date.sunday{
        background: #FFE5E5;
    }
    .cell{
        background: transparent;
    }
    .cell:hover{
        background: #eee;
    }
    .cell.active{
        background: #FFE699;
    }
    .cell.isDelete{
      background: #808080B2 !important;
    }
    .cell.booking-share{
        background: #caf2ca;
    }
    .cell.processed-report{
      background: #2AAAE2;
    }
    .cell.no-process-report{
        background: #FF0000;
    }
    /* MMSV2-1573-start */
    .cell.block-process-booking {
        background: rgb(255, 100, 100);
    }
    /* MMSV2-1573-end */
    .cell.normal-report{
        background: #EBF4E9;
    }
    .company-text{
        position: absolute;
        top: 30%;
        left: 5px;
        line-height: 24px;
        height: 24px;
        z-index: 1;
        font-weight:500;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        pointer-events: none;
    }
    .company-text-achievement-top{
        position: absolute;
        top: 11%;
        left: 5px;
        height: 24px;
        z-index: 1;
        font-weight:500;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        pointer-events: none;
    }
    .company-text-achievement-bottom{
        position: absolute;
        bottom: 11%;
        left: 5px;
        height: 24px;
        z-index: 1;
        font-weight:500;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        pointer-events: none;
    }
    /* MMSV2-1573-start */
    .company-text-block{
        position: absolute;
        top: 30%;
        left: 5px;
        line-height: 24px;
        height: 24px;
        z-index: 1;
        font-weight:500;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        pointer-events: none;
        color: #EBF4E9;
    }
    .company-text-block-icon{
        font-size: 20px !important;
        color: #EBF4E9 !important;
    }
    /* MMSV2-1573-end */
    .tooltip{
        position: absolute;
        display: none;
        background: #0082D8;
        bottom: -90%;
        width: max-content;
        z-index: 2;
        padding: 5px 10px;
        border-radius: 10px;
        max-width: 350px;
        word-wrap: break-word;
    }
    .tooltip-right {
        right: 0px !important;
    }
    .gray-field {
        background: rgba(190, 190, 190);
        pointer-events: none;
    }
</style>
