<template>
  <div>
    <DefaultLayout>
      <template #mainHeader>
        <!--  (共通) 会社ヘッダー -->
        <CompanyHeader/>
      </template>
      <template #page="{ layoutParams }">
        <Loading v-if="isLoading"/>
        <ValidationObserver v-slot="{ invalid }">
          <TableLayout
            :layoutParams="layoutParams"
            :hideFooter="searchParams.pageCount >= searchParams.total_item"
            ref="myTableLayout"
          >
            <template #tableHeader="{ updateHeader }"> 
              <TableHeader
                ref="tableHeader"
                :pageTitle="PAGE_TITLE"
                :multiRemoveStatus="disableRemoveBtn"
                :isDelete="admin_flg == ADMIN_FLG ? true : false"
                :approveStatus="disableApproveBtn"
                :updateHeader="updateHeader"
                :isOpenFormApprove="admin_flg == ADMIN_FLG ? true : false"
                :isAddNew="admin_flg == ADMIN_FLG ? true : false"
                :isOpenFormContractors="admin_flg != ADMIN_FLG && companyType == COMPANY_BUILDING ? true : false"
                @openRemoveDialog="openRemoveDialog"
                @openItemForm="openNewItemForm"
                @openFormApprove="openFormApprove"
              >
                <SearchFormWrapper>
                  <Label label="元請名" class="title-input-item">
                    <InputText
                      name="main_contractor_name"
                      :editable="true"
                      :values="searchParams"
                      @onInput="onChangeSearchParams"
                    />
                  </Label>
                  <Label label="現場名" class="title-input-item">
                    <InputText
                      name="field_name"
                      :editable="true"
                      :values="searchParams"
                      @onInput="onChangeSearchParams"
                    />
                  </Label>
                  <template v-if="admin_flg !== ADMIN_FLG">
                    <Label label="所属名" class="title-input-item">
                      <InputText
                        name="group_name"
                        :editable="true"
                        :values="searchParams"
                        @onInput="onChangeSearchParams"
                      />
                    </Label>
                  </template>
                  <!-- ロール -->
                  <Label label="竣工予定年月" class="title-input-item" style="max-width: 224px">
                    <InputMonthpicker
                      name="start_use_field_date"
                      :editable="true"
                      :values="searchParams"
                      @onInput="onChangeSearchParams"
                      :flagNull="true"
                      :validation_rules="getStartMonthRules()"
                    />
                  </Label>
                  <span class="mt-12 ml-3">
                      <v-icon>mdi-tilde</v-icon>
                  </span>
                  <Label label="竣工予定年月" class="title-input-item" style="max-width: 224px">
                    <InputMonthpicker
                      name="end_use_field_date"
                      :editable="true"
                      :values="searchParams"
                      @onInput="onChangeSearchParams"
                      :flagNull="true"
                      :validation_rules="getEndMonthRules()"
                    />
                  </Label>
                  <Label label="契約" class="title-input-item">
                    <TabSelect
                      name="agreements"
                      :items="SELECT_TAB"
                      :editable="true"
                      :values="searchParams"
                      @onInput="onChangeAgreement"
                    />
                  </Label>
                  <v-spacer></v-spacer>
                  <Label>
                    <v-btn class="mr-6" color="primary" depressed @click="onSearch(invalid)">
                      検索
                    </v-btn>
                  </Label>
                </SearchFormWrapper>
              </TableHeader>
              <!-- 
                (共通)
                ソートのレイアウトを調整するラッパーコンポーネントです
              -->
              <TableSortWrapper>
                <!--  
                  (共通) ソート 
                  ソート項目、ソート順、表示件数の選択、総件数の表示
                -->
                <TableSort
                  :values="searchParams"
                  :sort_items="SORT_ITEMS"
                  sort_item_text="name"
                  sort_item_value="id"
                  :page_counts_options="PAGE_COUNT_OPTIONS"
                  :sort_order_options="SORT_ORDERS"
                  :total_item="searchParams.total_item"
                  @onInput="onChangeSortParams(...arguments, invalid)"
                  class="ml-3"
                />
              </TableSortWrapper>
            </template>
            <!-- 
              (共通)テーブル
              v-data-table自体は共通ですが、
              カラムによって変更をしたい場合はそれぞれ実装してください。
            -->
            <template #tableBody="{ tableHeight }">
              <v-data-table
                item-key="field_id"
                v-model="selectedItems"
                :headers="admin_flg != ADMIN_FLG ? FIELD_TABLE_LABELS_USER : TABLE_LABELS"
                :items="items"
                :items-per-page="searchParams.pageCount"
                :height="searchParams.pageCount >= searchParams.total_item ? tableHeight : tableHeight -45"
                fixed-header
                hide-default-footer
                disable-sort
                :class="admin_flg == ADMIN_FLG ? 'admin-table elevation-1 v-data-table__wrapper' : 'elevation-1 v-data-table__wrapper'"
                :show-select="admin_flg == ADMIN_FLG ? true : false"
                :noDataText="NO_DATA_MESSAGE"
                @item-selected="updateSelectedItems"
                @toggle-select-all="selectAllItems"
                @click:row="openItemForm"
                @update:searchParams.currentPage="$vuetify.goTo(0)"
                :key=searchParams.currentPage
                style="box-shadow: none !important;"
              >
                <template v-slot:[`item.active_flg`]="{ item }">
                  <div
                    style="
                      overflow: hidden;
                      text-overflow: ellipsis;
                      max-width: 280px;
                      white-space: nowrap
                    "
                  >
                    <span
                      rounded
                      v-if="item.active_flg == ACTIVE_FLG_NONE"
                      style="background-color: #e5951d"
                      class="active_flg text-center"
                    >
                    {{ convertName("利用申請中", 40) }}
                    </span>
                    <span
                      rounded
                      v-else-if="item.active_flg == ACTIVE_FLG_IN_USE"
                      style="background-color: #274fc2; color: white"
                      class="active_flg text-center"
                    >{{ convertName("利用中", 40) }}
                    </span>
                    <span
                      rounded
                      v-else-if="item.active_flg == ACTIVE_FLG_APPLY_FOR_TERMINATION"
                      style="background-color: #b00020; color: white"
                      class="active_flg text-center"
                    >{{ convertName("終了申請中", 40) }}
                    </span
                    >
                    <span
                      rounded
                      v-else
                      style="background-color: rgba(33, 33, 33, 0.08)"
                      class="active_flg text-center"
                    >{{ convertName("終了", 40) }}
                    </span
                    >
                  </div>
                </template>
                <template v-slot:[`item.field_code`]="{ item }">
                  <div
                    v-if="admin_flg == ADMIN_FLG || item.active_flg == ACTIVE_FLG_IN_USE "
                    style="
                      overflow: hidden;
                      text-overflow: ellipsis;
                      max-width: 380px;
                      white-space: nowrap
                    "
                  >
                    {{ convertName(item.field_code, 50) }}
                  </div>
                </template>
                <template v-slot:[`item.compnay_name`]="{ item }">
                  <div
                    style="
                      overflow: hidden;
                      text-overflow: ellipsis;
                      max-width: 380px;
                      white-space: nowrap
                    "
                  >
                    {{ convertName(item.compnay_name, 65) }}
                  </div>
                </template>
                <template v-slot:[`item.name`]="{ item }">
                  <div
                    style="
                      overflow: hidden;
                      text-overflow: ellipsis;
                      max-width: 380px;
                      white-space: nowrap
                    "
                  >
                    {{ convertName(item.name, 65) }}
                  </div>
                </template>
                <template v-slot:[`item.manager_user`]="{ item }">
                  <div
                    style="
                      overflow: hidden;
                      text-overflow: ellipsis;
                      max-width: 380px;
                      white-space: nowrap
                    "
                  >
                    {{ convertName(item.manager_user, 50) }}
                  </div>
                </template>
                <template v-slot:[`item.field_end_date`]="{ item }">
                  <div
                    style="
                      overflow: hidden;
                      text-overflow: ellipsis;
                      max-width: 380px;
                      white-space: nowrap
                    "
                  >
                    {{ convertFieldEnDate(item.field_end_date, 50) }}
                  </div>
                </template>
                <template v-slot:[`item.action`]="{ item }">
                  <div
                    v-if="admin_flg == ADMIN_FLG "
                    style="
                        overflow: hidden;
                      text-overflow: ellipsis;
                      max-width: 380px;
                      white-space: nowrap
                    "
                  >
                    <v-menu offset-y left :close-on-content-click="false">
                      <template v-slot:activator="{ on: menu }">
                        <v-tooltip >
                          <template v-slot:activator="{ on: tooltip }">
                            <v-btn
                              depressed
                              style="background: none"
                              v-show="
                                item.active_flg == ACTIVE_FLG_IN_USE  ||
                                item.active_flg == ACTIVE_FLG_END  &&
                                  item.end_use_field_date >= date_now
                              "
                              v-on="{ ...tooltip, ...menu }"
                            >
                              <v-icon>mdi-dots-vertical</v-icon>
                            </v-btn>
                            <v-btn
                              depressed
                              style="background: none"
                              v-show="
                              item.active_flg == ACTIVE_FLG_END  && item.field_end_date < date_now ||
                              item.active_flg == ACTIVE_FLG_COMPLETED
                              "
                              v-on="{ ...tooltip, ...menu }"
                            >
                              <v-icon>mdi-dots-vertical</v-icon>
                            </v-btn>
                          </template>
                        </v-tooltip>
                      </template>

                      <v-list>
                        <v-list-item>
                          <v-sheet
                            @click="openEndUseDialog(item)"
                            class="action"
                            v-if="
                              item.active_flg == ACTIVE_FLG_IN_USE ||
                              item.active_flg == ACTIVE_FLG_END  && item.end_use_field_date >= date_now
                            "
                          >
                            <v-switch
                              v-model="item.end_use"
                              color="primary"
                              class="disable-events"
                            ><template v-slot:label >
                                <span class="switch-label">利用終了する</span>
                              </template>
                            </v-switch>
                          </v-sheet>

                          <v-sheet
                            class="action"
                            @click="openExtendDialog(item)"
                            v-if="
                              item.active_flg == ACTIVE_FLG_END  && item.field_end_date < date_now ||
                              item.active_flg == ACTIVE_FLG_COMPLETED
                            "
                          >
                            <v-switch
                              v-model="item.extend"
                              color="primary"
                              class="disable-events"
                            ><template v-slot:label >
                                <span class="switch-label">現場の帳票出力を許可する</span>
                              </template>
                            </v-switch>
                          </v-sheet>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </div>
                  <div
                    v-if="admin_flg != ADMIN_FLG && item.active_flg == ACTIVE_FLG_IN_USE"
                    style="
                      overflow: hidden;
                      text-overflow: ellipsis;
                      max-width: 380px;
                      white-space: nowrap
                    "
                    v-on:click.stop
                  >
                    <v-btn
                      depressed
                      style="background: none"
                      @click="showQRCode(item)"
                    >
                      <v-icon>mdi-qrcode</v-icon>
                    </v-btn>
                  </div>
                </template>
              </v-data-table>
            </template>
            <template #tableFooter>
              <!-- (共通) ページネーション -->
              <Pagination
                :current="searchParams.currentPage"
                :total="searchParams.totalPage"
                @pageUpdate="pageUpdate"
                style="background: white; position: fixed; width: 100%; bottom: 0;     "
              />
            </template>
          </TableLayout>
        </ValidationObserver>
      </template>
    </DefaultLayout>

    <!-- model confirm extension of time to issue ballots -->

    <Popup width="480px" :dialog="isShowQR">
      <ShowQR
        @close="closeShowQrCode()"
        title="現場QRコード"
        :data="dataField"
        text="現場コード："
        type="field"
      />
    </Popup>

    <Popup width="480px" :dialog="popups.isShowRemoveDialog">
      <ConfirmRemoveDialog
        @close="closeRemoveDialog()"
        @yes="removeItems()"
        title="選択項目の削除"
        text="以下を削除してもよろしいですか？"
        :items="selectedItems"
        warning
      />
    </Popup>

    <Popup v-if="!extend" width="480px" :dialog="popups.isShowExtendDialog">
      <ExtendDialog
        @close="closeRemoveDialog()"
        @yes="extendSite()"
        title="現場の帳票出力を許可する"
        text1="現場の帳票出力を元請ユーザーのみ許可します。"
        text2="再度現場ログインを制限する場合は、利用終了してください。"
        acceptBtn="許可"
        primary
      />
    </Popup>

    <!-- model destroy extension of time to issue ballots -->
    <Popup v-if="extend" width="480px" :dialog="popups.isShowExtendDialog">
      <ExtendDialog
        @close="closeRemoveDialog()"
        @yes="extendSite()"
        title="現場の帳票出力を終了する"
        text1="現場の帳票出力を終了します。"
        acceptBtn="終了"
        warning
      />
    </Popup>

    <!-- model extend end_use -->
    <Popup v-if="!end_use" width="480px" :dialog="popups.isShowEndUseDialog">
      <EndUseDialog
        @close="closeRemoveDialog()"
        @yes="endUse()"
        title="利用終了する"
        text1="現場の利用を終了します。"
        text2="元請ユーザーは現場の帳票出力のみ可能となり、竣工後に現場ログインができなくなります。"
        acceptBtn="利用終了"
        warning
      />
    </Popup>

    <!-- model destroy end use -->
    <Popup v-if="end_use" width="480px" :dialog="popups.isShowEndUseDialog">
      <EndUseDialog
        @close="closeRemoveDialog()"
        @yes="endUse()"
        title="利用終了を取り消す"
        text1="現場の利用終了を取り消します。"
        text2=""
        acceptBtn="取り消し"
        primary
      />
    </Popup>

    <Popup width="480px" :dialog="popups.isShowApproveDialog">
      <ApproveDialog
        @close="closeRemoveDialog()"
        @yes="approve()"
        title="現場申請を承認"
        text="現場の申請を承認します。"
        primary
      />
    </Popup>

    <Popup :dialog="popups.isShowItemForm">
      <FieldForm
        :item="editedItem"
        :isNewItem="isNewItem"
        :isErrorSubmit="isErrorSubmit"
        :isEmitted="isEmitted"
        @cancel="closeItemForm"
        @formUpdate="formUpdate"
        @submit="submitForm"
        :admin_flg="admin_flg"
        :searchParams="apiParams"
        @beforeUpdateItem="beforeUpdateItem"
        :approvalAuto="approvalAuto"
      />
    </Popup>
  </div>
</template>
<script>
/**
 * (共通)
 * テーブル共通のコンポーネント、関数
 */
import {Store} from "@/store/Store.js";
import DefaultLayout from "@/components/layout/DefaultLayout";
import TableLayout from "@/components/layout/TableLayout";
import TableHeader from "@/components/masterTable/elements/TableHeader";
import Pagination from "@/components/masterTable/elements/Pagination";
import SearchFormWrapper from "@/components/masterTable/elements/SearchFormWrapper";
import TableSortWrapper from "@/components/masterTable/elements/TableSortWrapper";
import TableSort from "@/components/masterTable/elements/TableSort";
import Popup from "@/components/common/Popup"; //モーダル用のポップアップ
import ConfirmRemoveDialog from "./components/ConfirmRemoveDialog"; //削除確認ダイアログ
import ExtendDialog from "@/components/dialog/ExtendDialog";
import EndUseDialog from "@/components/dialog/EndUseDialog";
import ApproveDialog from "@/components/dialog/ApproveDialog";
import CompanyHeader from "@/components/companyHeader/CompanyHeader";
import InputMonthpicker from "@/components/forms/elements/InputMonthpicker.vue";
import TabSelect from "@/components/forms/elements/TabSelect";
import ShowQR from "@/components/qr/ShowQRField";

import {
  TABLES_PER_PAGE,
  TABLE_SORT_ORDERS,
  NO_DATA_MESSAGE,
} from "@/constants/COMMON"; //絞り込みフォームで使用

import FieldForm from "@/components/forms/fields/FieldForm";
import InputText from "@/components/forms/elements/InputText"; //絞り込みフォームで使用
import Select from "@/components/forms/elements/Select"; //絞り込みフォームで使用
import Label from "@/components/forms/elements/Label"; //絞り込みフォームで使用
import {
  FIELD_TABLE_LABELS,
  FIELD_SORT_ITEMS,
  FIELD_INITIAL_ITEM,
  FIELD_SELECT_TAB,
  FIELD_TABLE_LABELS_USER
} from "@/constants/FIELDS"; //絞り込みフォームで使用

import _ from "lodash";
import {convertName, dateFormat} from "@/common/helper";
import Loading from "@/components/loading/Loading";
import { HEADER_MENU_ITEMS_ADMIN } from "@/constants/GLOBALHEADER";
import {format} from "date-fns";
import {ValidationProvider, ValidationObserver} from "vee-validate";

/**
 * 定数
 * この一覧ページで使用する固有の定数を定義します。
 * 共通に使用する定数は基本的に@/constants/で定義します。
 * - 定数は大文字で定義します
 * - 定数は基本的にはdataに代入しないで直接参照します
 */

//タイトル
const PAGE_TITLE = "現場";

//１ページあたりのテーブル件数
const PAGE_COUNT = 25;

//１ページあたりのテーブル件数オプション
const PAGE_COUNT_OPTIONS = TABLES_PER_PAGE;

// 昇順降順オプション
const SORT_ORDERS = TABLE_SORT_ORDERS;

//ストア
const STORE = "Fields";

//テーブルヘッダーラベル
const TABLE_LABELS = FIELD_TABLE_LABELS;

//ソート要素
const SORT_ITEMS = FIELD_SORT_ITEMS;

const SELECT_TAB = FIELD_SELECT_TAB;

const INITIAL_ITEM = FIELD_INITIAL_ITEM;

const ADMIN_FLG = 1;

const COMPANY_BUILDING = 1;

const ACTIVE_FLG_NONE = 0;
const ACTIVE_FLG_IN_USE = 1;
const ACTIVE_FLG_APPLY_FOR_TERMINATION = 2;
const ACTIVE_FLG_END = 3;
const ACTIVE_FLG_COMPLETED = 4;

export default {
  head: {
    title() {
      return {inner: "AirLiza", separator: "|", complement: PAGE_TITLE};
    },
  },
  data() {
    return {
      /**
       * (共通)
       */
      PAGE_TITLE,
      TABLE_LABELS,
      SORT_ITEMS,
      SORT_ORDERS,
      PAGE_COUNT_OPTIONS,
      NO_DATA_MESSAGE,
      SELECT_TAB,
      ADMIN_FLG,
      COMPANY_BUILDING,
      ACTIVE_FLG_NONE,
      ACTIVE_FLG_IN_USE,
      ACTIVE_FLG_APPLY_FOR_TERMINATION,
      ACTIVE_FLG_END,
      ACTIVE_FLG_COMPLETED,
      FIELD_TABLE_LABELS_USER,
      admin_flg: null,
      date_now: null,
      end_use: true,
      extend: false,
      isShowQR: false,
      dialog: false,
      dataField: null,
      /**
       * (共通)
       * 一覧データ
       */
      items: [],

      /**
       * (共通)
       * checkbox選択item
       */
      selectedItems: [],
      selectedItemsRemoved: [],

      /**
       * (共通)
       * 編集アイテム
       * 定数から初期値を代入
       */
      editedItem: {},

      companyBranches: {},

      /**
       * (共通)
       * 新規フラグ
       */
      isNewItem: false,

      // 検索パラメータ
      searchParams: {
        field_name: "",
        group_name: "",
        start_use_field_date: "",
        end_use_field_date: "",
        main_contractor_name: "",
        agreement: [],
        pageCount: PAGE_COUNT,
        currentPage: 1,
        totalPage: 1,
        sort: 1, //sortする項目
        asc: true,
      },

      /**
       * (共通)
       * ポップアップの状態管理
       */
      popups: {
        // 追加/編集フォーム
        isShowItemForm: false,
        // 削除確認ダイアログ表示
        isShowRemoveDialog: false,
        isShowExtendDialog: false,
        isShowEndUseDialog: false,
        isShowApproveDialog: false,
        isShowUploadForm: false,
      },
      editSelectOffices: false,
      isErrorSubmit: false,
      isEmitted: false,
      editable: {
        type: Boolean,
        default: false,
      },
      formValues: {},
      min: 0,

      endUseParams: {
        admin_flg: null,
        field_id: 0,
        role: null,
      },

      extendParams: {
        field_id: 0,
      },

      approvePrams: {
        ids: [],
      },
      apiParamsToChild: {},
      flagError: false,
      companyType: null,
      isLoading: false,
      approveArr: [],
      approvalAuto: 0,
    };
  },

  components: {
    //共通のコンポーネント
    DefaultLayout,
    TableLayout,
    TableHeader,
    Pagination,
    SearchFormWrapper,
    TableSortWrapper,
    TableSort,
    CompanyHeader,
    Popup,
    ConfirmRemoveDialog,
    FieldForm,
    InputText,
    Select,
    Label,
    TabSelect,
    InputMonthpicker,
    ExtendDialog,
    EndUseDialog,
    ApproveDialog,
    ShowQR,
    Loading,
    ValidationProvider,
    ValidationObserver
  },
  async mounted() {
    Store.dispatch("GlobalHeader/setInHouseMenu", {
      menuId: HEADER_MENU_ITEMS_ADMIN.SITES.id,
    });

    const user = JSON.parse(sessionStorage.getItem("USER")).Login.user;

    if (user?.admin_flg) {
      this.admin_flg = user?.admin_flg;
    }

    if (user?.company?.company_type) {
      this.companyType = user?.company?.company_type;
    }
    // List company building
    Store.dispatch("Companies/getDataBuilding");
    Store.dispatch("Prefectures/get");

    //Prevent BrowserのBack、Forward
    history.pushState(null, null, location.href);
    window.onpopstate = function () {
      history.go(1);
    };

    /**
     * (共通)
     * ここからapiにリクエストします
     */
    this.getItems();

    //get date today
    this.getDateNow();
    /**
     * (共通)
     * データとページネーションを取得
     */
    this.$watch(
      () => [
        Store.getters[`${STORE}/getData`],
        Store.getters[`${STORE}/getPagination`],
      ],
      (data) => {
        let _items = [...data[0]?.data];
        this.items = _items.map((item) => {
          if (item.active_flg == this.ACTIVE_FLG_IN_USE ) {
            item.end_use = false;
          }

          if (
            item.active_flg == ACTIVE_FLG_END  &&
            item.end_use_field_date >= this.date_now
          ) {
            item.end_use = true;
          }

          if (item.active_flg == ACTIVE_FLG_END  && item.field_end_date < this.date_now) {
            item.extend = true;
          }

          if (item.active_flg == ACTIVE_FLG_COMPLETED ) {
            item.extend = false;
          }

          return {
            ...item,
          };
        });

        let searchParams = {...this.searchParams};
        searchParams.totalPage = data[1].total;
        searchParams.currentPage = data[1].current;
        searchParams.total_item = data[1].total_item;
        this.searchParams = searchParams;
      },
      {
        immediate: false,
        deep: true,
      }
    );
    this.$watch(
      () => Store.getters[`${STORE}/getIsLoading`],
      (data) => {
        this.isLoading = data;
      },
      {
        immediate: true,
        deep: true,
      }
    );
  },

  /**
   * computedの使いわけのイメージとしては、
   * 1.リアクティブではない定数をdataに追加してdataを肥大化したくない
   * 2.状態を明示的に定義したい
   */
  computed: {
    /**
     * (共通)
     * 削除ボタンの活性・非活性
     * selectedItems.length === 0
     * 自体はtemplate側で記述できますが、
     * ここに明示的に状態を定義して可読性を担保してます
     */
    disableRemoveBtn() {
      return this.selectedItems.length === 0;
    },

    //disable approve btn
    disableApproveBtn() {
      this.approveArr = this.selectedItems.map(e => {
          return e.active_flg;
      });

      if (this.approveArr.includes(0) || this.approveArr.includes(2)) {
        return false;
      } else {
        return true;
      }
    },
    /**
     * API Param
     */
    apiParams() {
      return {
        field_name: this.searchParams.field_name,
        group_name: this.searchParams.group_name,
        admin_flg: this.admin_flg,
        end_use_field_date: this.searchParams.end_use_field_date
          ? this.searchParams.end_use_field_date.replaceAll("/", "-")
          : "",
        start_use_field_date: this.searchParams.start_use_field_date
          ? this.searchParams.start_use_field_date.replaceAll("/", "-")
          : "",
        main_contractor_name: this.searchParams.main_contractor_name,
        agreement: this.searchParams.agreement,
        asc: this.searchParams.asc ? 1 : 0,
        sort: this.searchParams.sort,
        current_page: this.searchParams.currentPage,
        page_size: this.searchParams.pageCount,
        company_type: this.companyType
      };
    },

    COMPANY_USER() {
      return JSON.parse(sessionStorage.getItem("COMPANY_USER")).Login
        .company_user;
    },
  },

  methods: {
    onQR() {
      this.isShowQR = true;
    },

    closeShowQrCode() {
      this.isShowQR = false;
    },

    showQRCode(data) {
      this.dataField = data;
      this.isShowQR = true;
    },

    /**
     * (共通)
     * ページネーションイベント
     * @param Number
     */
    pageUpdate(n) {
      let searchParams = {...this.searchParams};
      searchParams.currentPage = n;
      this.searchParams = searchParams;
      this.getItems();
    },

    /**
     * (共通)
     * 検索
     */
    onSearch(invalid) {
      this.searchParams["currentPage"] = 1;
      if (!invalid){
          this.getItems();
      }
    },

    /**
     * (共通)
     * 検索パラメータの変更
     * @param {name:String,value:String}
     */
    onChangeSearchParams({name, value}) {
      let searchParams = {...this.searchParams};
      searchParams[name] = value;
      this.searchParams = searchParams;
      this.$refs.myTableLayout.onChangeWindowSize()
    },

    getStartMonthRules() {
      let fieldEndDate = this.searchParams?.end_use_field_date;
      if (fieldEndDate) {
        fieldEndDate = new Date(fieldEndDate + '/01');
        fieldEndDate = new Date(new Date(fieldEndDate.getFullYear(), fieldEndDate.getMonth() + 1, 0));
        fieldEndDate = format(fieldEndDate, "yyyy/MM/dd");
      }
      let compareFieldStartDate = fieldEndDate ? `compare-field-start-date:${fieldEndDate},竣工予定年月（左）,竣工予定年月（右）` : "";
      return compareFieldStartDate;
    },

    getEndMonthRules() {
      let fieldStartDate = this.searchParams?.start_use_field_date;
      fieldStartDate = fieldStartDate + '/01';
      let compareFieldEndDate = "";
      if (this.searchParams?.start_use_field_date) {
        compareFieldEndDate = `compare-field-end-date:${fieldStartDate},竣工予定年月（右）,竣工予定年月（左）`;
      }
      return compareFieldEndDate;
    },

    onChangeAgreement({name, value}) {
      console.log(value, name);
      let agreement = [];
      let agreements = [];
      if (value.includes(0)) {
        agreements.push(0);
        agreement.push(0);
        agreement.push(2);
      }
      if (value.includes(1)) {
        agreements.push(1);
        agreement.push(1);
      }
      if (value.includes(2)) {
        agreements.push(2);
        agreement.push(3);
        agreement.push(4);
      }

      let searchParams = {...this.searchParams};
      searchParams["agreement"] = agreement;
      searchParams["agreements"] = agreements;
      this.searchParams = searchParams;
    },

    /**
     * (共通)
     * 並び替えパラメータの変更
     * @param {name:String,value:String}
     */
    onChangeSortParams({ name, value }, invalid) {
      let searchParams = { ...this.searchParams };
      searchParams[name] = value;
      name == "pageCount" ? (searchParams["currentPage"] = 1) : "";
      this.searchParams = searchParams;
      if (!invalid){
          this.getItems();
      }
    },

    /**
     * (共通)
     * 作成/編集フォーム
     * INITIAL_ITEM で定義した値がitemに入ります
     * @param INITIAL_ITEMで定義している値
     */
    openNewItemForm() {
      let editedItem = INITIAL_ITEM.entries;
      this.editedItem = _.cloneDeep(editedItem);
      this.isNewItem = true;
      this.popups.isShowItemForm = true;
    },
    async openItemForm(item) {
      let params = {id: item.field_id};
      const fieldInfo = await Store.dispatch(`${STORE}/getMasterFieldInfo`, {params});
      // Show Detail Form
      this.approvalAuto = fieldInfo.data.contents.entries.fields.approval_auto;
      if (!fieldInfo.hasError && fieldInfo.data.status_code == 200) {
        this.editedItem = _.cloneDeep(fieldInfo.data.contents.entries);
        this.isNewItem = false;
        this.popups.isShowItemForm = true;
      }
    },
    closeItemForm() {
      this.popups.isShowItemForm = false;
    },

    /**
     *  (共通)
     * 確認ダイアログ
     * 基本的に変更しないでいいはず
     */

    openRemoveDialog() {
      this.popups.isShowRemoveDialog = true;
    },

    // close dialog
    closeRemoveDialog() {
      this.end_use = false;
      this.popups.isShowRemoveDialog = false;
      this.popups.isShowEndUseDialog = false;
      this.popups.isShowExtendDialog = false;
      this.popups.isShowApproveDialog = false;
      this.$nextTick(() => {
        this.selectedItems = [];
        this.selectedItemsRemoved = [];
      });
      this.approvePrams.ids = [];
    },

    openFormApprove() {
      this.selectedItems.forEach((item) => {
        this.approvePrams.ids.push(item.field_id);
      });
      this.popups.isShowApproveDialog = true;
    },

    openExtendDialog(item) {
      this.extend = item.extend;
      this.extendParams.field_id = item.field_id;
      this.popups.isShowExtendDialog = true;
    },

    openEndUseDialog(item) {
      this.end_use = item.end_use;
      this.endUseParams.admin_flg = this.admin_flg;
      this.endUseParams.field_id = item.field_id;
      this.popups.isShowEndUseDialog = true;
    },

    /**
     * (共通)
     * フォームの変更を受け取る
     */
    formUpdate(params) {
      this.editedItem = _.cloneDeep(params);
    },

    /**
     *  (共通)
     * ストア / api
     * ストア実装ルールにしたがっている場合は、
     */

    // データ取得
    async getItems(isShowLoading = true) {
      const user = JSON.parse(sessionStorage.getItem("USER")).Login.user;

      if (user?.admin_flg) {
        await Store.dispatch(`${STORE}/get`, {params: this.apiParams}, isShowLoading);
      } else {
        await Store.dispatch(`${STORE}/getListFieldInfo`, this.apiParams, isShowLoading);
      }
      this.apiParamsToChild = this.apiParams;
      this.closeRemoveDialog();
    },

    // 削除
    async removeItems() {
      let ids = this.selectedItems.map(item => item.field_id);
      const result = await Store.dispatch(`${STORE}/deleteMasterFieldInfoById`, {ids});

      this.selectedItemsRemoved = [];
      this.searchParams["currentPage"] = 1;
      //ダイアログ閉じる
      this.closeRemoveDialog();
      //成功したら値を更新
      if (result?.response?.data?.result == false) {
        Store.dispatch("Error/show", {
          status: 200,
          message: result?.response?.data?.message,
        });
      }

      if (!result?.hasError) {
        Store.dispatch("Toast/show", {
          status: 200,
          message: result?.data?.message
        });
      }
      this.getItems();
    },

    /**
     * (共通)
     * 新規登録 / 更新
     * - idがある場合は更新
     */
    async submitForm() {
      const editedItem = _.cloneDeep(this.editedItem);
      const hasId = "id" in this.editedItem.fields;
      const result = await Store.dispatch(
        hasId
          ? `${STORE}/updateMasterFieldInfoById`
          : `${STORE}/addMasterFieldInfo`,
        editedItem
      );

      if (result.hasError) {
        this.isErrorSubmit = true;
        return;
      } else if (hasId) {
        await Store.dispatch("Toast/show", {
          status: 200,
          message: "更新しました",
        });
        let params = {id: this.editedItem.fields.id};
        const resultDetail = await Store.dispatch(
          `${STORE}/getMasterFieldInfo`,
          {
            params,
          }
        );
        this.editedItem = _.cloneDeep(resultDetail.data.contents.entries);
        this.isErrorSubmit = false;
      } else {
        this.closeItemForm();
        Store.dispatch("Toast/show", {
          status: 200,
          message: "登録しました",
        });
      }
      this.getItems(false);
      this.isEmitted = !this.isEmitted;
    },

    updateSelectedItems(value) {
      let currentItems = value.value
        ? this.items.filter(
          (element) => element.field_id === value.item.field_id
        )
        : [];
      if (currentItems.length > 0) {
        this.selectedItemsRemoved.push(currentItems[0]);
        this.selectedItems.push(...currentItems);
      } else {
        this.selectedItemsRemoved = this.selectedItemsRemoved.filter(
          (item) => item.field_id !== value.item.field_id
        );
        this.selectedItems = this.selectedItems.filter(
          (item) => item.field_id !== value.item.field_id
        );
      }
    },

    selectAllItems(value) {
      this.selectedItemsRemoved = [];
      let idSet = new Set();
      if (value.value) {
        value.items.forEach((item) => {
          if (!idSet.has(item.field_id)) {
            this.selectedItemsRemoved.push(item.field_id);
            idSet.add(item.field_id);
          }
        });
      }
    },

    //get date today
    getDateNow() {
      var today = new Date();
      var dd = String(today.getDate()).padStart(2, "0");
      var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
      var yyyy = today.getFullYear();

      this.date_now = yyyy + "-" + mm + "-" + dd;
    },

    //open dialog end use
    acceptEndUse() {
      this.popups.isShowEndUseDialog = false;
    },

    //open dialog form extend
    acceptExtend() {
      this.popups.isShowExtendDialog = false;
    },

    convertName(str, length) {
      return convertName(str, length);
    },

    formatDate(date) {
      return dateFormat(date);
    },

    //get api end_use
    async endUse() {
      if (this.end_use == false) {
        let res = await Store.dispatch(`${STORE}/endUse`, this.endUseParams);
        this.checkRes(res);
      } else {
        let res = await Store.dispatch(
          `${STORE}/cancelEndUse`,
          this.endUseParams
        );
        this.checkRes(res);
      }
      this.getItems();
    },

    //get api extend
    async extendSite() {
      if (this.extend == false) {
        let res = await Store.dispatch(`${STORE}/permitOnSite`, this.extendParams);
        this.checkRes(res);
      } else {
        let res = await Store.dispatch(`${STORE}/endFormOutput`, this.extendParams);
        this.checkRes(res);
      }
      this.getItems();
    },

    //get api approve
    async approve() {
      let res = await Store.dispatch(`${STORE}/approve`, this.approvePrams);
      if (res?.response?.data?.result == false) {
        Store.dispatch("Error/show", {
          status: 200,
          message: res?.response?.data?.message,
        });
      } else {
        if (!res.hasError) {
          Store.dispatch("Toast/show", {
            status: 200,
            message: res?.data?.message,
          });
        }
      }
      this.getItems();
    },
    convertFieldEnDate(item) {
      let value = item.replaceAll("-", "/").slice(0, 7);
      return value;
    },

    //check res => toast message
    checkRes(res) {
      if (!res.hasError) {
        Store.dispatch("Toast/show", {
          status: 200,
          message: "成功しました",
        });
      }
      this.getItems();
    },

    closeUploadDialog() {
      this.popups.isShowUploadForm = false;
    },

    updateItemsBefore(data) {
      this.itemGateBefore = data.items;
    },

    beforeUpdateItem(data) {
      this.itemGateBefore = data.field_gates;
      this.itemPlaceBefore = data.field_places;
    },
  },
};
</script>

<style scoped>
.active_flg {
  width: 120px;
  box-shadow: none;
  height: 36px;
  padding: 0px auto;
  border-radius: 20px;
  display: inline-block;
  line-height: 36px;
}

.action {
  width: 250px;
}

.title-input-item .label{
  color: #000000;
}

::v-deep.theme--light.v-input--switch:not(.v-input--is-dirty) .v-input--switch__thumb {
  color: #000 !important;
}

::v-deep.theme--light.v-input--switch:not(.v-input--is-dirty) .v-input--switch__track {
  color: rgba(0, 0, 0, 0.38) !important;
}

.switch-label {
  color: black;
  font-weight: 400;
  font-size: 16px;
}

.disable-events {
  pointer-events: none;
}

::v-deep.theme--light.v-data-table > .v-data-table__wrapper > table > thead > tr > th {
  white-space: nowrap;
}

::v-deep .v-data-table.admin-table>.v-data-table__wrapper>table>tbody>tr>td:first-child {
  padding: 0;
  text-align: center !important;
}

::v-deep .v-data-table>.v-data-table__wrapper table thead tr th:first-child {
  padding: 0;
  width: 5% !important;
  min-width: 5% !important;
  text-align: center !important;
}


::v-deep .v-data-table.admin-table>.v-data-table__wrapper>table>tbody>tr>td:nth-child(2) {
  padding: 0;
}

@media (max-width: 1024px) {
  .SearchFormWrapper .labelWrapper {
    max-height: inherit !important;
    height: auto !important;
  } 
}
</style>
