<template>
  <div class="FormMain">
    <div class="FormMainBody" :style="getBodyStyle">
      <v-card flat>
        <div class="table-container" style="overflow: hidden!important;">
          <div class="d-flex mt-5 ml-4">
            <v-btn
              v-if="editable"
              class="mr-6 mb-3"
              depressed
              color="primary"
              @click=openUpdateUseStatusToStartDialog
            >
              利用再開
            </v-btn>
            <v-btn
              v-if="editable"
              class="mr-6 mb-3"
              depressed
              color="warning"
              @click=openUpdateUseStatusToEndDialog
            >
              利用解除
            </v-btn>
          </div>
          <v-data-table :noDataText="NO_DATA" :headers="LIST_FIELD_GROUP_USERS_LABEL" :items="detailData" height="480px" disable-pagination fixed-header hide-default-footer class="data-table"
            disable-sort
            v-model="selected"
            item-key="inspection_result_id"
            :show-select="editable"
            @click:row="openFormDetailModel"
          >
            <template v-slot:[`item.approved_status`]="{ item }">
              <v-chip :color="getColor(item.approved_status)" :text-color="item.approved_status == APPROVED_STATUS.APPROVED.id ? '#ffffff' : '#000000'">
                {{ getApprovedStatus(item.approved_status) }}
              </v-chip>
            </template>
            <template v-slot:[`item.act`]="{ item }">
              <span v-if="item.inspector_id != item.created_field_user_id">
                <div class="act-circle">
                  <img width="25" height="25" class="block-frame" v-bind:src="ICON_REPORTER" alt=""/>
                </div>
              </span>
            </template>
            <template v-slot:[`item.name`]="{ item }">
              <span>{{ item.user_name_sei }} {{ item.user_name_mei }}</span>
            </template>
            <template v-slot:[`item.inspection_at`]="{ item }">
              {{ formatDateTime(item.inspection_at) }}
            </template>
            <template v-slot:[`item.inspection_status`]="{ item }">
              <span style="margin-right: 3px;">{{ LIST_REPAIR[item.result] }}</span>
              <span v-if="item.repair_status" style="color: #E5951D;">{{ LIST_REPAIR_STATUS[item.repair_status] }}</span>
            </template>
          </v-data-table>
        </div>
      </v-card>
    </div>
    <Popup width="480px" :dialog="popups.isShowToStartDialog">
      <ConfirmUpdateUseStatus
        title="利用再開"
        text1="選択したユーザーの現場連携を再開します。"
        text2="よろしいですか？"
        submitText="再開"
        @close="onClickBtnCancelShowConfirm"
        @yes="saveApproveData"
      />
    </Popup>
    <Popup width="480px" :dialog="popups.isShowToEndDialog">
      <ConfirmUpdateUseStatus
        title="利用解除"
        text1="選択したユーザーの現場連携を解除します。"
        text2="よろしいですか？"
        submitText="解除"
        @close="onClickBtnCancelShowConfirm"
        @yes="saveApproveData"
        warning
      />
    </Popup>
  </div>
</template>
<script>
import { ValidationObserver } from "vee-validate";
import Label from "@/components/forms/elements/Label";
import SectionLabel from "@/components/forms/elements/SectionLabel";
import InputText from "@/components/forms/elements/InputText";
import SelectSearch from "@/components/forms/elements/SelectSearch";
import SearchSelectListHight from "@/components/forms/elements/SearchSelectListHight";
import InputDatepicker from "@/components/forms/elements/InputDatepicker.vue";
import RadioGroup from "@/components/forms/elements/RadioGroup.vue";
import Popup from "@/components/common/Popup";
import Select from "@/components/forms/elements/Select.vue";
import FileInput from "@/components/forms/elements/FileInput";
import { COMPANY_RENTAL, MODE_CREATE } from "@/constants/COMMON";
import ConfirmUpdateUseStatus from "@/components/forms/fieldGroups/Popup/ConfirmUpdateUseStatusForm.vue";
import { LIST_FIELD_GROUP_USERS_LABEL } from "@/constants/FIELD_GROUPS";
import { COMPANY_TYPE, RENTAL_USER_TYPE, ENGINEERS } from "@/constants/COMPANIES";
import { USER_ACTIVE, USER_DEACTIVE } from "@/constants/USER";

export default {
  data: () => {
    return {
      formValues: {
        group_name: '',
        leader_name: '',
      },
      resetValue: {},
      ccusIdCheck: false,
      plateNumberPrefixCheck: false,
      usersEmailCheck: false,
      usersPhoneCheck: false,
      usersNameSeiCheck: false,
      usersNameMeiCheck: false,
      usersKanaSeiCheck: false,
      usersKanaMeiCheck: false,
      COMPANY_TYPE: COMPANY_TYPE,
      RENTAL_USER_TYPE,
      company: {},
      companyType: null,
      COMPANY_RENTAL,
      ENGINEERS,
      MODE_CREATE,
      USER_ACTIVE,
      USER_DEACTIVE,
      LIST_FIELD_GROUP_USERS_LABEL,
      popups: {
        isShowToStartDialog: false,
        isShowToEndDialog: false
      },
    };
  },
  components: {
    ValidationObserver,
    Label,
    SectionLabel,
    InputText,
    InputDatepicker,
    Select,
    FileInput,
    SelectSearch,
    Popup,
    ConfirmUpdateUseStatus,
    SearchSelectListHight,
    RadioGroup,
  },
  props: {
    isRental: Boolean,
    editable: {
      type: Boolean,
      default: false,
    },
    detailData: Object,
    mainHeight: {
      type: Number,
      default: 0,
    },
    formUpdate: Function,
    actions: String,
    active_flg: Number,
  },
  computed: {
    getBodyStyle() {
      let style = `height:${this.mainHeight}px; min-height: 300px`;
      return style;
    },
  },
  methods: {
    onInput(parent_name, { name, value }) {
      const formValues = { ...this.formValues };
      this.formValues[name] = value;
      this.$emit("formUpdate", formValues);
    },

    openUpdateUseStatusToStartDialog() {
      this.popups.isShowToStartDialog = true;
    },

    openUpdateUseStatusToEndDialog() {
      this.popups.isShowToEndDialog = true;
    },

  },
};
</script>
<style lang="scss">
@import "@/components/forms/style/forms.scss";

.title-input-item .label {
  color: #000000;
}
<
