const COMPANY_INTO_TAB = {
  CompanyFormPage1: { id: 1, title: "基本情報" },
  SafetyRulesForm: { id: 2, title: "安全施策" },
  CompanyFormPage2: { id: 3, title: "保険・退職金共済" },
  CompanyFormPage3: { id: 5, title: "外国人受入企業に関する事項" },
  CompanyFormPage4: { id: 6, title: "女性及び年少者の就業制限" },
  CompanyFormPage5: { id: 7, title: "協力会社提出書類" },
};

const CORPORATE_RATING_POSITION = [
  { name: "前", value: 1 },
  { name: "後", value: 2 },
];

const CORPORATE_CLASSIFICATION = [
  { name: "法人", value: 1 },
  { name: "個人", value: 2 },
];

const INSURANCE_STATUS_2 = [
  { name: "無", value: 1 },
  { name: "有", value: 2 },
];

const INSURANCE_STATUS_3 = [
  { name: "無", value: 1 },
  { name: "有", value: 2 },
  { name: "適用除外", value: 3 },
];

const RADIO_ITEMS_YES_NO = [
  { id: 2, name: "いる", value: 2 },
  { id: 1, name: "いない", value: 1 },
];


const RADIO_ITEMS_YES_NO_IGNORE = [
  { id: 2, name: "いる", value: 2 },
  { id: 1, name: "いない", value: 1 },
  { id: 0, name: "該当なし", value: 0 }
];

const RADIO_ITEMS_VIOLATE_RESTRICTION_FOR_YOUNG_PEOPLE = [
  { id: 1, name: "該当あり", value: 1 },
  { id: 0, name: "該当なし", value: 0 },
];

const DEFAULT_RADIO_YES_NO = 1;
const VIOLATE_RESTRICTION_FOR_YOUNG_PEOPLE_APPLY = 1;


const COMPANY_INITAL_ITEM = {
  company: {
    name: "",
    name_kana: "",
    corporate_status_id: null,
    corporate_rating_position: "",
    corporate_classification: "",
    corporate_number: null,
    logo_image: "",
    postal_code: "",
    cmn_mst_prefecture_id: null,
    city: "",
    address: "",
    tel: "",
    fax: "",
    representative: "",
    representative_email : "",
    employment_manager: "",
    catergory_id: null,
    com_info_id: null,
    ccus_business_id: null,
    remarks: null,
  },
  company_branch_insurances: {
    health_insurance_status: "",
    health_insurance_office_symbol: "",
    health_insurance_number: null,
    health_insurance_association_status: "",
    health_insurance_association_name: "",
    national_health_insurance_association_status: "",
    national_health_insurance_association_name: "",
    national_health_insurance_association_document: "",
    insurance_accident_status: "",
    insurance_accident_number: null,
    reference_number: null,
    insurance_accident_document: "",
    pension_insurance_status: "",
    pension_insurance_office_symbol: "",
    pension_insurance_number: null,
    pension_insurance_document: "",
    employment_insurance_status: "",
    employment_insurance_number: null,
    employment_insurance_document: "",
    participation_construction_status: "",
    participation_construction_number: null,
    participation_construction_document: "",
    participation_sme_status: "",
    participation_sme_number: null,
    participation_sme_document: "",
  },
  company_branch_foreigners: {
    work_place: "",
    engagement_content: "",
    engagement_start_date: "",
    engagement_end_date: "",
    responsible_person_position_name: "",
    responsible_person_name: "",
    responsible_person_phone_number: null,
    construction_specific_skill_certificate_image: "",
    appropriate_supervision_certificate_image: "",
  },
  company_safety_rules: [],
};

export {
  COMPANY_INTO_TAB,
  CORPORATE_RATING_POSITION,
  CORPORATE_CLASSIFICATION,
  INSURANCE_STATUS_2,
  INSURANCE_STATUS_3,
  COMPANY_INITAL_ITEM,
  RADIO_ITEMS_YES_NO,
  RADIO_ITEMS_YES_NO_IGNORE,
  RADIO_ITEMS_VIOLATE_RESTRICTION_FOR_YOUNG_PEOPLE,
  DEFAULT_RADIO_YES_NO,
  VIOLATE_RESTRICTION_FOR_YOUNG_PEOPLE_APPLY
};
export const COMPANIES_TAB = {
  CompaniesFormPage1: { id: 1, title: "基本情報" },
};

export const COMPANIES_INITAL_ITEM = {
    name: "",
    name_kana: "",
    corporate_status_id: null,
    corporate_rating_position: "",
    corporate_classification: "",
    corporate_number: null,
    logo_image: "",
    postal_code: "",
    cmn_mst_prefecture_id: null,
    city: "",
    address: "",
    tel: "",
    fax: "",
    representative: "",
    employment_manager: "",
    catergory_id: null,
    com_info_id: null,
    ccus_business_id: null,
}
