const DOCUMENT_TABLE_LABELS =  [
  {
    text: "帳票名",
    value: "name",
    align: "left"
  },
  {
    text: "出力頻度",
    value: "document_url",
    align: "right"
  }
];

const DOCUMENT_INTO_TAB = {
  DocumentFormPage1: { id: 1, title: "協力会社提出書類" },
};


export { DOCUMENT_TABLE_LABELS, DOCUMENT_INTO_TAB };

