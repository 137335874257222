<template>
  <div>
    <v-app-bar class="portalHeader" :class="backgroundColorHeader" dense>
      <!-- 現場ポータルグローバルヘッダー -->
      <!-- タイトル -->
      <div class="portalHeader-title font-weight-bold primary--text" mx-3>
        <div class="cursor-title" @click="goToHomePage" style="margin-top: 6px;">
          <img src="/images/Airliza.png" alt="-image" width="160" height="30" class=""/>
        </div>
      </div>
      <!-- 現場選択ダイアログ -->
      <v-btn
        dense
        filled
        depressed
        color="grey lighten-2"
        class="site_select_btn"
        @click="openSearchDialog"
      >
        <!-- 現場名 -->
        <div v-if="CurrentSite" class="sites_name_wrapper">
          {{ CurrentSite ? CurrentSite.name : "" }}
        </div>
        <div v-else-if="currentSite" class="sites_name_wrapper">
          {{ currentSite.name }}
        </div>
        <div v-else class="sites_name_wrapper">
          {{ CurrentSiteSession.name }}
        </div>
        <v-spacer></v-spacer>
        <v-icon dark right> mdi-menu-down </v-icon>
      </v-btn>

      <!-- Company name -->
      <span class="portalHeader-date primary--text company-name" @click="close">
        {{ fieldGroupName }}
      </span>

      <!-- 日付 -->
      <span class="portalHeader-date primary--text" @click="close">
        {{ getFormattedDate() }}
      </span>

      <v-spacer></v-spacer>

      <!-- 現場ポータル タブメニュー -->
      <v-tabs class="menu-tab-site" show-arrows dark portalHeader-tabmenu right v-model="active_tab" @click="close">
        <v-tab
          v-for="(val, index) in menuSites"
          v-show="val.visibility"
          :key="index"
          @change="(menu = val), onChange(val.id)"
        >{{ val.name }}</v-tab
        >
      </v-tabs>

      <!-- 通知 -->
      <v-btn :disabled="isNotice" icon @click="openNotification">
        <v-icon v-if="isUpdated" class="buruburu">mdi-bell</v-icon>
        <v-icon v-if="!isUpdated">mdi-bell-outline</v-icon>
      </v-btn>

      <!-- drawer -->
      <v-btn icon @click="openDrawer">
        <v-app-bar-nav-icon></v-app-bar-nav-icon>
      </v-btn>
    </v-app-bar>

    <Popup width="480px" :dialog="isShowSearchDialog">
      <FieldsSelectDialog
        title="現場選択"
        :formValues="formValues"
        @formUpdate="formUpdate"
        @onSearch="searchSite"
        @close="isShowSearchDialog = false"
      />
    </Popup>
    <DrawingSelectDialog
      :isShow="isShowDrawingDialog"
      @onClose="isShowDrawingDialog = false"
    />
  </div>
</template>

<script>
import {
  HEADER_MENU_ITEMS_SITE_ARRAY,
  HEADER_MENU_ITEMS_SITE,
  PORTAL_LIST,
  HEADER_MENU_DROPDOWN,
  // MENU_ENTRANCE_ITEMS_MAP,
  HEADER_MENU_ITEMS_SITE_ARRAY_USER_RENTAL_COMPANY,
  HEADER_MENU_DROPDOWN_FOR_USER_RENTAL_COMPANY,
  HEADER_MENU_ITEMS_FIELD_PORTAL,
} from "@/constants/GLOBALHEADER";
import { getEnvConfigValue, ENV_CONFIG_LABELS } from "@/constants/ENV_CLIENT";
import { Store } from "@/store/Store.js";
import { getDateJp } from "@/utils/timeUtil"; //日付フォーマット変換で使用
import FieldsSelectDialog from "./components/FieldsSelectDialog";
import Popup from "@/components/common/Popup.vue";
import DrawingSelectDialog from "@/components/drawingSelect/index.vue";
import DropdownList from "./components/DropdownList.vue";

const HEADR_MENU_ITEMS_FIELD_PORTAL = () => {
  const menuConfig = getEnvConfigValue(
    ENV_CONFIG_LABELS.HEADER_MENU_ITEMS_FIELD_PORTAL
  );
  return Object.keys(HEADER_MENU_ITEMS_FIELD_PORTAL).map((key) => {
    return { ...HEADER_MENU_ITEMS_FIELD_PORTAL[key], ...menuConfig[key] };
  });
};

const ROLE_MAIN = 1;
const ACTIVE_FLG_APPLY_FOR_TERMINATION = 2;
const ACTIVE_FLG_END = 3;
const ACTIVE_FLG_IN_USE = 1;
const ROLE_COMPLETION_PENDING = 2;
const ROLE_COMPLETED = 3;
const FIELD_GROUP_STORE = "FieldGroups";

export default {
  data() {
    return {
      title: null,
      // タブメニュー表示項目
      HEADER_MENU_ITEMS_SITE_ARRAY,
      HEADER_MENU_ITEMS_SITE,
      HEADER_MENU_DROPDOWN,
      HEADER_MENU_ITEMS_SITE_ARRAY_USER_RENTAL_COMPANY,
      HEADER_MENU_DROPDOWN_FOR_USER_RENTAL_COMPANY,
      HEADER_MENU_ITEMS_FIELD_PORTAL,

      // 選択中のタブメニューid
      active_tab: 0,

      // 検索データ
      formValues: {
        // 検索items
        items: [],
        // 検索ワード
        keyword: "",
        // 選択項目id
        selected: null,
      },

      // 検索ダイアログ表示
      isShowSearchDialog: false,

      //開智計画図面ダイアログ表示
      isShowDrawingDialog: false,

      // 更新があるか
      isUpdated: false,

      // 現場id
      siteId: null,
      menuSites: [],
      backgroundColorHeader: null,
      ROLE_MAIN: 1,
      FIELD_ACTIVE_FLG_END: 3,
      currentSite: null,
      nameSiteSession: null,
      fieldGroupName: null,
      isNotice: false
    };
  },

  components: {
    FieldsSelectDialog,
    Popup,
    DrawingSelectDialog,
    DropdownList,
  },

  created() {
    let siteField = (this.currentSite = JSON.parse(
      sessionStorage.getItem("CURRENT_SITE")
    ));
    if (siteField) {
      if (
        siteField?.role &&
        siteField?.role === ROLE_MAIN &&
        siteField?.active_flg === ACTIVE_FLG_IN_USE
      ) {
        this.backgroundColorHeader = "green_header";
      }
      if (
        siteField?.role &&
        siteField?.role === ROLE_MAIN &&
        (siteField?.active_flg === ACTIVE_FLG_APPLY_FOR_TERMINATION || siteField?.active_flg === ACTIVE_FLG_END)
      ) {
        this.backgroundColorHeader = "grey_header";
      }
      if (siteField?.role &&
        (
          siteField?.role === ROLE_COMPLETION_PENDING ||
          siteField?.role === ROLE_COMPLETED
        )
      ) {
        this.backgroundColorHeader = "red_header";
      }
    }
  },

  async mounted() {
    this.currentSite = JSON.parse(sessionStorage.getItem("CURRENT_SITE"));

    const fieldGroup = JSON.parse(sessionStorage.getItem("CURRENT_SITE"));

    if (fieldGroup?.group_name) {
      if (fieldGroup.role == 1) {
        this.fieldGroupName = fieldGroup.company_name;
      } else {
        this.fieldGroupName = fieldGroup.group_name;
      }
    }

    /**
     * 現場一覧を取得
     */
    this.$watch(
      () => Store.getters[`GlobalHeader/getSiteList`],
      (data) => {
        const siteList = {
          ...data,
        };
        this.formValues.items = siteList;
      },
      {
        immidiate: true,
        deep: true,
      }
    );
    /**
     * 更新のチェック
     */
    this.$watch(
      () => Store.getters[`Notification/countUnreadNotification`],
      (value) => {
        if (value != null) {
          this.isUpdated = value === 1;
        }
      },
      {
        immediate: true,
        deep: true,
      }
    );
    /**
     * Get CurrentSite
     */
    this.$watch(
      () => [Store.getters["GlobalHeader/getCurrentSite"], this.currentSite],
      (data) => {
        if (data[0]) {
          this.checkRole(data[0]);
        } else if (data[1]) {
          this.checkRole(data[1]);
          this.nameSiteSession = data[1].name;
        }
      },
      {
        immediate: true,
        deep: true,
      }
    );
  
    this.$watch(
      () => [Store.getters[`GlobalHeader/getSiteMenuId`], this.menuSites],
      (data) => {
        const menuId = data[0];
        const menu = data[1];
        const selected = menu.findIndex((item) => {
          return item.id === menuId;
        });
        this.active_tab = selected;
      },
      {
        immediate: true,
      }
    );
  },
  props: {},

  computed: {
    /**
     * 現在の現場を取得
     */
    CurrentSite() {
      return Store.getters["GlobalHeader/getCurrentSite"];
    },
    CurrentSiteSession() {
      return JSON.parse(sessionStorage.getItem("USER"));
    },
  },

  methods: {
    checkRole(data) {
      if (data) {
        this.active_tab = 0;
        if (data?.role && data.role === ROLE_MAIN && data?.active_flg === ACTIVE_FLG_IN_USE) {
          this.menuSites = HEADR_MENU_ITEMS_FIELD_PORTAL();
          this.backgroundColorHeader = "green_header";
          this.active_tab = 1;
        }
        if (
          data?.role &&
          data?.role === ROLE_MAIN &&
          (data?.active_flg === ACTIVE_FLG_APPLY_FOR_TERMINATION || data?.active_flg === ACTIVE_FLG_END)
        ) {
          this.menuSites = HEADR_MENU_ITEMS_FIELD_PORTAL().filter(
            (e) => (e.key === "form_output")
          );
          this.backgroundColorHeader = "grey_header";
        }
        if (data?.role &&
          (
            data?.role === ROLE_COMPLETION_PENDING ||
            data?.role === ROLE_COMPLETED
          )
        ) {
          this.menuSites = HEADR_MENU_ITEMS_FIELD_PORTAL().filter(
            (e) => (e.key === "reservation_status" || e.key === "field_machine" || e.key === "user_list")
          );
          this.backgroundColorHeader = "red_header";
        }
      } else {
        this.active_tab = null;
        this.menuSites = [];
        this.backgroundColorHeader = "";
      }
    },
    /**
     * タブメニューの変更
     */
    onChange(menuId) {
      this.close();

      const selectedMenu = this.menuSites.find((item) => {
        return item.id === menuId;
      });

      // //ドロップダウンメニュー
      // if (this.HEADER_MENU_DROPDOWN_OPTION.some((item) => item.id === menuId))
      //   return;

      //配置計画はダイアログを表示し、選択してから遷移する
      if (menuId === HEADER_MENU_ITEMS_SITE.PLACEMENT.id) {
        this.isShowDrawingDialog = true;
        return;
      }
      this.$router.push(selectedMenu.path);
    },

    // ドロワー
    openDrawer() {
      Store.dispatch("Drawer/show", {
        type: PORTAL_LIST.SITE_PORTAL.id,
      });
    },

    // 通知
    async openNotification() {
      this.isNotice = true;
      const result = await Store.dispatch("Notification/show");
      this.isNotice = false;
      if (result.hasError) {
        Store.dispatch("Error/show", {
          status: 200,
          message: result.data.message,
        });
        return;
      }
    },

    goToHomePage() {
      Store.dispatch("GlobalHeader/setSite", {
        field_id: null,
      });
      Store.dispatch("SitePortal/resetData");
      Store.dispatch("Weather/resetWeather");
      this.$router.push("/master/fields");
      this.close();
    },

    // 検索ダイアログ
    async openSearchDialog() {
      const result = await Store.dispatch("GlobalHeader/searchSites", {
        keyword: "",
      });
      if (result.hasError) {
        Store.dispatch("Error/show", {
          status: 200,
          message: result.data.message,
        });
      }
      this.isShowSearchDialog = true;
      this.close();
    },

    /**
     * 現場検索
     */
    async searchSite() {
      const { keyword } = this.formValues;
      const result = await Store.dispatch("GlobalHeader/searchSites", {
        keyword,
      });
      if (result.hasError) {
        Store.dispatch("Error/show", {
          status: 200,
          message: result.data.message,
        });
      }
    },

    // フォームの変更を受け取る
    formUpdate(values) {
      this.formValues = { ...values };
      let arr = Object.values(this.formValues?.items);
      arr = arr.filter((e) => {
        return e.field_id === this.formValues?.selected;
      });
      let onlyReport = false;
      if (arr[0] &&
        arr[0]?.role === ROLE_MAIN &&
        (
          arr[0]?.active_flg === ACTIVE_FLG_APPLY_FOR_TERMINATION ||
          arr[0]?.active_flg === ACTIVE_FLG_END
        )
      ) {
        onlyReport = true;
      }
      /**
       * 現場選択
       */
      const fieldIdOld = Store.getters["GlobalHeader/getCurrentSite"]?.field_id
      if (this.formValues.selected) {
        // 現場idが選択されたらset
        Store.dispatch("GlobalHeader/setSite", {
          field_id: this.formValues.selected,
        });

        let params = { field_user_id: this.formValues.selectedFieldUser}
        Store.dispatch(
          `${FIELD_GROUP_STORE}/selectUseableFieldGroup`,
          params
        );

        // ダイアログclose
        this.isShowSearchDialog = false;

        //リセット
        const formValues = {
          items: [],
          keyword: "",
          selected: null,
        };
        this.formValues = formValues;
        if (onlyReport) {
          this.$router.push("/reports").catch(error => {
            if (error.name !== "NavigationDuplicated") {
              throw error;
            }
          });
        } else {
          // 現場ポータルに遷移
          this.setCurrentRoute(this.$router.currentRoute.path, arr[0]?.role, fieldIdOld);
        }
      }
    },

    /**
     * 日付変換
     * @param date:yyyy/mm/dd（day）
     */
    getFormattedDate() {
      return getDateJp(new Date()).date;
    },

    close() {
      Store.dispatch("Drawer/close");
    },

    setCurrentRoute(path, role = null, fieldIdOld = null) {
      switch (path) {
        case "/portal/dashboard":
          if (role && role === ROLE_MAIN && (Store.getters["GlobalHeader/getCurrentSite"].field_id === fieldIdOld || fieldIdOld === null)) {
            this.$router.push(path).catch(error => {
              if (error.name != "NavigationDuplicated") {
                throw error;
              }
            });
          } else {
            this.$router.push("/booking").catch(error => {
              if (error.name != "NavigationDuplicated") {
                throw error;
              }
            });
          }
          break;
        default:
          this.$router.push("/booking").catch(error => {
            if (error.name != "NavigationDuplicated") {
              throw error;
            }
          });
          break;
      }
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/themes.scss";
@import "@/assets/scss/responsive.scss";

.portalHeader {
  .portalHeader-title {
    font-size: 16px;
    width: 128px;
    margin-right: 80px;
  }
  .portalHeader-siteSelect {
    width: 224px;
  }
  .portalHeader-date {
    padding-left: 20px;
    white-space: nowrap;
  }
}

.v-badge__badge {
  font-size: 9px !important;
}
.theme--light.v-badge .v-badge__badge:after {
  border-color: none !important;
}

.site_select_btn {
  width: 220px;
  min-width: 180px !important;
  overflow: hidden;
  @media (max-width: $sp) {
    width: 140px;
    min-width: 140px !important;
  }
}
.site_select_btn.v-btn.v-size--default {
  padding: 4px !important;
}
.sites_name_wrapper {
  width: auto;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  text-indent: 4px;
  text-transform: capitalize;
  font-size: 12px;
  letter-spacing: 0;
}

.cursor-title {
  cursor: pointer;
}

.buruburu {
  display: inline-block;
  animation: hurueru 1s infinite;
  transition-timing-function: ease-in-out;
  &::before {
    color: $color_util_1;
  }
}

// green
.green_header {
  background: #008000 !important;
  color: #fff;
}
.green_header .cursor-title {
  color: #fff;
}
.green_header .portalHeader-date {
  color: #fff !important;
}
.green_header .v-tab {
  color: #fff !important;
}
.v-application .green_header .site_select_btn {
  background-color: #61a761 !important;
}
.v-application .green_header .sites_name_wrapper {
  color: #fff !important;
}
.v-application .green_header span i {
  color: #fff !important;
}
.v-application .green_header span i.theme--dark {
  color: #000 !important;
}
// grey
.grey_header {
  background: #606060 !important;
  color: #fff;
}
.grey_header .cursor-title {
  color: #fff;
}
.grey_header .portalHeader-date {
  color: #fff !important;
}
.grey_header .v-tab {
  color: #fff !important;
}
.v-application .grey_header .site_select_btn {
  background-color: #61a761 !important;
}
.v-application .grey_header .sites_name_wrapper {
  color: #fff !important;
}
.v-application .grey_header .site_select_btn {
  background-color: #959595 !important;
}
.v-application .grey_header span i {
  color: #fff !important;
}
.v-application .grey_header span i.theme--dark {
  color: #000 !important;
}
// red
.red_header {
  background: #b00020 !important;
  color: #fff;
}
.red_header .cursor-title {
  color: #fff;
}
.red_header .portalHeader-date {
  color: #fff !important;
}
.red_header .v-tab {
  color: #fff !important;
}
.v-application .red_header .site_select_btn {
  background-color: #c16172 !important;
}
.v-application .red_header span i {
  color: #fff !important;
}
.v-application .red_header span i.theme--dark {
  color: #000 !important;
}
.v-application .red_header .sites_name_wrapper {
  color: #fff !important;
}
div::v-deep .red_header .v-tabs-slider {
  color: #fff !important;
}
div::v-deep .green_header .v-tabs-slider {
  color: #fff !important;
}
div::v-deep .grey_header .v-tabs-slider {
  color: #fff !important;
}
@keyframes hurueru {
  0% {
    transform: rotateZ(10deg);
  }
  50% {
    transform: rotateZ(-10deg);
  }
  100% {
    transform: rotateZ(10deg);
  }
}

.portalHeader-title {
  white-space: nowrap;
}

.menu-tab-site {
  overflow-y: auto;
}

@media (max-width: 1024px) {
  .portalHeader-date.company-name {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: inline-block;
    max-width: 24vw;
    min-width: 16vw;
  }
}
</style>
