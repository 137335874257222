<template>
  <ValidationProvider
    v-slot="{ errors, valid }"
    :vid="id"
    :name="validation_label"
    :rules="validation_rules"
  >
    <span @click="editable ? openFormSearch() : null">
    <v-select
      v-model="val"
      dense
      :items="items"
      :item-text="item_text"
      :item-value="item_value"
      :disabled="!editable"
      :success="valid"
      :filled="editable"
      single-line
      :error-messages="errors"
      :label="label"
      readonly
      :data-testid="testid"
      :menu-props="{ maxWidth: 800 }"
    ></v-select>
    </span>
    <Popup width="480px" :dialog="isShowSearchDialog" v-if="isShowSearchDialog">
      <SearchByNameDialog
        :item_text="item_text"
        :item_value="item_value"
        :title="title"
        :title_search="title_search"
        :formValues="dataValue"
        @formUpdate="formUpdate"
        @onSearch="search"
        @close="isShowSearchDialog = false"
        @reset="reset"
      />
    </Popup>
  </ValidationProvider>
</template>
<script>
import { ValidationProvider } from "vee-validate";
import Popup from "@/components/common/Popup.vue";
import SearchByNameDialog from "@/components/forms/elements/SearchList/SearchByNameDialog";

export default {
  data: () => {
    return {
      val: "",
      isShowSearchDialog: false,
      dataValue: {
        items: [],
        keyword: "",
        selected: null
      }
    };
  },
  components: {
    ValidationProvider,
    Popup,
    SearchByNameDialog
  },
  props: {
    editable: {
      type: Boolean,
      default: false
    },
    name: {
      type: String
    },
    values: {
      type: Object
    },
    items: {
      type: Array
    },
    label: {
      type: String
    },
    validation_rules: {
      type: String,
      default: ""
    },
    validation_label: {
      type: String,
      default: ""
    },
    autofocus: {
      type: Boolean,
      default: false
    },
    testid: {
      type: String
    },
    item_text: {
      type: String,
      default: "name"
    },
    item_value: {
      type: String,
      default: "id"
    },
    id: {
      type: String
    },
    title : {
      type : String
    },
    title_search : {
      type : String
    }
  },

  mounted() {
    this.$watch(
      () => [this.values, this.name],
      (newValue) => {
        const formValues = newValue[0];
        const name = newValue[1];
        if (formValues && name) this.val = formValues[name];
      },
      { immediate: true, deep: true }
    );
  },
  methods: {
    search() {
      const { keyword } = this.dataValue;
      let _dataValue = { ...this.dataValue };
      if (keyword) {
        _dataValue.items = this.items.filter(e => e[this.item_text].includes(keyword) && e[this.item_value]);
      } else {
        _dataValue.items = [...this.items];
      }
      this.dataValue = _dataValue;
    },

    openFormSearch() {
      let _dataValue = { ...this.dataValue };
      _dataValue.items = [...this.items];
      _dataValue.keyword = "";
      _dataValue.selected = null;
      this.dataValue = { ..._dataValue };
      this.isShowSearchDialog = true;
    },

    formUpdate(values) {
      this.dataValue = { ...values };
      if (this.dataValue.selected) {
        this.val = this.dataValue.selected;
        this.isShowSearchDialog = false;
        this.$emit("onInput", { name: this.name, value: this.val });
      }
    },
    reset(){
      this.val = null;
      this.isShowSearchDialog = false;
      this.$emit("onInput", { name: this.name, value: this.val });
    }
  }
};
</script>
<style lang="scss" scoped>
::v-deep .v-select.v-input--dense .v-select__selection--comma {
  position: absolute;
}
</style>