<template>
  <ValidationObserver v-slot="{ invalid }" ref="observer">
    <div class="report-search">
      <SearchFormWrapper>
        <div class="d-flex">
          <Label label="報告日" class="title-input-item" style="max-width: 150px">
            <InputDatepicker name="report_date_from" :editable="true" :values="searchParams" :flagNull="true"
              validation_label="報告日" @onInput="onChangeSearchParams" :validation_rules="getRuleStartDate()" />
          </Label>
          <span class="mt-10 ml-3">
            <v-icon>mdi-tilde</v-icon>
          </span>
          <Label label="報告日" class="title-input-item" style="max-width: 150px">
            <InputDatepicker name="report_date_to" :editable="true" :values="searchParams" :flagNull="true"
              validation_label="報告日" @onInput="onChangeSearchParams" :validation_rules="getRuleEndDate()" />
          </Label>
          <Label label="ナンバー" class="title-input-item" style="max-width: 100px">
            <InputText name="number_plate" :editable="true" :values="searchParams" :flagNull="true"
              validation_label="ナンバー" @onInput="onChangeSearchParams" />
          </Label>
          <Label label="所属" class="title-input-item" style="max-width: 100px">
            <Select name="field_group_id" :editable="true" :values="searchParams" :items="PARTNERS_ARRAY"
              item_text="name" :flagNull="true" validation_label="所属" @onInput="onChangeSearchParams" />
          </Label>
          <Label label="手配先" class="title-input-item" style="max-width: 100px">
            <Select name="company_renta_id" :editable="true" :values="searchParams" :items="RENTAL_COMPANY_ARRAY"
              item_text="name" :flagNull="true" validation_label="手配先" @onInput="onChangeSearchParams" />
          </Label>
          <Label label="留置場所" class="title-input-item" style="max-width: 100px">
            <Select name="field_place_id" :editable="true" :values="searchParams" :items="FIELD_PLACES_ARRAY"
              item_text="name" :flagNull="true" validation_label="留置場所" @onInput="onChangeSearchParams" />
          </Label>
        </div>
        <div class="d-flex">
          <div class="tab-select-header">
            <Label label="種別" class="title-input-item">
              <TabSelect name="kinds" :editable="true" :values="searchParams" :items="kindTabSelect" :flagNull="true"
                validation_label="種別" @onInput="onChangeSearchParams" />
            </Label>
          </div>

          <div class="tab-select-header" v-show="false">
            <Label label="昇降" class="title-input-item"  v-show="false">
              <TabSelect name="lifting_method" :editable="true" :values="searchParams" :items="liftingTabSelect"
                :flagNull="true" validation_label="昇降" @onInput="onChangeSearchParams" />
            </Label>
          </div>
          <div class="tab-select-header"  v-show="false">
            <Label label="足回り" class="title-input-item"  v-show="false">
              <TabSelect name="undercarriage" :editable="true" :values="searchParams" :items="undercarriageTabSelect"
                :flagNull="true" validation_label="足回り" @onInput="onChangeSearchParams" />
            </Label>
          </div>
          <div class="tab-select-header"  v-show="false">
            <Label label="作業床" class="title-input-item"  v-show="false">
              <TabSelect name="working_floor" :editable="true" :values="searchParams" :items="workingFloorTabSelect"
                :flagNull="true" validation_label="作業床" @onInput="onChangeSearchParams" />
            </Label>
          </div>
          <div class="tab-select-header">
            <Label label="対応状況" class="title-input-item">
              <TabSelect name="repair_status" :editable="true" :values="searchParams" :items="statusTabSelect"
                :flagNull="true" validation_label="対応状況" @onInput="onChangeSearchParams" />
            </Label>
          </div>
          <div style="width: 100px;"></div>
        </div>
        <v-spacer></v-spacer>
        <Label>
          <v-btn class="mr-6" color="primary" depressed @click="searchMachine" :disabled="invalid">
            検索
          </v-btn>
        </Label>
      </SearchFormWrapper>
    </div>
  </ValidationObserver>
</template>
<script>
import { format, parseISO } from "date-fns";
import { FOMAT_DATE } from "@/constants/BOOKING";
import TabSelect from "@/components/forms/elements/TabSelect";
import SearchFormWrapper from "@/components/masterTable/elements/SearchFormWrapper";
import { Store } from "@/store/Store.js";
import { ROLE_USER_RENTAL_COMPANY, ROLE_FOREMAN } from "@/constants/COMMON";
import Label from "@/components/forms/elements/Label";
import InputDatepicker from "@/components/forms/elements/InputDatepicker";
import InputText from "@/components/forms/elements/InputText";
import Select from "@/components/forms/elements/Select";
import { ValidationObserver } from "vee-validate";

const PAGE_COUNT = 25;
export default {
  data() {
    return {
      fieldId: null,
      ROLE_USER_RENTAL_COMPANY: ROLE_USER_RENTAL_COMPANY,
      ROLE_FOREMAN: ROLE_FOREMAN,
      isDateStart: false,
      isDateEnd: false,
      dateDefailt: new Date(),
      formatDate: FOMAT_DATE,
      searchParams: {
        "field_id": null,
        "report_date_from": null,
        "report_date_to": null,
        "number_plate": null,
        "field_group_id": null,
        "company_renta_id": null,
        "field_place_id": null,
        "kinds": [],
        "lifting_method": [],
        "undercarriage": [],
        "working_floor": [],
        "repair_status": [0,2,3,4],
        "page": 1,
        "page_size": PAGE_COUNT
      },
      PARTNERS_ARRAY: [],
      RENTAL_COMPANY_ARRAY: [],
      FIELD_PLACES_ARRAY: [],
      kindTabSelect: [
        { "id": 1, "name": "異常報告" },
        { "id": 2, "name": "修理要請" },
      ],
      liftingTabSelect: [
        { "id": 0, "name": "垂" },
        { "id": 1, "name": "直" },
        { "id": 2, "name": "屈" },
      ],
      undercarriageTabSelect: [
        { "id": 1, "name": "ホ" },
        { "id": 2, "name": "ク" },
      ],
      workingFloorTabSelect: [
        { "id": 1, "name": "普" },
        { "id": 2, "name": "ス" },
        { "id": 3, "name": "バ" },
      ],
      statusTabSelect: [
        { "id": 0, "name": "未対応" },
        { "id": 1, "name": "現場で対応" },
        { "id": 2, "name": "手配" },
        { "id": 3, "name": "見積済" },
        { "id": 4, "name": "修理報告済" },
        //MMSV2-1573-start
        { "id": 5, "name": "承認済" }
        //MMSV2-1573-end
      ],
    }
  },
  components: {
    Select,
    InputText,
    InputDatepicker,
    Label,
    TabSelect,
    SearchFormWrapper,
    ValidationObserver
  },
  computed: {
    computedDateStartFormatted() {
      return this.searchParams.report_date_from ? format(parseISO(this.searchParams.report_date_from), "yyyy/MM/dd") : "";
    },
    computedDateEndFormatted() {
      return this.searchParams.report_date_to ? format(parseISO(this.searchParams.report_date_to), "yyyy/MM/dd") : "";
    },
  },
  created() {
    this.fieldId = JSON.parse(sessionStorage.getItem("CURRENT_SITE")).field_id;
    this.roleId = JSON.parse(sessionStorage.getItem("CURRENT_SITE")).role;
    this.searchParams.field_id = this.fieldId;
  },
  async mounted() {
    this.getPartners();
    this.getListFieldCompany();
    this.getFieldPlaces();
  },
  methods: {
    async onChangeSearchParams({ name, value }) {
      let searchParams = { ...this.searchParams };
      searchParams[name] = value;
      this.searchParams = searchParams;
      this.$emit("has-error");
    },

    // get partners company
    async getPartners() {
      const params = {
        "field_id": this.fieldId,
      };
      const result = await Store.dispatch('FieldGroups/getListFieldGroupInfo', { params: params });
      if (!result.hasError) {
        this.PARTNERS_ARRAY = [{ id: null, name: "" }, ...result.data.contents.entries];
      }
    },

    async getListFieldCompany() {
      const params = {
        "field_id": this.fieldId,
        "role": this.ROLE_USER_RENTAL_COMPANY
      };
      const result = await Store.dispatch(`Machines/getListFieldCompanyRole`, params);
      if (!result.hasError) {
        this.RENTAL_COMPANY_ARRAY = [{ id: null, name: "" }, ...result.contents.entries];
      }
    },

    // get field places 
    async getFieldPlaces() {
      const params = {
        field_id: this.fieldId,
      };
      const result = await Store.dispatch('Fields/getListSpaceByFieldId', params);
      if (!result.hasError) {
        let _data = result.data.contents.entries.map(i => ({ ...i, name: i.display_name }));
        this.FIELD_PLACES_ARRAY = [{ id: null, name: "" }, ..._data];
      }
    },

    async searchMachine() {
      this.$emit("search-report-tab", this.searchParams);
    },

    onInput({ name, value }) {
      const reportSearchTab = { ...this.searchParams };
      reportSearchTab[name] = value;
      this.searchParams = reportSearchTab;
    },
    getRuleStartDate() {
      let rule = "";
      if (this.searchParams?.report_date_from && this.searchParams?.report_date_to) {
        const dateEnd = this.searchParams?.report_date_to;
        rule = `err-start-date:${dateEnd},報告日（左）, 報告日（右）`;
      }
      return (
        rule
      );
    },
    getRuleEndDate() {
      let rule = "";
      if (this.searchParams?.report_date_from && this.searchParams?.report_date_to) {
        const dateEnd = this.searchParams?.report_date_from;
        rule = `err-end-date:${dateEnd},報告日（右）,報告日（左）`;
      }
      return (
        rule
      );
    },
  },
}
</script>
<style lang="scss" scoped>
.layout-machine-container {
  .report-search {
    margin-bottom: 10px;

    ::v-deep .SearchFormWrapper {
      flex-wrap: wrap;
      position: relative;

      .v-text-field__details {
        margin: 0;
      }
    }

    .title-input-item .label {
      font-size: 12px !important;
      line-height: 16px !important;
      font-weight: 400 !important;
    }

    .title-input-item .label .none-required {
      display: none;
    }

    .input-date {
      border-radius: 2px;
      width: 166px;
      height: 52px;
      font-size: 12px !important;
      line-height: 16px;
      color: rgba(0, 0, 0, 0.87);
      flex: none;
    }

    .color-label {
      color: rgba(176, 0, 32, 1);
    }

    .headline {
      width: 50px;
      line-height: 54px !important;
      align-items: center;
      text-align: center;
      color: #000000;
    }

    .tab-select-header {
      height: 56px !important;
    }

    .tableSortWrapper {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding: 0 8px;
      height: 32px;

      .cst-row {
        border-bottom: 1px solid;
        padding-top: 12px;
        border-color: #e0e0e0;
      }

      .cst-col {
        padding-top: 0px;
        padding-bottom: 0px;
      }

    }
  }
}

@media (max-width: 1400px) {
  .layout-machine-container {
    .report-search {

      ::v-deep .SearchFormWrapper {
        .v-text-field__details {
          margin: 0;
        }

        .tabSelect {
          height: auto;
        }

        .tabSelectwrapper {
          height: auto;
        }
      }
    }
  }
}
</style>