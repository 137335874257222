import { render, staticRenderFns } from "./FieldUserHeader.vue?vue&type=template&id=1da40ecc&scoped=true&"
import script from "./FieldUserHeader.vue?vue&type=script&lang=js&"
export * from "./FieldUserHeader.vue?vue&type=script&lang=js&"
import style0 from "./FieldUserHeader.vue?vue&type=style&index=0&id=1da40ecc&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1da40ecc",
  null
  
)

export default component.exports