import Kuroshiro from "kuroshiro";
import KuromojiAnalyzer from "kuroshiro-analyzer-kuromoji";

const kuroshiro = new Kuroshiro();
kuroshiro.init(
  new KuromojiAnalyzer({
    dictPath: "/dict",
  })
);
export { kuroshiro };
