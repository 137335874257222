<template>
  <v-card class="dialog">
    <div class="header sticky">
      <v-card-title small>
        <div class="title primary--text">{{ title }}</div>
        <v-spacer></v-spacer>
        <v-btn small icon text @click="closeForm()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
    </div>
    <!-- 検索コンポーネント -->
    <SearchSelectList
      :formValues="formValues"
      @search="search"
      @formUpdate="formUpdate"
      :title_search="title_placehoder"
    >
      <v-list two-line>
        <template v-for="item in formValues.items">
          <template v-if="item.active_flg !== 0">
            <v-list-item
              v-if="((item.active_flg === 3 || item.active_flg === 2) && item.role === 1)"
              :key="item.field_id" ripple style="background-color:#f5f5f5;"
            >
              <v-list-item-content @click="onClick(item)" class="list-item-content">
                <div class="left-content">
                  <v-list-item-subtitle class="company_name">{{
                    item.company_name
                  }}</v-list-item-subtitle>
                  <v-list-item-title class="field_name">{{
                    item.name
                  }}</v-list-item-title>
                  <v-list-item-subtitle class="group_name">{{
                    item.group_name
                  }}</v-list-item-subtitle>
                </div>
                <div class="right-content">
                  <v-list-item-subtitle class="role_name">{{
                    item.role_name
                  }}</v-list-item-subtitle>
                  <v-list-item-subtitle class="leader_name">{{
                    item.leader_name
                  }}</v-list-item-subtitle>
                </div>
              </v-list-item-content>
            </v-list-item>
            <v-list-item v-else-if="item.role === 1 || item.role === 2" :key="item.field_id" ripple>
              <v-list-item-content @click="onClick(item)" class="list-item-content">
                <div class="left-content">
                  <v-list-item-subtitle class="company_name">{{
                    item.company_name
                  }}</v-list-item-subtitle>
                  <v-list-item-title class="field_name">{{
                    item.name
                  }}</v-list-item-title>
                  <v-list-item-subtitle class="group_name">{{
                    item.group_name
                  }}</v-list-item-subtitle>
                </div>
                <div class="right-content">
                  <v-list-item-subtitle class="role_name">{{
                    item.role_name
                  }}</v-list-item-subtitle>
                  <v-list-item-subtitle class="leader_name">{{
                    item.leader_name
                  }}</v-list-item-subtitle>
                </div>
              </v-list-item-content>
            </v-list-item>
          </template>
        </template>
        <v-list-item v-if="showNoMessage">
          {{ no_data }}
        </v-list-item>
      </v-list>
    </SearchSelectList>
  </v-card>
</template>

<script>
import SearchSelectList from "@/components/forms/elements/SearchSelectList";
import {
  NO_DATA
} from "@/constants/COMMON";
import { Store } from "@/store/Store.js";
// import {HEADER_MENU_ITEMS_FIELD_UNFINISHED} from "@/constants/GLOBALHEADER";

export default {
  components: {
    SearchSelectList,
  },

  props: {
    title: String,
    formValues: {
      type: Object,
      default: {
        // 検索ワード
        search: "",
        // 表示するリスト
        items: [],
        // 選択項目id
        selected: null,
        selectedFieldUser: null,
      },
    },
    onSearch: Function,
    close: Function,
  },

  data: () => {
    return {
      keyword: "",
      title_placehoder: '現場名',
      no_data: NO_DATA,
      showNoMessage: false,
    };
  },

  mounted() {
    this.$watch(
      () => Store.getters[`GlobalHeader/getSiteList`], (data) => {
         if (data.length < 1) {
           this.showNoMessage = true;
         } else {
           this.showNoMessage = false;
         }
        },
        {
          immidiate: true,
          deep: true,
        }
    );
  },

  methods: {
    // 検索(propsで渡すだけ)
    search() {
      this.$emit("onSearch", { keyword: this.keyword });
    },

    // formデータ更新(propsで渡すだけ)
    formUpdate(values) {
      this.keyword = values.keyword;
      this.$emit("formUpdate", values);
    },

    // idの更新
    onClick(fieldGroup) {
      const values = { ...this.formValues };
      values.selected = fieldGroup.field_id;
      values.selectedFieldUser = fieldGroup.field_user_id;
      let arr = Object.values(values.items);
      arr = arr.filter((e) => {
        return e.field_id == fieldGroup.field_id;
      });
      sessionStorage.setItem("CURRENT_SITE", JSON.stringify(arr[0]));

      this.$emit("formUpdate", values);
    },
    closeForm() {
      this.formValues.keyword = '';
      this.$emit('close');
    }
  },
};
</script>

<style lang="scss" scoped>
.dialog {
  padding-bottom: 20px;
  .header {
    height: 65px;
    position: sticky;
    top: 0;
    background-color: white;
    z-index: 1;
    .header-title-area {
      display: flex;
      justify-content: space-between;
      min-width: 100%;
      max-width: 100%;
    }
  }
  .body {
    .text-wrap {
      padding: 16px 0;
      position: relative;
      .text {
        text-align: center;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        margin: auto;
        width: 80%;
      }
    }
  }
  .btn {
    margin-right: 12px;
  }
}

.field_name {
  font-size: 16px !important;
  font-weight: bold !important;
  margin-bottom: 4px; // 行間を調整
  white-space: nowrap; // テキストの折り返しを防止
  overflow: hidden; // テキストが溢れた場合に隠す
  text-overflow: ellipsis; // テキストが溢れた場合に省略記号を表示
}

.company_name {
  font-size: 10px !important;
  margin-bottom: 4px; // 行間を調整
}

.list-item-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16 16px;
}

.left-content {
  flex: 1;
  text-align: left;
}

.right-content {
  flex: 1;
  text-align: right;
  margin-left: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start; // 上揃えに変更
}

.v-list-item-subtitle, .v-list-item-title {
  margin-bottom: 4px; // 行間を調整
}

.role_name, .leader_name {
  display: inline-block;
  vertical-align: top;
}

.group_name {
  margin-top: 8px; // 「元請」を少し下に配置
}
</style>
