<template>
    <div>
        <v-data-table
        class="data"
        :headers="TABLE_LABELS"
        :items="LIST_DATA_MACHINE"
        :items-per-page="LIST_DATA_MACHINE.length"
        :height="tableHeight"
        fixed-header
        hide-default-footer
        disable-sort
        sort-by="updatedAt"
        noDataText="データがありません。"
        @update:page="$vuetify.goTo(0)"
        @click:row="openFormMachineDetail"
        :key=page
        >
        <template v-slot:[`item.status`]="{ item }">
            <v-chip
                class="set-color"
                v-bind:class="{colorOrange: item.machines.status==='未使用'?true:false}"
                :color="getColor(item.machines.status)"
                dark
            >
                {{item.machines.status}}
            </v-chip>
        </template>
        <template v-slot:[`item.number_plate`] = "{ item }">
            <div v-if="Object.keys(item.machines) != 0">
                {{item.machines.number_plate}}
            </div>
        </template>
        <template v-slot:[`item.model`] = "{ item }">
            <div v-if="Object.keys(item.machines) != 0" class="mode-text-align">
                <span>{{item.machines.floor_height}}m </span>
                <span>{{item.machines.lifting_method}} </span>
                <span>{{item.machines.undercarriage}} </span>
                <span>{{item.machines.working_floor}}</span>
            </div>
        </template>
        <template v-slot:[`item.reservation_person`] = "{ item }">
            <div v-if="Object.keys(item.bookings) != 0">
                <span>{{item.bookings.booking_field_group_name}} </span>
                <span>{{item.bookings.booking_user_name_sei}} {{item.bookings.booking_user_name_mei}}</span><br/>
                <span>{{item.bookings.booking_user_phone}}</span>
            </div>
        </template>
        <template v-slot:[`item.date_booking`] = "{ item }">
            <div v-if="Object.keys(item.bookings) != 0">
                <span>{{ formatDate(item.bookings.use_start_date) }} </span>
                <span>{{ getTime(item.bookings.use_start_time) }}</span><br/>
                <span>～{{ formatDate(item.bookings.use_end_date) }} </span>
                <span>{{ getTime(item.bookings.use_end_time) }}</span>
            </div>
        </template>
        <template v-slot:[`item.place_of_detention`] = "{ item }">
            <div v-if="Object.keys(item.machines) != 0" style="word-break:break-all;">
                <span> {{ item.machines.place_name }} </span>
            </div>
        </template>
        <template v-slot:[`item.inventory_date`] = "{ item }">
            <div v-if="Object.keys(item.machine_move_histories) != 0">
                <span>{{ formatDateTime(item.machine_move_histories.move_at) }}</span>
            </div>
        </template>
        <template v-slot:[`item.date_field_machines`] = "{ item }">
            <div v-if="Object.keys(item.field_machines) != 0">
                <span>{{ formatDate(item.field_machines.start_bookable_date) }}</span><br/>
                <span> ～{{ formatDate(item.field_machines.end_bookable_date) }}</span>
            </div>
        </template>
        </v-data-table>
        <Popup width="480px" :dialog="isShowMachineDetails">
            <MachineDetails 
            :item="editedItem"
            :userRole="userRole"
            :originItem="originItem"
            :isEmitted="isEmitted"
            @formUpdate="formUpdate"
            @submit="submitForm"
            @cancel="closeItemForm"/>
        </Popup>
    </div>
</template>
<script>
import { Store } from "@/store/Store.js";
import {
    MACHINE_INVENTORY_TAB_TABLE_LABELS
} from "@/constants/FIELD_MACHINE";
import moment from "moment";
import _ from "lodash";
import Popup from "@/components/common/Popup.vue";
import MachineDetails from "./components/MachineDetails.vue"

//テーブルヘッダーラベル
const TABLE_LABELS = MACHINE_INVENTORY_TAB_TABLE_LABELS;

//ストア
const STORE = "Machines";

export default {
    data() {
        return {
            TABLE_LABELS,
            isShowMachineDetails: false,
            editedItem: {},
            originItem: {},
            isEmitted: false,
            userRole: ""
        }
    },
    components: {
        Popup,
        MachineDetails
    },
    props: {
        LIST_DATA_MACHINE: {
            type: Array
        },
        tableHeight: {
            type: Number
        },
        page: {
            type: Number
        },
        fieldId: {
            type: Number
        }
    },
    created() {
        this.getUserRole();
    },
    methods: {
        getTime: function(time) {
            if(time == 0) return 'AM';
            else if(time == 1) return 'PM';
        },
        formatDate: function(date) {
            return moment(new Date(date)).format('YYYY/MM/DD');
        },
        formatDateTime: function(date) {
            return moment(new Date(date)).format('YYYY/MM/DD HH:mm');
        },
        getColor(status) {
            if (status === '使用中' ) return '#274FC2'
            else if (status === '未使用') return '#E5951D'
            else if (status === '予約あり') return '#629B4D'
        },
        formUpdate(params) {
            this.editedItem = { ...params };
        },
        getUserRole() {
            this.userRole = JSON.parse(sessionStorage.getItem("CURRENT_SITE")).role;
        },
        async openFormMachineDetail(item) {
            let params = { 
                field_id: this.fieldId,
                machine_id: item.machines.manchine_id,
            }
            const siteInfo = await Store.dispatch(
                `${STORE}/getDetailMachineInventory`,
                {params}
            );
            // Show Detail Form
            if (!siteInfo.hasError) {
                this.editedItem = _.cloneDeep(siteInfo?.data?.contents?.entries);
                this.originItem = _.cloneDeep(siteInfo?.data?.contents?.entries);
                this.isShowMachineDetails = true;
            }
        },
        async submitForm() {
            this.editedItem.field_id = this.fieldId;
            this.editedItem.machine_id = this.editedItem.id;
            const editedItem = _.cloneDeep(this.editedItem);
            const result = await Store.dispatch(
                `${STORE}/updateDetailMachineInventory`,
                editedItem
            );
            let message = result?.data?.message;
            if (result.hasError) {
                this.isErrorSubmit = true;
                return;
            } else {
                this.isResetForm = !this.isResetForm;
                Store.dispatch("Toast/show", {
                status: 200,
                message: message,
                });
                let params = { 
                    field_id: this.fieldId,
                    machine_id: this.editedItem.id,
                }
                const resultDetail = await Store.dispatch(
                `${STORE}/getDetailMachineInventory`,
                {params}
                );
                this.editedItem = _.cloneDeep(resultDetail.data.contents.entries);
                this.originItem = _.cloneDeep(resultDetail.data.contents.entries);
                this.isErrorSubmit = false;
            }
            this.$emit('reload');
            this.isEmitted = !this.isEmitted;
        },
        closeItemForm() {
            this.isShowMachineDetails = false;
        },
        
    }
}
</script>
<style lang="scss" scoped>
.layout-machine-container {
  .tab-select-header {
    height: 56px !important;
  }
  .data  {
      .set-color {
          width: 70px;
          font-size: 12px !important;
          color: #FFFFFF;
          font-weight: 400;
          text-align: center;
          border-radius: 16px;
          height: 32px;
          line-height: 32px;
          display:block;
          pointer-events: none;
      }
      .colorOrange {
        color: rgba(0, 0, 0, 0.87);
      }
      .green {
          background: #629B4D;
      }
      .primary {
        background: #274FC2;
      }
      .yellow {
          color: rgba(0, 0, 0, 0.87) !important;
          background: #E5951D;
      }
      .mode-text-align {
          text-align: justify;
      }
  }
}
</style>