<template>
  <div ref="self" class="FormDialog" :class="$sp ? 'sp' : ''">
    <v-card height="100%">
      <v-card-title ref="FormHeader">
        <slot name="header" />
      </v-card-title>
      <v-card-text>
        <v-container>
          <slot name="main" :params="params" />
        </v-container>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
export default {
  data: () => {
    return {
      params: {
        mainHeight: 10,
        headerHeight: 10,
      },
    };
  },
  mounted() {
    this.$nextTick(() => {
      setTimeout(() => {
        this.getWindowSize();
      }, 500);
    });
    window.addEventListener("resize", () => {
      this.getWindowSize();
    });
  },
  methods: {
    getWindowSize() {
      const heightWindow = window.innerHeight - 20; // margin bottom
      const self = this.$refs.self;
      const header = this.$refs.FormHeader;
      if (self) {
        //全体のサイズを取得
        const selfRect = self.getBoundingClientRect();
        const headerRect = header.getBoundingClientRect();
        //params全体を更新
        let _params = { ...this.params };
        //ヘッダーの高さを引いてMainを算出
        _params.headerHeight = headerRect.height;
        _params.mainHeight = this.$sp
          ? selfRect.height - headerRect.height - 48
          : heightWindow * 0.9 - headerRect.height; // class FormDialog: height: 90%
        this.params = _params;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.FormDialog {
  position: fixed;
  top: 5%;
  left: 5%;
  width: 90%;
  height: 90%;
  z-index: 2;

  &.sp {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    .v-card__text {
      padding: 0 !important;
      margin-bottom: 128px;

      .v-list-item {
        padding: 0 !important;
      }
    }
  }
}
</style>
<style lang="scss">
.FormDialog.sp .v-card__text {
  margin-bottom: 128px;
}
.FormDialog.sp .v-card__text .v-list-item {
  padding: 0 !important;
}
</style>
