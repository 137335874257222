import moment from "moment";
import { format } from "date-fns";
const getTimeToParam = (time) => {
  const _time = time.split(":");
  return {
    h: +_time[0],
    m: +_time[1],
  };
};

const getTimeRange = (start, end) => {
  if (!start || !end) {
    return {
      h: 0,
      m: 0,
      value: "00:00",
    };
  }
  const startTime = getTimeToParam(start);
  const endTime = getTimeToParam(end);
  const startTimeMin = +startTime.h * 60 + +startTime.m;
  const endTimeMin = +endTime.h * 60 + +endTime.m;
  const rangeTimeMin = endTimeMin - startTimeMin;
  const h = Math.floor(rangeTimeMin / 60);
  const m = rangeTimeMin - h * 60;
  return {
    h: h,
    m: m * 60,
    value: `${`${h}`.padStart(2, "0")}:${`${m}`.padStart(2, "0")}`,
  };
};

// 取得日付：日本の日付フォーマット（曜日：有）
const getDateJp = (dateStr) => {
  let date;
  if (dateStr) {
    date = new Date(dateStr);
  } else {
    date = new Date();
  }
  var dayOfWeek = date.getDay(); // 曜日(数値)
  var dayOfWeekStr = ["日", "月", "火", "水", "木", "金", "土"][dayOfWeek]; // 曜日(日本語表記)
  var formattedDay =
    date.getFullYear() +
    "/" +
    ("0" + (date.getMonth() + 1)).slice(-2) +
    "/" +
    ("0" + date.getDate()).slice(-2) +
    " " +
    `（${dayOfWeekStr}）`;
  var formattedTime =
    ("0" + date.getHours()).slice(-2) +
    ":" +
    ("0" + date.getMinutes()).slice(-2) +
    ":" +
    ("0" + date.getSeconds()).slice(-2);

  return { date: formattedDay, time: formattedTime };
};

const getDayOfWeekStr = (dateStr) => {
  let date;
  if (dateStr) {
    date = new Date(dateStr);
  } else {
    date = new Date();
  }
  var dayOfWeek = date.getDay(); // 曜日(数値)
  return ["日", "月", "火", "水", "木", "金", "土"][dayOfWeek]; // 曜日(日本語表記)
}

// 日付変換：スラッシュ区切りの日付
const fmtSlashDate = (dateStr) => {
  let date;
  if (dateStr) {
    date = new Date(dateStr);
  } else {
    return dateStr;
  }
  var formattedDay =
    date.getFullYear() +
    "/" +
    ("0" + (date.getMonth() + 1)).slice(-2) +
    "/" +
    ("0" + date.getDate()).slice(-2);
  var formattedTime =
    ("0" + date.getHours()).slice(-2) +
    ":" +
    ("0" + date.getMinutes()).slice(-2) +
    ":" +
    ("0" + date.getSeconds()).slice(-2);

  return { date: formattedDay, time: formattedTime };
};

const compareStartEndDate = (start, end) => {
  if (start !== "Invalid Date" && !isNaN(start) && end !== "Invalid Date" && !isNaN(end)) {
    var startDate = moment(start);
    var futureMonth = moment(startDate).add(1, "months");
    if (startDate.date() == 30 && startDate.month() == 0) {
      let temp = startDate.year() % 4 == 0 ? 28 : 27;
      futureMonth.set({ "year": startDate.year(), "month": 1, "date": temp });
    }
    if (startDate.date() == 29 && startDate.month() == 0 && startDate.year() % 4 !== 0) {
      futureMonth.set({ "year": startDate.year(), "month": 2, "date": 1 });
    }
    return format(new Date(end), "yyyy/MM/dd") < format(new Date(futureMonth), "yyyy/MM/dd");
  }
};

export { getTimeToParam, getTimeRange, getDateJp, getDayOfWeekStr, fmtSlashDate, compareStartEndDate };
