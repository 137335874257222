import isMobile from "ismobilejs";

export const isSP = () => {
  //サイズで判定
  // return window.innerWidth <= 600;

  //ユーザーエージェントで判定
  return isMobile(window.navigator).phone;
};

export const bodyScrollLock = (val) => {
  document.querySelector("body").style.overflow = val ? "hidden" : "auto";
};
