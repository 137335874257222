<template>
  <v-card class="dialog">
    <div class="header sticky">
      <v-card-title small>
        <div class="title">{{ title }}</div>
        <v-spacer></v-spacer>
        <v-btn small icon text @click="$emit('close')">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
    </div>
    <SearchSelectList
      :formValues="formValues"
      :title_search="title_search"
      @search="search"
      @formUpdate="formUpdate"
    >
      <template v-for="item in formValues.items">
        <v-list-item :key="item[item_value]" ripple style="cursor: pointer;">
          <v-list-item-content @click="onClick(item[item_value])">
            <v-list-item-title class="item-text">
              {{ item[item_text] }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
      <div style="display: flex;justify-content: center" v-if="formValues.items.filter(e => e[item_value]).length == 0">
        {{ NO_DATA_MESSAGE }}
      </div>
    </SearchSelectList>
  </v-card>
</template>

<script>
import SearchSelectList from "@/components/forms/elements/SearchSelectList";
import { NO_DATA_MESSAGE } from "@/constants/COMMON";

export default {
  components: {
    SearchSelectList
  },

  props: {
    title: String,
    formValues: {
      type: Object,
      default: {
        search: "",
        items: [],
        selected: null
      }
    },
    onSearch: Function,
    close: Function,
    item_text: {
      type: String,
      default: "name"
    },
    item_value: {
      type: String,
      default: "id"
    },
    title_search:{
      type : String
    }
  },

  data: () => {
    return {
      keyword: "",
      NO_DATA_MESSAGE
    };
  },

  methods: {
    search() {
      this.$emit("onSearch", { keyword: this.keyword });
    },

    formUpdate(values) {
      this.keyword = values.keyword;
      this.$emit("formUpdate", values);
    },

    onClick(id) {
      if(id) {
        const values = { ...this.formValues };
        values.selected = id;
        this.$emit("formUpdate", values);
      }else{
        this.$emit("reset");
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.dialog {
  padding-bottom: 20px;
  .header {
    height: 65px;
    position: sticky;
    top: 0;
    background-color: white;
    z-index: 1;
    .header-title-area {
      display: flex;
      justify-content: space-between;
      min-width: 100%;
      max-width: 100%;
    }
  }
  .body {
    .text-wrap {
      padding: 16px 0;
      position: relative;
      .text {
        text-align: center;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        margin: auto;
        width: 80%;
      }
    }
  }
  .btn {
    margin-right: 12px;
  }
}

.item-text {
  font-size: 16px !important;
  font-weight: bold !important;
}
</style>
