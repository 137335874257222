<template>
  <div class="FormMain">
    <div class="FormMainBody">
      <v-card flat>
        <v-card-text>
          <ValidationObserver>
            <v-form autocomplete="off">
              <v-container>
                <v-col cols="6" sm="6" md="6">
                  <v-row>
                    <v-col>
                      <v-list-item-content>
                        <Label
                          label="所属名"
                          class="title-input-item"
                          :editable="editable"
                          required
                        >
                          <InputText
                            name="name"
                            :values="formValues"
                            :editable="editable"
                            validation_label="所属名"
                            validation_rules="max:20|required"
                            @onInput="onInput('', $event)"
                          />
                        </Label>
                      </v-list-item-content>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="6" sm="6" md="6">
                  <v-row>
                    <v-col>
                      <v-list-item-content>
                        <Label
                          label="代表者"
                          class="title-input-item"
                          :editable="editable"
                        >
                          <InputText
                            name="leader_name"
                            :values="formValues"
                            :editable="editable"
                            validation_label="代表者"
                            validation_rules="max:20"
                            @onInput="onInput('', $event)"
                          />
                        </Label>
                      </v-list-item-content>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="6" sm="6" md="6">
                  <v-row>
                    <v-col>
                      <v-list-item-content>
                        <Label
                          label="作成者"
                          class="title-input-item"
                          :editable="false"
                        >
                          <v-text-field
                            v-model="createUserName"
                            dense
                            color="primary"
                            :disabled="true"
                            :filled="false"
                          ></v-text-field>
                        </Label>
                      </v-list-item-content>
                    </v-col>
                    <v-col>
                      <v-list-item-content>
                        <Label
                          label="作成日時"
                          class="title-input-item"
                          :editable="false"
                        >
                          <InputText
                            name="created_at"
                            :values="formValues"
                            :editable="false"
                          />
                        </Label>
                      </v-list-item-content>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="6" sm="6" md="6">
                  <v-row>
                    <v-col>
                      <v-list-item-content>
                        <Label
                          label="最終更新者"
                          class="title-input-item"
                          :editable="false"
                        >
                          <v-text-field
                            v-model="updateUserName"
                            dense
                            color="primary"
                            :disabled="true"
                            :filled="false"
                          ></v-text-field>
                        </Label>
                      </v-list-item-content>
                    </v-col>
                    <v-col>
                      <v-list-item-content>
                        <Label
                          label="最終更新日時"
                          class="title-input-item"
                          :editable="false"
                        >
                          <InputText
                            name="updated_at"
                            :values="formValues"
                            :editable="false"
                          />
                        </Label>
                      </v-list-item-content>
                    </v-col>
                  </v-row>
                </v-col>
              </v-container>
            </v-form>
          </ValidationObserver>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>
<script>
import { ValidationObserver } from "vee-validate";
import Label from "@/components/forms/elements/Label";
import SectionLabel from "@/components/forms/elements/SectionLabel";
import InputText from "@/components/forms/elements/InputText";
import SelectSearch from "@/components/forms/elements/SelectSearch";
import SearchSelectListHight from "@/components/forms/elements/SearchSelectListHight";
import InputDatepicker from "@/components/forms/elements/InputDatepicker.vue";
import RadioGroup from "@/components/forms/elements/RadioGroup.vue";
import Select from "@/components/forms/elements/Select.vue";
import FileInput from "@/components/forms/elements/FileInput";
import _ from "lodash";
import { COMPANY_RENTAL, MODE_CREATE } from "@/constants/COMMON";
import { COMPANY_TYPE, RENTAL_USER_TYPE, ENGINEERS } from "@/constants/COMPANIES";
import { USER_ACTIVE, USER_DEACTIVE } from "@/constants/USER";

export default {
  data: () => {
    return {
      formValues: {
        name: '',
        leader_name: '',
      },
      resetValue: {},
      ccusIdCheck: false,
      plateNumberPrefixCheck: false,
      usersEmailCheck: false,
      usersPhoneCheck: false,
      usersNameSeiCheck: false,
      usersNameMeiCheck: false,
      usersKanaSeiCheck: false,
      usersKanaMeiCheck: false,
      COMPANY_TYPE: COMPANY_TYPE,
      RENTAL_USER_TYPE,
      company: {},
      companyType: null,
      COMPANY_RENTAL,
      ENGINEERS,
      MODE_CREATE,
      USER_ACTIVE,
      USER_DEACTIVE,
    };
  },
  components: {
    ValidationObserver,
    Label,
    SectionLabel,
    InputText,
    InputDatepicker,
    Select,
    FileInput,
    SelectSearch,
    SearchSelectListHight,
    RadioGroup,
  },
  props: {
    isRental: Boolean,
    editable: {
      type: Boolean,
      default: false,
    },
    detailData: Object,
    mainHeight: {
      type: Number,
      default: 0,
    },
    formUpdate: Function,
    actions: String,
    active_flg: Number,
  },
  computed: {
    createUserName() {
      if (this.formValues?.create_user) {
        return `${this.formValues.create_user.name_sei} ${this.formValues.create_user.name_mei}`;
      }
      return '';
    },
    updateUserName() {
      if (this.formValues?.update_user) {
        return `${this.formValues.update_user.name_sei} ${this.formValues.update_user.name_mei}`;
      }
      return '';
    },
  },
  mounted() {
    this.$watch(
      () => this.detailData,
      (data) => {
        this.formValues = _.cloneDeep(data);
      },
      {
        immediate: true,
        deep: true
      }
    );
  },
  methods: {
    onInput(parent_name, { name, value }) {
      this.formValues[name] = value;
      this.$emit("formUpdate", this.formValues);
    },
  },
};
</script>
<style lang="scss">
@import "@/components/forms/style/forms.scss";

.title-input-item .label {
  color: #000000;
}
</style>
