<template>
  <!-- Inspection Details Form -->
  <v-dialog persistent v-model="showModel" max-width="90%" min-width="1000px" v-if="showModal" :content-class=getClass()>
      <Loading v-if="isLoading"/>
      <v-card class="tenken-form-list-container popup-tenken" style="position: relative;">
        <div class="header">
          <v-toolbar-title color="primary" class="primary--text header-title">
            {{ titelModal }}
          </v-toolbar-title> 
          <div class="header-button-container">
            <div class="header-action-button-container" v-if="typeModal === 'booking' || typeModal === 'machine'">
              <v-btn depressed color="primary" @click="dialogConfirm = true" v-if="roleLogin === 1 && attachFileType === null && (repairStatus == LIST_KEY_NAME_STATUS_REPORT_TAB.notCompatible) && !(itemResult === 0)" class="pr-10">現場で対応
                <Popup width="480px" :dialog="dialogConfirm">
                  <ExtendDialog v-model="dialogConfirm" title="現場で対応" text1="現場で対応します。" text2="この処理を元に戻すことはできません。"
                    acceptBtn="確定" @close="dialogConfirm = false" @yes="updateHandle()">
                  </ExtendDialog>
                </Popup>
              </v-btn>
              <v-btn depressed color="warning" class="pr-10" @click="setName" v-if="roleLogin === 1 && attachFileType === null && (repairStatus == LIST_KEY_NAME_STATUS_REPORT_TAB.notCompatible) && !(itemResult === 0)">レンタル会社を手配
                <Popup width="480px" :dialog="dialogSubmit">
                  <v-card class="dialog">
                    <ValidationObserver v-slot="{ invalid }">
                      <v-card-title>
                        <div class="title" style="color: #274FC2;">レンタル会社を手配</div>
                        <v-spacer></v-spacer>
                        <Popup width="480px" :dialog="isShowCancel">
                          <ConfirmCloseDialog
                            title="フォームを閉じる確認"
                            :text1="WRN_0002.TEXT1"
                            :text2="WRN_0002.TEXT2"
                            @close="isShowCancel = false"
                            @yes="cancelSenData()"
                            :warning="true"
                          />
                        </Popup>
                        <v-icon @click="isShowCancel = true">mdi-close</v-icon>
                      </v-card-title>
                      <v-card-text class="body">
                        <div class="text-wrap">
                          <div class="text">{{ nameCompanyRental }}</div>
                        </div>
                        <div class="textAr">
                          <v-col cols="6" lg="12" md="12">
                            <ValidationProvider v-slot="{ errors, valid }" name="メッセージ" rules="minmax:0,255">
                              <v-textarea v-model="message" dense filled :error-messages="errors" :success="valid" />
                            </ValidationProvider>
                          </v-col>
                        </div>
                      </v-card-text>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn depressed outlined class="btn" color="primary" @click="isShowCancel = true"> やめる </v-btn>
                        <v-btn depressed class="btn" color="primary" @click="senData" :disabled="invalid || isSubmitted"> 手配 </v-btn>
                      </v-card-actions>
                    </ValidationObserver>
                  </v-card>
                </Popup>
              </v-btn>
            </div>
            <div class="header-action-button-container" v-else-if="typeModal === 'inspection'">
              <v-btn depressed color="warning" class="pr-10" @click="dialogUnapproved = true">承認解除
                <Popup width="480px" :dialog="dialogUnapproved">
                  <ExtendDialog
                    v-model="dialogUnapproved"
                    title="承認解除"
                    text1="承認解除"
                    acceptBtn="承認解除"
                    :warning="true"
                    @close="dialogUnapproved = false"
                    @yes="updateInspectionStatus(1)"
                  />
              </Popup>
              </v-btn>
              <v-btn depressed color="primary" @click="dialogApproval = true" class="pr-10">承認
                <Popup width="480px" :dialog="dialogApproval">
                  <ExtendDialog
                    v-model="dialogApproval"
                    title="承認"
                    text1="承認"
                    acceptBtn="承認"
                    @close="dialogApproval = false"
                    @yes="updateInspectionStatus(0)"
                  />
                </Popup>
              </v-btn>
            </div>
            <v-btn icon @click="close()">
              <v-icon>
                mdi-close
              </v-icon>
            </v-btn>
          </div>
        </div>
        <div class="action-container">
          <div class="action">
            <span class="action-before">レ</span>
            <span>良好</span>
          </div>
          <div class="action">
            <span class="action-before">◯</span>
            <span>修理して使用する</span>
          </div>
          <div class="action">
            <span class="action-before">× </span>
            <span>故障のため使用しない</span>
          </div>
        </div>
        <div class="tab-container">
          <v-tabs v-model="selectedTab" align-with-title :class="[callFromTable != REPAIR_REQUESTS_TABLE ? 'ml-n4' : 'ml-6']">
            <v-tab v-show="callFromTable != REPAIR_REQUESTS_TABLE" @click="attachFileType = null">点検結果</v-tab>
            <v-tab v-if="!(itemResult === 0)" @click="attachFileType = null">写真</v-tab>
            <v-tab v-if="repairStatus && repairStatus != 1" @click="attachFileType = 0, getRepairAttach()">修理見積書</v-tab>
            <v-tab v-if="repairStatus && repairStatus != 1" @click="attachFileType = 1, getRepairAttach()">修理報告書</v-tab>
          </v-tabs>
        </div>
        <div class="table-container-inspection" v-if="selectedTab === 0 && callFromTable != REPAIR_REQUESTS_TABLE">
          <div class="header-inspection-result">
            <div style="width: 30%;" class="font-weight-black">高車情報</div>
            <div style="width: 70%; padding-left:73px;" class="font-weight-black">点検項目</div>
          </div>
          <div class="inspection-result">
            <div class="inspection-result-list">
              <div class="inspection-result-item">
                <p>{{ mottoChange }}</p>
                <p>カーナンバー <br /> <span v-if="resultDetails.length">{{ machineInfo.number_plate }}</span></p>
                <p>位置情報<br /> <span v-if="resultDetails.length">{{ machineInfo.place_name }}</span></p>
                <p>報告者 <br /> 
                  <span v-if="annunciator">
                    {{ annunciator.field_group_name }} {{ annunciator.user_name_sei }} {{ annunciator.user_name_mei }} <br />
                    {{ annunciator.user_phone }}
                  </span>
                </p>
              </div>
            </div>
            <div class="inspection-items">
              <div v-for="result in resultDetails">
                <span style="padding-right: 40px;">
                  <v-icon style="color: green;" v-if="result.result === 0">
                    mdi-checkbox-marked-circle
                  </v-icon>
                  <v-icon style="color: green;" v-else-if="result.result === 1">
                    mdi-close-circle
                  </v-icon>
                  <v-icon style="color: green;" v-else>
                    mdi-checkbox-blank-circle-outline
                  </v-icon>
                </span>
                <span class="inspection-result-item">{{ result.inspect_item }}</span>
              </div>
            </div>
          </div>
        </div>
        <div v-if="selectedTab === 1" class="table-container-scroll bg-tab">
          <div class="list-image">
            <div class="container-comment" style="width: 25%; padding-top: 25px;">
              <span>コメント</span>
              <div class="messager-content">
                {{ photoComment }}
              </div>
            </div>
            <Gallery :list-video="listVideo" v-if="listVideo?.length"/>
            <div class="messager-no-data" v-if="listVideo === undefined || listVideo?.length === 0">
              {{NO_DATA}}
            </div>
          </div>
        </div>
        <div class="table-container-scroll" v-if="selectedTab === 2">
          <div style="width: 100%; padding: 10px 60px;">
            <v-btn class="action-header-top" round depressed style="width: 124px;" v-if="loginUserCompanyType === 2" color="primary" @click="onBtnUploadFile(2)">
              アップロード
            </v-btn>
            <input
              ref="uploader"
              class="d-none"
              type="file"
              accept="application/pdf"
              @change="onFileChanged"
            >
          </div>
          <div class="repair-quote" v-if="repairAttach.length">
            <div class="repair-quote-content">
              <div class="repair-quote-content-file" v-for="item in repairAttach">
                <span class="item-child-file text-truncate d-inline-block name-file-truncate" @click="updatePreviewsFile(item)"
                  style="color: #0000FF; cursor: pointer; text-overflow: ellipsis; width: 180px"
                  :style="selectedPdfId == item.id ? {'text-decoration' : 'underline'} : ''"
                >
                  {{item.file_name}}
                </span>
                <span class="item-child-file">{{(item.created_at) ? item.created_at : getToDay()}}</span>
                <span class="item-child-file" style="cursor: pointer; padding-right: 7px !important;" v-if="roleLogin !== 2" @click="downloadDocument(item)">
                  <v-icon :disabled="isSubmitted">
                    mdi-download
                  </v-icon>
                </span>
              <span class="item-child-file" style="cursor: pointer;" @click="cancelRemoveFile = true, setIdRemove(item.id)" v-if="loginUserCompanyType === 2">
                  <v-icon>
                    mdi-close
                  </v-icon>
                </span>
              </div>
              <Popup width="480px" :dialog="cancelRemoveFile">
                <ConfirmCloseDialog
                  title="添付ファイルを削除します。"
                  text1="本当に削除してもよろしいですか？"
                  text2="この処理を元に戻すことはできません。"
                  textSubmit="削除"
                  @close="cancelRemoveFile = false"
                  @yes="deleteUrlFile()"
                  warning
                />
              </Popup>
            </div>
            <div class="repair-quote-content-reviews" style="width: 66%; margin: 0px 65px;">
                <div ref="pdfNavigation" class="pdfNavigation">
                <div class="pdfNavigation__pages">
                  {{ pdfPage.currentPage }} / {{ pdfPage.pageCount }}
                </div>
                <div class="pdfNavigation__navi">
                  <v-btn
                    small
                    icon
                    :disabled="pdfPage.currentPage === 1"
                    @click="pdfNaviPrev"
                  >
                    <v-icon>mdi-chevron-left</v-icon>
                  </v-btn>
                  <v-btn
                    small
                    icon
                    :disabled="pdfPage.currentPage === pdfPage.pageCount"
                    @click="pdfNaviNext"
                  >
                    <v-icon>mdi-chevron-right</v-icon>
                  </v-btn>
                </div>
              </div>
              <div ref="pdfViewer" class="pdfViewer" :style="pdfPage.style">
                <v-card outlined tile v-if="urlFilePdf">
                  <!-- PDF -->
                  <pdf
                    :src="urlFilePdf"
                    :page="pdfPage.selectPage"
                    @num-pages="pdfPage.pageCount = $event"
                    @page-loaded="pdfPage.currentPage = $event"
                  ></pdf>
                </v-card>
              </div>
            </div>
          </div>
          <div class="text-center" v-else>
            {{NO_DATA}}
          </div>
        </div>
        <div class="table-container-scroll" v-if="selectedTab === 3">
          <div style="width: 100%; padding: 10px 60px;">
            <v-btn class="action-header-top" round depressed style="width: 124px;" v-if="loginUserCompanyType === 2" color="primary" @click="onBtnUploadFile(3)">
              アップロード
            </v-btn>
            <input
              ref="uploader"
              class="d-none"
              type="file"
              accept="application/pdf"
              @change="onFileChanged"
            >
          </div>
          <div class="repair-quote" v-if="repairAttach.length">
            <div class="repair-quote-content">
              <div class="repair-quote-content-file" v-for="item in repairAttach">
                <span class="item-child-file text-truncate d-inline-block name-file-truncate" @click="updatePreviewsFile(item)"
                  style="color: #0000FF; cursor: pointer; text-overflow: ellipsis; width: 180px;"
                  :style="selectedPdfId == item.id ? {'text-decoration' : 'underline'} : ''"
                >
                  {{item.file_name}}
                </span>
                <span class="item-child-file">{{(item.created_at) ? item.created_at : getToDay()}}</span>
                <span class="item-child-file" style="cursor: pointer; padding-right: 7px !important;" v-if="roleLogin !== 2" @click="downloadDocument(item)">
                  <v-icon>
                    mdi-download
                  </v-icon>
                </span>
              <span class="item-child-file" style="cursor: pointer;" @click="cancelRemoveFile = true, setIdRemove(item.id)" v-if="loginUserCompanyType === 2">
                  <v-icon>
                    mdi-close
                  </v-icon>
                </span>
              </div>
              <Popup width="480px" :dialog="cancelRemoveFile">
                <ConfirmCloseDialog
                  title="添付ファイルを削除します。"
                  text1="本当に削除してもよろしいですか？
                    入力内容は保存されません。"
                  text2="この処理を元に戻すことはできません。"
                  textSubmit="削除"
                  @close="cancelRemoveFile = false"
                  @yes="deleteUrlFile()"
                  warning
                />
              </Popup>
            </div>
            <div class="repair-quote-content-reviews" style="width: 66%; margin: 0px 65px;">
                <div ref="pdfNavigation" class="pdfNavigation">
                <div class="pdfNavigation__pages">
                  {{ pdfPage.currentPage }} / {{ pdfPage.pageCount }}
                </div>
                <div class="pdfNavigation__navi">
                  <v-btn
                    small
                    icon
                    :disabled="pdfPage.currentPage === 1"
                    @click="pdfNaviPrev"
                  >
                    <v-icon>mdi-chevron-left</v-icon>
                  </v-btn>
                  <v-btn
                    small
                    icon
                    :disabled="pdfPage.currentPage === pdfPage.pageCount"
                    @click="pdfNaviNext"
                  >
                    <v-icon>mdi-chevron-right</v-icon>
                  </v-btn>
                </div>
              </div>
              <div ref="pdfViewer" class="pdfViewer" :style="pdfPage.style">
                <v-card outlined tile v-if="urlFilePdf">
                  <!-- PDF -->
                  <pdf
                    :src="urlFilePdf"
                    :page="pdfPage.selectPage"
                    @num-pages="pdfPage.pageCount = $event"
                    @page-loaded="pdfPage.currentPage = $event"
                  ></pdf>
                </v-card>
              </div>
            </div>
          </div>
          <div class="text-center" v-else>
            {{NO_DATA}}
          </div>
        </div>
      </v-card>
    </v-dialog>
</template>
<script>
import pdf from "vue-pdf";
import { NO_DATA, SUBMIT_DELAY_TIMEOUT } from "@/constants/COMMON";
import { TYPE_REPORT_KIND_API, TYPE_REPORT_KIND, LIST_KEY_NAME_STATUS_REPORT_TAB } from "@/constants/MACHINE";
import Gallery from "@/components/gallery/Gallery.vue";
import { Store } from "@/store/Store.js";
import moment from "moment";
import Popup from "@/components/common/Popup.vue";
import ExtendDialog from "@/components/dialog/ExtendDialog.vue";
import ConfirmCloseDialog from "@/components/dialog/ConfirmCloseDialog.vue";
import { WRN_0002 } from "@/constants/MESSAGE";
import axios from "axios";
import Loading from "@/components/loading/Loading";
import Label from "@/components/forms/elements/Label";
import { ValidationProvider, ValidationObserver } from "vee-validate";

import { getParamsOfUrlReadFile } from "@/utils/viewSourceAuth";
import _ from "lodash";

const REPAIR_STATUS_ALLOW = [2, 3, 4];
const REPAIR_REQUESTS_TABLE = "2";

export default {
  data: () => ({
    NO_DATA,
    selectedTab: 0,
    listVideo: [],
    highCarInformation: [],
    photoComment: null,
    annunciator: null,
    mottoChange: null,
    resultDetails: [],
    machineInfo: {},
    repairAttach: [],
    urlFilePdf: null,
    repairStatus: null,
    currentSite: null,
    roleLogin: null,
    attachFileType: null,
    isShowCancel: false,
    message: "",
    dialogSubmit: false,
    dialogConfirm: false,
    repairAttachId: null,
    cancelRemoveFile: false,
    idRepairAttachRm: null,
    showDialog: false,
    isLoading: false,
    ids: [],
    WRN_0002: WRN_0002,
    pdfPage: {
      pageCount: 0,
      currentPage: 0,
      selectPage: 1,
      style: "",
    },
    isNoDataMessage: false,
    dialogUnapproved: false,
    dialogApproval: false,
    loginUserCompanyType: JSON.parse(sessionStorage.getItem("USER"))?.Login?.user?.company?.company_type,
    REPAIR_STATUS_ALLOW,
    REPAIR_REQUESTS_TABLE,
    TYPE_REPORT_KIND_API,
    TYPE_REPORT_KIND,
    LIST_KEY_NAME_STATUS_REPORT_TAB,
    showModal: false,
    selectedPdfId : null,
    uploadFromTab: null,
    SUBMIT_DELAY_TIMEOUT,
    isSubmitted: false
  }),

  components: {
    Gallery,
    ExtendDialog,
    Popup,
    ConfirmCloseDialog,
    pdf,
    Loading,
    Label,
    ValidationProvider,
    ValidationObserver
  },

  props: {
    showModel: {
      type: Boolean,
    },
    idSend: {
      type: Number,
    },
    idFieldMachine: {
      type: Number,
    },
    titelModal: {
      type: String,
    },
    nameCompanyRental: {
      type: String,
    },
    inspectorId: {
      type: Number,
    },
    typeModal: {
      type: String,
    },
    callFromTable: {
      type: String,
      default: "1",
    },
    repairStatusItem: {
      type: Number,
    },
    itemResult: {
      type: Number,
    }
  },

  watch: {
    async showModel() {
      if (this.idSend) {
        this.selectedTab = 0;
        this.ids = [this.idSend];
        if (this.callFromTable == this.TYPE_REPORT_KIND.inspectionResult) {
          await this.getInspectionDetail();
        } else {
          await this.getRepairRequestImage();
        }
      }
      if (this.callFromTable != REPAIR_REQUESTS_TABLE) {
        this.selectedTab = 0
      } else {
        this.selectedTab = 1
      }
    },
    callFromTable() {
      if (this.callFromTable != REPAIR_REQUESTS_TABLE) {
        this.selectedTab = 0
      } else {
        this.selectedTab = 1
      }
    },
    selectedTab() {
      this.pdfPage = {
        pageCount: 0,
        currentPage: 0,
        selectPage: 1,
        style: "",
      }
    }
  },
  mounted() {
    this.ids = [this.inspectorId];
    this.currentSite = JSON.parse(sessionStorage.getItem("CURRENT_SITE"));
    this.$watch(
      () => [Store.getters["GlobalHeader/getCurrentSite"], this.currentSite],
      (data) => {
        if (data[0]) {
          this.roleLogin = data[0].role;
        } else if (data[1]) {
          this.roleLogin = data[1].role;
        }
      },
      {
        immediate: true,
        deep: true,
      }
    );
    this.$watch(
      () => Store.getters[`Booking/getIsLoading`],
      (data) => {
        this.isLoading = data;
      },
      {
        immediate: true,
        deep: true,
      }
    );
  },
  methods: {
    getClass() {
      return "receive-detail-tenken-css-tab-" + this.selectedTab;
    },
    async getInspectionDetail() {
      const params = {
        'id': this.idSend
      };
      const result = await Store.dispatch(`Booking/getInspectionDetail`, params);
      if (!result.hasError && result.contents.entries) {
        const dataDetail = result.contents.entries.inspection_results;
        this.resultDetails = result.contents.entries.inspection_result_details;
        await this.getMachineInfo(dataDetail);
        if (dataDetail) {
          this.repairStatus = dataDetail.repair_status;
          if (!dataDetail.repair_status || this.loginUserCompanyType === 2) {
            if (dataDetail.result === 0) {this.mottoChange = "良好"}
            if (dataDetail.result === 1) {this.mottoChange = "故障のため使用しない"}
            if (dataDetail.result === 2) {this.mottoChange = "修理して使用する"}
          } else if (dataDetail.repair_status === 1) {
            this.mottoChange = "現場で対応";
          } else {
            this.mottoChange = `${this.machineInfo.company_name}を手配済み`;
          }
          this.annunciator = dataDetail;
          this.annunciator.company_name = this.annunciator.company_name + "　";
          this.repairAttachId = dataDetail.id;
        }
        this.getInspectionImage();
      }
      this.showModal = true;
    },

    async getInspectionImage() {
      const params = {
        'id': this.idSend
      };
      const result = await Store.dispatch(`Booking/getInspectionImage`, params);
      if (!result.hasError) {
        const dataList = result.contents.entries;
        this.listVideo = [];
        if (dataList) {
          this.photoComment = dataList.inspection_results?.comment || '';
          this.listVideo = dataList.inspection_result_images?.map?.(item => ({
            type: 'image',
            src: item?.image_url,
          }));
        }
      }
    },

    async getRepairRequestImage() {
      this.resultDetails = [];
      this.annunciator = [];
      this.repairStatus = this.repairStatusItem;
      const params = {
        'id': this.idSend
      };
      const result = await Store.dispatch("Booking/getRepairRequestDetail", params);
      const dataDetail = result.contents.entries.inspection_results;
      if (!result.hasError) {
        await this.getMachineInfo(dataDetail);
        this.photoComment = result.contents.entries.repair_requests.comment;
        const dataList = result.contents.entries.repair_request_images;
        this.listVideo = [];

        if (dataList) {
          this.listVideo = dataList.map(item => ({
            type: 'image',
            src: item?.image_url,
          }));
        }
      }
      this.showModal = true;
    },

    async deleteUrlFile() {
      const params = {
        'id': this.idRepairAttachRm
      };
      const result = await Store.dispatch(`Booking/deleteUrlFile`, params);
      if (!result.hasError) {
        this.cancelRemoveFile = false;
        this.selectedPdfId = null;
        this.getRepairAttach();
        this.pdfPage.selectPage = 1;
      }
    },

    getToDay() {
      return moment(new Date()).format('YYYY-MM-DD')
    },

    updatePreviewsFile(data) {
      // this.urlFilePdf = data?.file_url;
      const _urlFilePdf = data?.file_url;
      if (_urlFilePdf) {
        let paramsPath = "&folder=files&path=";
        let replace = "storage/files/"
        // let url = this.urlFilePdf.replace(replace, "api/file/read_file?name=") + paramsPath;
        let url = _urlFilePdf.replace(replace, "api/file/read_file?name=") + paramsPath;
        this.selectedPdfId = data.id;
        this.pdfPage.selectPage = 1;
        this.pdfPage.currentPage = 1;
        this.getUrlView(url)
      }
    },

    async getMachineInfo(dataDetail) {
      const params = {
        'id': this.idFieldMachine
      };
      const result = await Store.dispatch(`Booking/getMachineInfo`, params);
      if (!result.hasError) {
        this.machineInfo = result.contents.entries;
        // this.machineInfo.place_name = (this.machineInfo) ? this.machineInfo.place_name + "" : null;
        this.machineInfo.place_name = (dataDetail) ? dataDetail.place_name + "　" : null;
      }
    },

    async getRepairAttach() {
      const params = {
        "request_id" : this.repairAttachId? this.repairAttachId : this.idSend,
        "attach_file_type" : this.attachFileType,
        "repair_type" : this.getTypeApi()
      };
      this.repairAttach = [];
      this.urlFilePdf = null;
      const result = await Store.dispatch(`Booking/getRepairAttach`, params);
      if (!result.hasError) {
        this.repairAttach = result.contents.entries;
        // this.urlFilePdf = (this.repairAttach.length) ? this.repairAttach[0].file_url : null;
        const _urlFilePdf = (this.repairAttach.length) ? this.repairAttach[0].file_url : null;

        if (_urlFilePdf) {
          let paramsPath = "&folder=files&path=";
          let replace = "storage/files/"
          // let url = this.urlFilePdf.replace(replace, "api/file/read_file?name=") + paramsPath;
          let url = _urlFilePdf.replace(replace, "api/file/read_file?name=") + paramsPath;
          this.selectedPdfId = this.repairAttach[0].id;
          await this.getUrlView(url)
        }
      }
    },

    async updateHandle() {
      let response = await Store.dispatch(`Tenken/postUpdateTenken`, {
        ids: this.ids,
        type: this.getTypeApi(),
        fieldId: JSON.parse(sessionStorage.getItem("CURRENT_SITE")).field_id
      });
      if (!response.hasError) {
        Store.dispatch("Toast/show", {
          status: 200,
          message: "更新しました",
        });
        this.ids = [];
      }
      this.dialogConfirm = false;
      this.$emit('reload-data');
      if (this.typeModal !== 'machine') {
        this.getInspectionDetail();
      }
    },

    async senData() {
      this.isSubmitted = true;
      // Re-enable after submit
      setTimeout(() => {
        this.isSubmitted = false;
      }, SUBMIT_DELAY_TIMEOUT);
      let response = await Store.dispatch(`Tenken/postSenDataTenken`, {
        ids: this.ids,
        message: this.message,
        type: this.getTypeApi(),
        fieldId: JSON.parse(sessionStorage.getItem("CURRENT_SITE")).field_id
      });
      if (!response.hasError) {
        Store.dispatch("Toast/show", {
          status: 200,
          message: "更新しました",
        });
        this.ids = [];
      }
      this.dialogSubmit = false;
      this.message = "";
      this.$emit('reload-data');
      if (this.typeModal !== "machine") {
        this.getInspectionDetail();
      }
    },

    setIdRemove(id) {
      this.idRepairAttachRm = id;
    },

    cancelSenData: function () {
      this.message = "";
      this.isShowCancel = false;
      this.dialogSubmit = false;
    },

    setName: function () {
      this.dialogSubmit = true;
    },

    onBtnUploadFile(tab) {
      this.uploadFromTab = tab
      this.$refs.uploader.click();
    },

    async onFileChanged(e) {
      this.isLoading = true;
      const selectedFile = e.target.files[0];
      const dataForm = new FormData()
      dataForm.append('request_id', this.repairAttachId? this.repairAttachId : this.idSend);
      dataForm.append('repair_type', this.getTypeApi());
      dataForm.append('attach_file_type', this.attachFileType);
      dataForm.append('file', selectedFile);
      dataForm.append('from_tab', this.uploadFromTab);
      const result = await Store.dispatch(`Booking/uploadFileKenten`, dataForm);
      if (!result.hasError) {
        this.pdfPage.selectPage = 1;
        this.pdfPage.currentPage = 1;
        this.getRepairAttach();
        this.$emit("callReloadData");
      } else {
        this.isLoading = false;
      }
      this.$refs.uploader.value = null
    },

    dowloadFilePdf({file_url, file_name}) {
      axios.get(file_url, { responseType: 'blob' })
      .then(response => {
        const blob = new Blob([response.data], { type: 'application/pdf' })
        const link = document.createElement('a')
        link.href = URL.createObjectURL(blob)
        link.download = file_name
        link.click()
        URL.revokeObjectURL(link.href)
      }).catch(console.error)
    },

    pdfNaviPrev() {
      const pdfPage = { ...this.pdfPage };
      pdfPage.selectPage = pdfPage.selectPage > 1 ? pdfPage.selectPage - 1 : 1;
      this.pdfPage = pdfPage;
    },
    pdfNaviNext() {
      const pdfPage = { ...this.pdfPage };
      pdfPage.selectPage =
        pdfPage.selectPage < pdfPage.pageCount
          ? pdfPage.selectPage + 1
          : pdfPage.pageCount;
      this.pdfPage = pdfPage;
    },
    async updateInspectionStatus(approve_action) {
      const params = {
        "ids": [this.idSend],
        "approve_action": approve_action
      }
      const result = await Store.dispatch(`Inspection/updateInspectionApprovedStatus`, params);
      if (!result.hasError) {
        Store.dispatch("Toast/show", {
          status: 200,
          message: "承認しました",
        });
      }
      if (approve_action === 0) {
        this.dialogApproval = false;
      } else if (approve_action === 1) {
        this.dialogUnapproved = false;
      }
    },

    getTypeApi() {
      if (this.callFromTable == this.TYPE_REPORT_KIND.inspectionResult) {
        return this.TYPE_REPORT_KIND_API.inspectionResult;
      }

      return this.TYPE_REPORT_KIND_API.requestResult;
    },

    async getUrlView(item) {
      let data = null;
      let url = item;
      let response = null;
      if (url) {
        const params = getParamsOfUrlReadFile(url);
        response = await Store.dispatch("File/readFile", params);
        if (!response.hasError) {
          data = response.data;
        }
      } else {
        let arrayBase64 = item?.file?.split(",");
        let base64result = arrayBase64[1];
        const byteCharacters = atob(base64result);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        data = byteArray;
      }
      this.urlFilePdf = window.URL.createObjectURL(
        new Blob([data], {
          type: response?.headers["content-type"] || null,
        })
      );
    },
    async downloadDocument(item) {
      this.isSubmitted = true;
      this.isLoading = true;
      let _item = _.cloneDeep(item);
      let response = null;
      let data = null;
      if (_item?.file_url) {
        let paramsPath = "&folder=files&path=";
        let replace = "storage/files/"
        _item.file_url = _item?.file_url.replace(replace, "api/file/read_file?name=") + paramsPath;
        const params = getParamsOfUrlReadFile(_item.file_url);
        response = await Store.dispatch("File/readFile", params);
        data = response.data;
      } else {
        let arrayBase64 = item?.file?.split(",");
        let base64result = arrayBase64[1];
        const byteCharacters = atob(base64result);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        data = byteArray;
      }
      const url = window.URL.createObjectURL(
        new Blob([data], { type: response?.headers["content-type"] || null })
      );
      this.isSubmitted = false;
      let link = document.createElement("a");
      link.download = _item.file_name;
      link.target = "_blank";
      link.href = url;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      this.isLoading = false;
    },
    close() {
      this.showModal = false;
      this.$emit('close-formDetail-modal', false);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/table.scss";
.pdfNavigation {
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 8px;
}
.messager-no-data {
  color: rgba(0,0,0,.38);
  text-align: center; ;
  padding-top: 60px;
  width: 75%;
}
.repair-quote {
  width: 100%;
  height: 100%;
  display: flex;
  &-content {
    padding-left: 60px;
    &-file {
      width: max-content;
      padding-bottom: 8px;
      .item-child-file {
        padding-right: 15px;
        font-weight: 400;
        line-height: 24px;
      }
      .item-child-file {
        display: inline-block;
        height: 20px;
        line-height: 20px;
        margin-bottom: -6px;
      }
      .name-file-truncate {
        @media (max-width:1366px) {
          width: 200px !important;
        }
        @media (max-width:1025px) {
          width: 150px !important;
        }
      }
    }
  }
}
.repair-quote-content-reviews {
  ::v-deep .link-preview-section {
    max-width: 975px !important;
    min-height: 500px !important;
  }
}

.tab-select-header {
  color: #000000;
}

.inspection-result-item {
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.15px;
  p {
    font-weight: 400;
    color: rgba(0,0,0,.87);
    margin-bottom: 2px;
    line-height: 1.5em!important;
    padding: 15px 0px 26px 0px;
    padding-top: 0px;
  }
}
.table-container-scroll {
  overflow: auto;
}
.table-container-inspection {
  padding: 27px 20px;
  width: 100%;
  overflow: auto;
  margin: 0 auto;
  .header-inspection-result {
    width: 100%;
    display: flex;
    border-bottom: 1px solid #f0f2f3;
    padding: 10px;
    div {
      font-weight: 500;
      line-height: 24px;
      color: rgba(0,0,0,.6);
    }
  }
  .inspection-result {
    width: 100%;
    display: flex;
    &-list {
      width: 30%;
      padding: 10px;
    }
    .inspection-items {
      width: 70%;
      div {
        border-bottom: 1px solid #f0f2f3;
        padding: 15px;
      }
    }
  }
}
* {
  font-family: $normal_font;
  font-style: normal;
}
.tenken-form-list-container {
  height: max-content;
  height: 80vh;
  display: flex;
  flex-direction: column;
  .bg-tab {
    background: #fff;
  }
  >.header {
    display: flex;
    padding: 8px 4px 22px 8px;
    justify-content: space-between;

    >.header-title {
      font-size: 20px !important;
      font-weight: 600;
    }

    >.header-button-container {
      display: flex;
      align-items: center;

      > :not(:last-child) {
        margin-right: 25px;
      }

      >.header-action-button-container {
        > :not(:last-child) {
          margin-right: 20px;
        }
      }
    }
  }

  >.tab-container {
    border-bottom: 1px solid $color_border_accent;
    display: flex;

    >.legends-container {
      display: flex;
      align-items: center;
      width: 100%;
      justify-content: flex-end;
      padding-right: 112px;

      > :not(:last-child) {
        margin-right: 15px;
      }

      >.legend {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.25px;

        > :not(:last-child) {
          margin-right: 10px;
        }
      }
    }
  }

  .action-container {
    display: flex;
    width: auto;
    justify-content: flex-end;
    position: absolute;
    top: 80px;
    right: 100px;
    z-index: 1;

    @media (max-width:1025px) {
      right: 30px;
    }

    .action {
      padding: 0px 20px;

      &-before {
        padding-right: 8px;
      }
    }
  }

  .list-image {
    width: 90%;
    margin: 0 auto;
    display: flex;

    .container-comment {
      span {
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0.15px;
        color: #000000;
      }
    }

    .messager-content {
      overflow-y: auto;
      border: 1px solid #e5e5e5;
      padding: 15px;
      height: 88%;
      min-height: 400px;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0.15px;
      color: rgba(0, 0, 0, 0.6);
      word-wrap: break-word;
      white-space: pre-line;
    }
  }

  >.table-container {
    padding: 22px 75px 36px;
  }
}
.dialog {
  padding-bottom: 20px;

  .body {
    .text-wrap {
      padding: 16px 0;
      position: relative;

      .text {
        padding-left: 10px;
        color: #000000;
      }
    }
  }

  .btn {
    margin-right: 12px;
  }

  .title {
    font-size: 20px !important;
    font-weight: 600;
    padding-left: 8px;
    color: rgba(39, 79, 194, 1);
  }
}


</style>
<style lang="scss">
.receive-detail-tenken-css-tab-2, .receive-detail-tenken-css-tab-3 {
  background: white !important;
  .tenken-form-list-container {
    box-shadow: none !important;
  }
  .repair-quote-content {
    padding-bottom: 20px;
  }
}
.custom-color-tooltip {
  opacity: 1!important;
}
.fd-gallery-content-show-centent img{
  max-height:380px !important;
  padding-top:20px !important;
}
</style>
