import Api from "../api";

const SITES_BASE_URL = `${process.env.VUE_APP_API_BASE_URL}`;
const GET_FIELD_GROUP_BY_ID = `${SITES_BASE_URL}/field_user/get_list_field_group_by_field_id`;
const GET_BOOKING_INFO_ID = `${SITES_BASE_URL}/fields/get_booking_info_by_id`;
const GET_INFO_ID = `${SITES_BASE_URL}/fields/get_field_info_by_id`;
const GET_LIST_RESERVER = `${SITES_BASE_URL}/field_user/get_list_reserver_in_field_group`;
const POST_ADD_BOOKING = `${SITES_BASE_URL}/fields/add_booking_info`;
const POST_UPDATE_BOOKING = `${SITES_BASE_URL}/fields/update_booking_info`;
const POST_DELETE_BOOKING = `${SITES_BASE_URL}/fields/delete_booking_info`;
const URL_INSPECTION_DETAIL = `${SITES_BASE_URL}/fields/get_inspection_detail_by_inspection_id`;
const URL_INSPECTION_IMAGE = `${SITES_BASE_URL}/fields/get_inspection_image_by_inspection_id`;
const URL_MACHINE_INFO = `${SITES_BASE_URL}/fields/get_field_machine_info_by_id`;
const URL_REPAIR_ATTACH = `${SITES_BASE_URL}/fields/get_list_repair_attach_file`;
const POST_DELETE_URL_FILE = `${SITES_BASE_URL}/repair_requests/delete_repair_attach_files_by_id`;
const UPLOAD_FILE_PDF = `${SITES_BASE_URL}/fields/upload_file`;
const URL_REPAIR_REQUEST_DETAIL = `${SITES_BASE_URL}/fields/get_repair_request_image_by_request_id`;
const URL_DELETE_PERIOD_BOOKING_INFO = `${SITES_BASE_URL}/fields/delete_period_booking_info`;
export const booking = {
  getInfo: (params) => {
      return Api.get(GET_INFO_ID, {params});
  },
  getFieldGroupId: (params) => {
    return Api.get(GET_FIELD_GROUP_BY_ID, {params});
  },
  getBookingInfoId: (params) => {
      return Api.get(GET_BOOKING_INFO_ID, {params});
  },
  getListReserver: (params) => {
    return Api.get(GET_LIST_RESERVER, {params});
  },
  postAddBooking: (newBooking) => {
      return Api.post(POST_ADD_BOOKING, newBooking);
  },
  postUpdateBooking: (params) => {
      return Api.post(POST_UPDATE_BOOKING, {...params});
  },
  postDeleteBooking: (params) => {
      return Api.post(POST_DELETE_BOOKING, {...params});
  },
  getInspectionDetail: (params) => {
    return Api.get(URL_INSPECTION_DETAIL, { params });
  },
  getInspectionImage: (params) => {
    return Api.get(URL_INSPECTION_IMAGE, { params });
  },
  getMachineInfo: (params) => {
    return Api.get(URL_MACHINE_INFO, { params });
  },
  getRepairAttach: (params) => {
    return Api.get(URL_REPAIR_ATTACH, { params });
  },
  postDeleteUrlFile: (params) => {
    return Api.post(POST_DELETE_URL_FILE, {...params});
  },
  uploadFilePdf: (params) => {
    return Api.post(UPLOAD_FILE_PDF, params, {headers: {'Content-Type': 'multipart/form-data'}});
  },
  getRepairRequestDetail: (params) => {
    return Api.get(URL_REPAIR_REQUEST_DETAIL, { params });
  },
  deletePeriodBookingInfo: (params) => {
    return Api.post(URL_DELETE_PERIOD_BOOKING_INFO, params);
  },
};
