<template>
  <FormDialog>
    <template #header>
      <v-app-bar flat height="48px" color="rgba(0, 0, 0, 0)">
        <v-toolbar-title color="primary">
          {{ FORM_TITLE }}
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn
          v-if="!editable && roleUser == ROLE_MAIN"
          depressed
          small
          :disabled="!valid"
          color="primary"
          @click="onEditable"
        >
          編集
        </v-btn>
        <v-btn
          v-if="editable"
          depressed
          small
          outlined
          color="primary"
          class="mr-5"
          @click="isShowConfirmDialog = true"
        >
          やめる
        </v-btn>
        <v-btn
          v-if="editable"
          depressed
          small
          :disabled="!valid"
          color="primary"
          :key="flagBtn"
          @click.once="onSubmit"
        >
          保存
        </v-btn>
        <v-btn icon @click="onClickBtnClose">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <template v-slot:extension>
          <v-tabs v-model="tab" align-with-title>
            <v-tabs-slider color="primary"></v-tabs-slider>
            <v-tab v-for="(form, key) in DOCUMENT_INTO_TAB" :key="key">
              {{ form.title }}
            </v-tab>
          </v-tabs>
        </template>
      </v-app-bar>
    </template>
    <template #main="{ params }">
      <ValidationObserver ref="observer" v-slot="observer">
        <v-tabs-items v-model="tab">
          <v-tab-item>
            <DocumentFormPage1
              :editable="editable"
              :arrayImages="editedItem"
              :mainHeight="params.mainHeight"
              @updateItems="updateItems"
            />
          </v-tab-item>
        </v-tabs-items>
        <ValidationCallback :observer="observer" @callback="updateValidate" />
      </ValidationObserver>
      <Popup width="480px" :dialog="isShowConfirmDialog">
        <ConfirmCloseDialog
          title="フォームを閉じる確認"
          text1="フォームを閉じますがよろしいですか？
入力内容は保存されません。"
          text2="このページから移動してもよろしいですか？"
          @close="closeConfirmDialog"
          @yes="closeForm"
          warning
        />
      </Popup>
    </template>
  </FormDialog>
</template>

<script>
import { Store } from "@/store/Store.js";
import { ValidationObserver } from "vee-validate";
import ValidationCallback from "../elements/ValidationCallback.vue";
import Popup from "@/components/common/Popup.vue";
import FormDialog from "@/components/dialog/FormDialog.vue";
import ConfirmCloseDialog from "@/components/dialog/ConfirmCloseDialog.vue";
import DocumentFormPage1 from "@/components/forms/document/DocumentFormPage1.vue";
import { DOCUMENT_INTO_TAB } from "@/constants/DOCUMENT";
import _ from "lodash";

const FORM_TITLE = "書類";

const STORE = "Sites";

const ROLE_MAIN = 3;

export default {
  data: () => {
    return {
      DOCUMENT_INTO_TAB,
      valid: false,
      tab: null,
      editable: false,
      isShowConfirmDialog: false,
      editedItem: [],
      beforeUpdateItem : [],
      flagBtn : false,
      roleUser : null,
      ROLE_MAIN,
      isClickCloseBtn: false,
    };
  },
  components: {
    ValidationObserver,
    ValidationCallback,
    Popup,
    FormDialog,
    ConfirmCloseDialog,
    DocumentFormPage1,
  },
  mounted() {
    this.getItems();
    this.$watch(
      () => Store.getters[`${STORE}/getDocument`],
      (data) => {
        if (Array.isArray(data.company_documents)) {
          this.initDataArrayItems(data);
        }
      },
      {
        immediate: true,
        deep: true,
      }
    );

    this.$watch(
      () => Store.getters["Sites/getRole"],
      (data) => {
        this.roleUser = data;
      },
      {
        immediate: true,
        deep: true,
      }
    );
  },
  computed: {
    FORM_TITLE() {
      return FORM_TITLE;
    },
    CURRENT_SITE() {
      return Store.getters["GlobalHeader/getCurrentSite"];
    },
  },
  methods: {
    initDataArrayItems(data){
      let _editedItems = [];
      data.company_documents.forEach((e) => {
        _editedItems.push({ ...e, status: false });
      });
      data.field_documents.forEach((e) => {
        _editedItems.push({ ...e, status: true });
      });
      _editedItems = _editedItems.map((e, index) => {
        return { ...e, key: index + 1 };
      });
      this.editedItem = [..._editedItems];
      this.beforeUpdateItem = _.cloneDeep(_editedItems);
    },
    async getItems() {
      await Store.dispatch(`${STORE}/getDocument`, this.CURRENT_SITE.field_id);
    },
    updateItems(items) {
      this.editedItem = [...items];
    },
    updateValidate({ valid }) {
      this.valid = valid;
    },
    onEditable() {
      this.editable = true;
    },
    async onSubmit() {
      let field_documents = this.editedItem.filter(e => e.status == true && !(e.document_url && e.document_deleted !== 1));
      field_documents.forEach(e => {
        delete e.key;
        delete e.status;
      });
      let params = {
        field_id: this.CURRENT_SITE.field_id,
        field_documents
      };
      const response = await Store.dispatch(`${STORE}/updateFieldDocument`,  params );
      if (response.hasError) {
        this.flagBtn = !this.flagBtn;
      } else {
        await this.getItems();
        Store.dispatch("Toast/show", {
          status: 200,
          message: "更新しました",
        });
        this.editable = false;
      }
    },
    closeConfirmDialog() {
      this.isShowConfirmDialog = false;
    },
    onClickBtnClose() {
      if (!this.editable) {
        this.$emit("cancel");
      } else {
        this.isClickCloseBtn = true;
        this.isShowConfirmDialog = true;
      }
    },
    closeForm() {
      if (this.editable && !this.isClickCloseBtn) {
        this.isShowConfirmDialog = false;
        const item = _.cloneDeep(this.beforeUpdateItem);
        this.updateItems(item);
        this.editable = false;
      } else {
        this.$emit("cancel");
      }
    }
  },
};
</script>

<style lang="sass" scoped>
.from-close-btn
  float: right
</style>
