<template>
  <FormDialog>
    <template #header>
      <Loading v-if="isLoading"/>
      <v-app-bar flat height="50px" color="rgba(0, 0, 0, 0)">
        <v-toolbar-title color="primary" class="title-popup">
          利用者情報
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn
          v-if="!editable && dataRegister.id !== fieldGroupUserId"
          depressed
          small
          color="primary"
          @click="onEditable"
        >
          編集
        </v-btn>
        <v-btn
          v-if="editable"
          class="mx-2"
          depressed
          small
          outlined
          color="primary"
          @click="onStopEditCF"
        >
          やめる
        </v-btn>
        <v-btn
          v-if="editable"
          class="mx-2"
          depressed
          small
          :disabled="!valid || isSubmitted"
          color="primary"
          @click="hundleSubmit"
        >
          保存
        </v-btn>
        <v-btn icon @click="onClickBtnClose">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <template v-slot:extension>
          <v-tabs v-model="tab" align-with-title>
            <v-tabs-slider color="primary"></v-tabs-slider>
            <v-tab v-for="(form, key) in FORMS" :key="key">
              {{ form.title }}
            </v-tab>
          </v-tabs>
        </template>
      </v-app-bar>
    </template>
    <template #main="{ params }">
      <ValidationObserver ref="observer" v-slot="observer">
        <BasicFormPage
          v-if="tab === 0"
          :editable="editable"
          :companyEditable="companyEditable"
          :detailData="dataRegister"
          :dataSelectCompany="dataSelectCompany"
          :fieldId="fieldId"
          @updateCompanyEditable="updateCompanyEditable"
          @openListCompany="openListCompany"
          @searchListCompany="searchListCompany"
          @getFieldUserInfoByEmail="getFieldUserInfoByEmail"
          :mainHeight="params.mainHeight"
          @formUpdate="formUpdate"
        />
        <ValidationCallback :observer="observer" @callback="updateValidate"/>
      </ValidationObserver>
      <Popup width="480px" :dialog="isShowConfirmDialog">
        <ConfirmCloseDialog
          title="フォームを閉じる確認"
          text1="フォームを閉じますがよろしいですか？入力内容は保存されません。"
          text2="このページから移動してもよろしいですか？"
          @close="onClickBtnCancelForm"
          @yes="onStopEdit"
          warning
        />
      </Popup>
    </template>
  </FormDialog>
</template>

<script>
import {ValidationObserver} from "vee-validate";
import ValidationCallback from "@/components/forms/elements/ValidationCallback.vue";
import Popup from "@/components/common/Popup.vue";
import FormDialog from "@/components/dialog/FormDialog.vue";
import ConfirmCloseDialog from "@/components/dialog/ConfirmCloseDialog.vue";
import ListCompany from "@/components/forms/company/popup/ListCompany";
import {Store} from "@/store/Store.js";
import FormFieldGroup from "./FormFieldGroup";
import BasicFormPage from "./BasicFormPage";
import FieldGroupUserFormPage from "./FieldGroupUserFormPage";
import ShowQR from "@/components/qr/ShowQR";
import ConfirmInviteUserForm from "@/components/forms/fieldUsers/Popup/ConfirmInviteUserForm";
import {USER_INIT} from "@/constants/FIELD_USERS";
import Loading from "@/components/loading/Loading";
import {COMPANY_SCENE} from "@/constants/COMMON";

const FIELD_GROUP_STORE = "FieldGroups";
const FIELD_GROUP_USER_STORE = "FieldGroupUsers";

const FORMS = {
  BasicInfo: {
    id: 1,
    title: "基本情報"
  },
};

export default {
  data: () => {
    return {
      FORMS,
      valid: false,
      tab: null,
      editable: false,
      companyEditable: true,
      isShowConfirmDialog: false,
      isClickCloseBtn: false,
      errors: {},
      isSubmitted: false,
      idSelect: null,
      isShowItemForm: false,
      dataSelectCompany: null,
      dataRegister: {
        "fieldGroups" : {},
        "fieldGroupUsers" : [],
      },
      fieldId: JSON.parse(sessionStorage.getItem("CURRENT_SITE")).field_id,
      fieldGroupUserId: JSON.parse(sessionStorage.getItem("CURRENT_SITE")).field_group_user_id,
      keySearch: null,
      dataListCompany: {},
      isShowConfirmInvite: false,
      USER_INIT,
      isLoading: false,
      COMPANY_SCENE
    };
  },
  components: {
    ConfirmCloseDialog,
    ValidationObserver,
    ValidationCallback,
    BasicFormPage,
    FieldGroupUserFormPage,
    Popup,
    FormFieldGroup,
    FormDialog,
    ListCompany,
    ShowQR,
    ConfirmInviteUserForm,
    Loading
  },
  props: {
    isNewItem: Boolean,
    actions: String,
    item: Object,
    fieldId: Number
  },
  mounted() {
    this.$watch(
      () => this.isNewItem,
      (flag) => {
        if (flag) this.editable = true;
      },
      {
        immediate: true,
        deep: true,
      }
    );
    this.$watch(
      () => this.fieldId,
      (fieldId) => {
        this.fieldId = fieldId,
        this.fieldId = fieldId
      },
      {immediate: true, deep: true}
    );
    this.$watch(
      () => Store.getters[`${FIELD_GROUP_STORE}/getIsLoading`],
      (data) => {
        if (data == true) {
          this.isLoading = data;
        } else {
          this.isLoading = false;
        }
      },
      {
        immediate: true,
        deep: true,
      }
    );
    this.$watch(
        () => Store.getters[`Register/getIsLoading`],
        (data) => {
          if (data == true) {
            this.isLoading = data;
          } else {
            this.isLoading = false;
          }
        },
        {
          immediate: true,
          deep: true,
        }
    );
    this.$watch(
      () => this.item,
      (data) => {
        this.dataRegister = { ...data };
      },
      {
        immediate: true,
        deep: true,
      }
    );
  },

  methods: {
    formUpdate(params) {
      this.dataRegister = params;
    },

    onStopEdit() {
      if (this.actions == 'create' || this.isClickCloseBtn) {
        this.$emit("closeFieldGroupUserForm")
      } else {
        this.dataRegister = { ...this.item };
        this.editable = false;
        this.isShowConfirmDialog = false;
      }
    },
    onStopEditCF() {
      this.isShowConfirmDialog = true;
    },
    onEditable() {
      this.editable = true;
    },
    async hundleSubmit() {
      this.dataRegister.usage_status = this.dataRegister.usage_status ? 1 : 0;
      const res = await Store.dispatch(`${FIELD_GROUP_USER_STORE}/updateFieldGroupUserInfo`, this.dataRegister);
      if (!res.hasError && res.data.status_code === 200) {
        Store.dispatch("Toast/show", {
            status: "200",
            message: "更新しました。"
          },
          {
            root: true
          }
        );
      }
      this.closeForm();
      this.$emit("getItems");
    },

    closeFieldGroupUserForm() {
      this.$emit("closeFieldGroupUserForm")
    },

    async saveData() {
      this.isShowConfirmInvite = false;
      let params = {...this.dataRegister};
      params["field_id"] = this.fieldId;
      const res = await Store.dispatch(`FieldUsers/addFieldUserInfo`, params);
      if (!res.hasError && res.data.status_code === 200) {
        this.dataRegister.field_users = res.contents.entries.field_users;
        this.editable = false;
        this.companyEditable = false;
        this.callGetItems();
        await Store.dispatch("Toast/show", {
            status: "200",
            message: res.data.message
          },
          {
            root: true
          }
        );
        this.closeFieldGroupUserForm();
      }
    },

    updateValidate(params) {
      const {valid, errors} = params;
      this.valid = valid;
      this.errors = errors;
    },

    closeForm() {
      this.isShowConfirmDialog = false;
      this.closeFieldGroupUserForm();
    },

    onClickBtnClose() {
      if (!this.editable) {
        this.closeFieldGroupUserForm();
      } else {
        this.isClickCloseBtn = true;
        this.isShowConfirmDialog = true;
      }
    },

    handCheckCompany(data) {
      if (data) {
        this.dataSelectCompany = data;
        this.closeItemForm();
      }
    },

    onClickBtnCancelForm() {
      this.isShowConfirmDialog = false;
      this.isClickCloseBtn = false;
    },

    onClickBtnCancelConfirm() {
      this.isShowConfirmInvite = false;
    },

    openListCompany(name) {
      this.keySearch = name.keyword
    },

    closeItemForm() {
      this.isShowItemForm = false;
    },

    updateCompanyEditable(value) {
      this.companyEditable = value;
    },

    callGetItems() {
      this.$emit("getItems");
    },

    async searchListCompany() {
      const res = await Store.dispatch('Register/getCompany', {
        name: this.keySearch
      });
      if (!res.hasError && res?.data?.count > 0) {
        this.dataListCompany = res.data.corporation
        this.isShowItemForm = true;
      } else if (res.hasError) {
        Store.dispatch("Error/show", {
            status: "422",
            message: "エラー: " + res.response.data.message
          },
          {
            root: true
          }
        );
      } else {
        Store.dispatch("Error/show", {
            status: "422",
            message: "エラー: データがありません。"
          },
          {
            root: true
          }
        );
      }
    },

    validateEmail(value) {
      return value.match(/^\w+([\\.-]?\w+)*@\w+([\\.-]?\w+)*(\.\w{2,6})+$/);
    },

    async getFieldUserInfoByEmail(params) {
      let tmpParams = {...params};
      let isValidate = await this.validateEmail(tmpParams.email);
      if (isValidate !== null) {
        tmpParams.field_id = this.fieldId;
        tmpParams.company_id = this.dataRegister.id ?? null;
        const res = await Store.dispatch("FieldUsers/getFieldUserInfoByEmail", {params: tmpParams});
        if (!res.hasError && res.data.status_code == 200)
        {
          if (res?.data?.contents?.entries?.users) {
            let data = res.data.contents.entries.users;
            await Object.entries(data).forEach(([key, value]) => this.dataRegister["users"][key] = value);
            this.dataRegister = JSON.parse(JSON.stringify(this.dataRegister));
            this.editable = false;
          } else {
            let tmpEmail = this.dataRegister?.users?.email;
            this.dataRegister.users.email = tmpEmail;
            this.editable = true;
          }
        }
        else {
          let tmpEmail = this.dataRegister?.users?.email;
          this.dataRegister.users = {...USER_INIT};
          this.dataRegister.users.email = tmpEmail;
          this.editable = true;
        }
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.title-popup {
  color: #274FC2;
}

.popup-company {
  position: relative;
  height: 70vh;
  padding-bottom: 129px;
  overflow: hidden;
}
</style>
