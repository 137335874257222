<template>
  <div>
    <v-container class="container">
      <div>
        <v-main v-for="(item, index) in MENU_SP_ITEMS" :key="index">
          <v-row no-gutters @click="onClickOpen(item.path)">
            <v-list-item link>
              <v-list-item-content>
                <v-list-item-title>{{ item.name }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-row>
        </v-main>
      </div>
    </v-container>
  </div>
</template>
<script>
import { MENU_SP_ITEMS } from "@/constants/COMMON";
export default {
  data: () => {
    return {
      isShowMenuSpItem: false,
      MENU_SP_ITEMS: MENU_SP_ITEMS,
    };
  },
  methods: {
    onClickOpen(url) {
      this.$emit("onClickOpen", url);
    },
  },
};
</script>
<style lang="scss" scoped>
.container {
  border-top: 1px solid #e5e5e5;
}
</style>
