import Vue from "vue";
import Vuex from "vuex";
import { PORTAL_LIST } from "@/constants/GLOBALHEADER";

Vue.use(Vuex);

export const Drawer = {
  namespaced: true,

  state: {
    type: PORTAL_LIST.INHOUSE_PORTAL.id,
    isActive: false,
  },

  mutations: {
    ACTIVE(state, { type }) {
      state.type = type;
      state.isActive = true;
    },
    DEACTIVE(state) {
      state.isActive = false;
    },
  },

  actions: {
    show({ commit }, { type }) {
      commit("ACTIVE", { type });
    },
    close({ commit }) {
      commit("DEACTIVE");
    },
  },

  getters: {
    getState: (state) => {
      return state;
    },
    getType: (state) => {
      return state.type;
    },
  },
};
