<template>
  <div>
    <div class="sticky">
      <v-row no-gutters>
        <v-col cols="9" sm="10" class="pa-3">
          <!-- 検索ワード -->
          <Label :label="searchLabel">
            <SearchInputText
              name="keyword"
              :values="formValues"
              placeholder="検索"
              validation_label="検索ワード"
              :validation_rules="rule"
              :editable="true"
              :clearable="true"
              prependInnerIcon="mdi-magnify"
              @onInput="onInput"
              @keyEnter="$emit('search')"
            />
          </Label>
        </v-col>
        <v-col cols="3" sm="2" class="pr-2" style="display: flex; align-items: center;">
          <!-- 検索ボタン -->
          <v-btn @click="$emit('search')" color="primary">検索</v-btn>
        </v-col>
      </v-row>
      <v-divider></v-divider>
    </div>

    <!-- リスト -->
    <slot></slot>
  </div>
</template>

<script>
import SearchInputText from "@/components/forms/elements/SearchInputText.vue";
import Label from "@/components/forms/elements/Label.vue";

export default {
  data() {
    return {
      values: {
        // 表示するリスト
        items: [],
        // 選択項目id
        selected: null,
      },
    };
  },

  components: {
    SearchInputText,
    Label
  },

  props: {
    formValues: Object,
    validateRule: String,
    searchLabel: {
      type: String,
      default: ""
    }
  },

  async mounted() {
    // formに表示する値を更新
    this.$watch(
      () => this.formValues,
      (newValue) => {
        if (newValue) this.values = newValue;
      },
      { immediate: true, deep: true }
    );
  },

  computed: {
    rule() {
      return this.validateRule ? "max:255|" + this.validateRule : "max:255";
    }
  },

  methods: {
    // idの更新
    onClick(id) {
      this.values.selected = id;
      this.$emit("formUpdate", this.values);
    },

    onInput({ name, value }) {
      this.values[name] = value;
      this.$emit("formUpdate", this.values);
    },
  },
};
</script>
<style lang="scss" scoped>
.sticky {
  position: sticky;
  top: 65px;
  z-index: 1;
  background-color: white;
}
</style>
