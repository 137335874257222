<template>
  <div class="single" :class="$sp ? 'sp' : ''">
    <v-card class="mx-auto card mt-0 mb-0" flat>
      <slot />
    </v-card>
  </div>
</template>
<script>
export default {};
</script>
<style lang="scss" scoped>
.single {
  display: flex;
  padding: 0;
  margin: 0;
  height: 100vh;
  justify-content: center;
  align-items: center;
  background-color: #f8f8f8;
}
.single.sp {
  background-color: white;
}
.card {
  width: 100%;
  max-width: 375px;
}
h4 {
  width: 100%;
}
</style>
