<template>
  <ValidationProvider
    v-slot="{ errors, valid }"
    :name="validation_label"
    :rules="validation_rules"
  >
    <!-- 検索用 InputTextコンポーネント
      icon, clearable, ワードクリアfunc追加
     -->
    <v-text-field
      v-model="val"
      color="primary"
      :disabled="!editable"
      :filled="editable"
      :error-messages="errors"
      :success="valid"
      :placeholder="placeholder"
      :prepend-inner-icon="prependInnerIcon"
      :clearable="clearable"
      @click:clear="clearSearch"
      @input="$emit('onInput', { name, value: val })"
      @keydown.enter="$emit('keyEnter')"
      background-color="grey lighten-2"
      autofocus
      solo
      flat
      class="pt-1"
    >
    </v-text-field>
  </ValidationProvider>
</template>
<script>
import { ValidationProvider } from "vee-validate";
export default {
  components: {
    ValidationProvider,
  },
  props: {
    editable: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
    },
    values: {
      type: Object,
    },
    validation_rules: {
      type: String,
    },
    validation_label: {
      type: String,
    },
    placeholder: {
      type: String,
      default: "",
    },
    clearable: {
      type: Boolean,
      default: true,
    },
    prependInnerIcon: {
      type: String,
      default: "mdi-magnify",
    },
  },
  data: () => {
    return {
      val: "",
    };
  },
  mounted() {
    this.$watch(
      () => [this.values, this.name],
      (newValue) => {
        const formValues = newValue[0];
        const name = newValue[1];
        if (formValues && name) this.val = formValues[name];
      },
      { immediate: true, deep: true }
    );
  },
  methods: {
    // 検索ワード削除
    clearSearch() {
      this.values.keyword = "";
      this.$emit("onInput", this.values);
      this.$emit("keyEnter");
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .v-input__slot {
  min-height: 24px !important;
}

::v-deep .v-input__prepend-inner {
  margin-top: 0 !important;
}
</style>
