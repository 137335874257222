<template>
    <div>
    <Loading v-if="isLoading" />
    <v-data-table :noDataText="NO_DATA" :headers="headers" :items-per-page="desserts.length"
      :items="desserts" hide-default-footer 
      fixed-header
      disable-sort
      :height="tableHeight"
      item-key="id"
      class="plan-to-pay-tab"
      @update:page="$vuetify.goTo(0)"
      :key=page
      >
      <template v-slot:item.name="{ item }">
          {{ item.company_renta_name }}
      </template>
      <template v-slot:item.scheduled_return_date="{ item }">
        {{ getDateTable(item) }}
      </template>
      <template v-slot:item.return_gate="{ item }">
        {{item.gate_name}}
      </template>
      <template v-slot:item.use_end_date="{ item }">
        {{item.use_end_date}}
      </template>
      <template v-slot:item.number_plate="{ item }">
        {{ item.number_plate }}
      </template>
      <template v-slot:item.floor_height="{ item }">
        {{item.floor_height }}m {{item.lifting_method }} {{item.undercarriage }} {{item.working_floor}}
      </template>
      <template v-slot:item.name_sei="{ item }">
        <p>{{ item.field_group_name }} {{ item.user_name_sei }} {{ item.user_name_mei }}</p>
        <p> {{ item.user_phone }} </p>
      </template>
      <template v-slot:item.comment="{ item }">
        <v-tooltip bottom color="#0082d8" max-width="384px" v-if="item.comment">
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-bind="attrs" v-on="on" color="black">mdi-information-outline</v-icon>
          </template>
          <span style="word-wrap: break-word; white-space: pre-wrap;">{{item.comment}}</span>
        </v-tooltip>
      </template>
    </v-data-table>
    </div>
  </template>
  <script>
  import { NO_DATA } from "@/constants/COMMON";
  import { Store } from "@/store/Store.js";
  import Popup from "@/components/common/Popup.vue";
  import ExtendDialog from "@/components/dialog/ExtendDialog.vue";
  import AddRequestForm from "@/components/forms/booking/AddRequestForm.vue";
  import { HEADER_PLAN_TO_PAY } from "@/constants/MACHINE";
  import Loading from "@/components/loading/Loading";
  export default {
    data() {
      return {
        NO_DATA,
        headers: HEADER_PLAN_TO_PAY,
        desserts: [],
        fieldId: null,
        isLoading: false
      }
    },
  
    components: {
      ExtendDialog,
      Popup,
      AddRequestForm,
      Loading
    },
  
    props:{
      paramsSearch: {
        type: Object,
      },
      tableHeight: {
        type: Number
      },
      page: {
        type: Number
      }
    },
    mounted() {

    this.$watch(
      () => Store.getters["Machines/getIsLoading"],
      (data) => {
        this.isLoading = data;
      },
      {
        immediate: true,
        deep: true,
      }
    );
  },
  
    watch: {
      paramsSearch: {
        handler: function (params) {
          this.getListRequestField(params);
        },
        deep: true
      },
    },
  
    created() {
      this.fieldId = JSON.parse(sessionStorage.getItem("CURRENT_SITE")).field_id;
      let params = {
        "field_id": this.fieldId,
        "page": 1,
        "page_size": 25,
        "sort_value":"name_kana",
        "sort_by":"ASC",
      };
      this.getListRequestField(params);
    },
  
    methods: {
      async getListRequestField(params) {
        const result = await Store.dispatch(`Machines/getListRequestField`, params);
        if (!result.hasError) {
          this.desserts = result.contents.entries;
          this.$emit("pagination-data", result.contents.pagination)
        }
      },
  
      getDateTable(data) {
        let dateTime = null;
        const during_day = 0;
        const morning_day = 1;
        const afternoon_day = 2;
        const timeDesignation = 3;
        if (data.scheduled_return_type === during_day) {
          dateTime = "当日中";
        } else if (data.scheduled_return_type === morning_day) {
          dateTime = "午前";
        } else if (data.scheduled_return_type === afternoon_day) {
          dateTime = "午後";
        } else if (data.scheduled_return_type === timeDesignation) {
          dateTime = data.scheduled_return_time.substring(0,2) + ":00";
        }
        return data.scheduled_return_date + " " + dateTime;
      }
    }
  }
  </script>
  <style lang="scss" scoped>
  .layout-machine-container {
    .tab-select-header {
      height: 56px !important;
    }
    .plan-to-pay-tab  {
      ::v-deep   {
        .v-simple-checkbox--disabled {
          opacity: 0;
        }
        td {
          line-height: 24px;
          p {
            margin-bottom: 3px !important;
            line-height: 15px;
          }
        }
      }
    }
    .tableBody {
      .btn-delete:before {
        background-color: white !important;
      }
    }
  }
  </style>