<template>
  <ValidationObserver v-slot="{ invalid }" ref="observer">
    <div class="list-item-search">
      <SearchFormWrapper>
        <Label label="使用開始日" class="title-input-item custom-screen" style="max-width: 150px">
          <InputDatepicker name="use_start_date_from" :editable="true" :values="searchTabAdd" :flagNull="true"
            validation_label="使用開始日" :validation_rules="getUseStartDate()" @onInput="onChangeSearchParams"/>
        </Label>
        <span class="mt-12 ml-1">
          <v-icon>mdi-tilde</v-icon>
        </span>
        <Label label="使用開始日" class="title-input-item custom-screen" style="max-width: 150px">
          <InputDatepicker name="use_start_date_to" :editable="true" :values="searchTabAdd" :flagNull="true"
            validation_label="使用開始日" :validation_rules="getUseStartEndDate()" @onInput="onChangeSearchParams"/>
        </Label>
        <Label label="要求日" class="title-input-item custom-screen" style="max-width: 150px">
          <InputDatepicker name="request_date_from" :editable="true" :values="searchTabAdd" :flagNull="true"
            validation_label="要求日" :validation_rules="getRuleStartDate()" @onInput="onChangeSearchParams"/>
        </Label>
        <span class="mt-12 ml-1">
          <v-icon>mdi-tilde</v-icon>
        </span>
        <Label label="要求日" class="title-input-item custom-screen" style="max-width: 150px">
          <InputDatepicker name="request_date_to" :editable="true" :values="searchTabAdd" :flagNull="true"
            validation_label="要求日" :validation_rules="getRuleEndDate()" @onInput="onChangeSearchParams"/>
        </Label>
        <!--<LabelSearchMachine label="協力会社" :editable="true" class="title-input-item custom-screen" style="max-width: 100px">
          <Select name="company_id" :values="searchTabAdd" :items="fieldPlaces"
            item_text="name" :editable="true" @onInput="onChangeSearchParams" testid="id" />
        </LabelSearchMachine>-->
        <Label label="ステータス" class="title-input-item">
          <TabSelect name="status" :items="statusTabSelect" :editable="true" :values="searchTabAdd"
            @onInput="onChangeSearchParams" />
        </Label>
        <Label label="昇降" class="title-input-item">
          <TabSelect name="lifting_method" :items="liftingTabSelect" :editable="true" :values="searchTabAdd"
            @onInput="onChangeSearchParams" />
        </Label>
        <Label label="足回り" class="title-input-item">
          <TabSelect name="undercarriage" :items="undercarriageTabSelect" :editable="true" :values="searchTabAdd"
            @onInput="onChangeSearchParams" />
        </Label>
        <div style="position: relative; right: 10px">
          <Label label="作業床" class="title-input-item">
            <TabSelect name="working_floor" :items="workingFloorTabSelect" :editable="true" :values="searchTabAdd"
              @onInput="onChangeSearchParams" />
          </Label>
        </div>
        <v-spacer></v-spacer>
        <Label>
          <v-btn class="mr-6" color="primary" depressed @click="searchMachine" :disabled="invalid">
            検索
          </v-btn>
        </Label>
      </SearchFormWrapper>
    </div>
  </ValidationObserver>
</template>
<script>
import { format, parseISO } from "date-fns";
import { FOMAT_DATE } from "@/constants/BOOKING";
import TabSelect from "@/components/forms/elements/TabSelect";
import SearchFormWrapper from "@/components/masterTable/elements/SearchFormWrapper";
import { Store } from "@/store/Store.js";
import {
  STATUS_TAB_SELECT,
  LIFTING_TAB_SELECT,
  UNDERCARRIAGE_TAB_SELECT,
  WORKING_FLOOR_TAB_SELECT
} from "@/constants/MACHINE";
import Label from "@/components/forms/elements/Label"; //絞り込みフォームで使用
import InputDatepicker from "@/components/forms/elements/InputDatepicker.vue";
import LabelSearchMachine from "@/components/forms/elements/LabelSearchMachine.vue";
import Select from "@/components/forms/elements/Select.vue";
import { ValidationObserver } from "vee-validate";

export default {
  data() {
    return {
      fieldId: null,
      isDateStart: false,
      isDateEnd: false,
      dateDefailt: new Date(),
      formatDate: FOMAT_DATE,
      searchTabAdd: {
        "field_id": null,
        "request_date_from": null,
        "request_date_to": null,
        "company_id": null,
        "status": [0, 5],
        "lifting_method": [],
        "undercarriage": [],
        "working_floor": [],
        "sort_value": "use_start_date",
        "sort_by": "ASC",
        "page": 1,
        "page_size": 25
      },
      fieldPlaces: [],
      statusTabSelect: STATUS_TAB_SELECT,
      liftingTabSelect: LIFTING_TAB_SELECT,
      undercarriageTabSelect: UNDERCARRIAGE_TAB_SELECT,
      workingFloorTabSelect: WORKING_FLOOR_TAB_SELECT,
    }
  },
  components: {
    TabSelect,
    SearchFormWrapper,
    Label,
    InputDatepicker,
    LabelSearchMachine,
    ValidationObserver,
    Select
  },
  computed: {
    computedDateStartFormatted() {
      return this.searchTabAdd.request_date_from ? format(parseISO(this.searchTabAdd.request_date_from), "yyyy/MM/dd") : "";
    },
    computedDateEndFormatted() {
      return this.searchTabAdd.request_date_to ? format(parseISO(this.searchTabAdd.request_date_to), "yyyy/MM/dd") : "";
    },
  },
  created() {
    this.fieldId = JSON.parse(sessionStorage.getItem("CURRENT_SITE")).field_id;
    this.searchTabAdd.field_id = this.fieldId;
    this.getListFieldCompany();
  },

  methods: {
    async onChangeSearchParams({ name, value }) {
      let searchTabAdd = { ...this.searchTabAdd };
      searchTabAdd[name] = value;
      this.searchTabAdd = searchTabAdd;
      this.$emit("has-error");
    },

    async getListFieldCompany() {
      const params = {
        "field_id": this.fieldId,
        "role": 2
      };
      const result = await Store.dispatch(`Machines/getListFieldCompanyRole`, params);
      if (!result.hasError) {
        this.fieldPlaces = [{ id: null, name: "" }, ...result.contents.entries];
      }
    },

    async searchMachine() {
      let requestData = { ...this.searchTabAdd };
      this.addStatus(requestData.status);
      this.$emit("search-tab-add", requestData);
    },

    clearDateStart() {
      this.isDateStart = false;
      this.searchTabAdd.request_date_from = "";
    },

    clearDateEnd() {
      this.isDateEnd = false;
      this.searchTabAdd.request_date_to = "";
    },

    onInput({ name, value }) {
      const searchTabAdd = { ...this.searchTabAdd };
      searchTabAdd[name] = value;
      this.searchTabAdd = searchTabAdd;
    },
    getRuleStartDate() {
      let rule = "";
      if (this.searchTabAdd?.request_date_to) {
        const dateTo = this.searchTabAdd?.request_date_to;
        rule = `err-start-date:${dateTo},要求日（左）, 要求日（右）`;
      }
      return rule;
    },
    getUseStartDate() {
      let rule = "";
      if (this.searchTabAdd?.use_start_date_to) {
        const dateTo = this.searchTabAdd?.use_start_date_to;
        rule = `err-start-date:${dateTo},使用開始日（左）, 使用開始日（右）`;
      }
      return rule;
    },
    getRuleEndDate() {
      let rule = "";
      if (this.searchTabAdd?.request_date_from) {
        const dateFrom = this.searchTabAdd?.request_date_from;
        rule = `err-end-date:${dateFrom},要求日（右）,要求日（左）`;
      }
      return rule;
    },
    getUseStartEndDate() {
      let rule = "";
      if (this.searchTabAdd?.use_start_date_from) {
        const dateFrom = this.searchTabAdd?.use_start_date_from;
        rule = `err-end-date:${dateFrom},使用開始日（右）,使用開始日（左）`;
      }
      return rule;
    },
    addStatus(requestData) {
      if (requestData.includes(0) && !requestData.includes(5)) {
        requestData.push(5);
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.layout-machine-container {
  .list-item-search {
    ::v-deep .SearchFormWrapper {
      flex-wrap: wrap;
      position: relative;

      .header-filer {
        .v-label {
          color: rgba(0, 0, 0, 0.38) !important;
          font-weight: 400;
          font-size: 12px;
        }
      }

      .v-text-field__slot {
        .v-label {
          color: rgba(0,0,0,.87) !important;
          font-weight: 400 !important;
          font-size: 12px !important;
        }
      }

      .tab-select-header {
        .title-tab-select {
          font-weight: 400;
          font-size: 12px;
          line-height: 16px;
          letter-spacing: 0.4px;
          color: #000000;
        }
      }
    }

    .input-date {
      border-radius: 2px;
      width: 166px;
      height: 52px;
      font-size: 12px !important;
      line-height: 16px;
      color: rgba(0, 0, 0, 0.87);
      flex: none;
    }

    .color-label {
      color: rgba(176, 0, 32, 1);
    }

    .headline {
      width: 50px;
      line-height: 54px !important;
      align-items: center;
      text-align: center;
      color: #000000;
    }

    .tab-select-header {
      height: 56px !important;
    }

    .tableSortWrapper {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding: 0 8px;
      height: 32px;

      .cst-row {
        border-bottom: 1px solid;
        padding-top: 12px;
        border-color: #e0e0e0;
      }

      .cst-col {
        padding-top: 0px;
        padding-bottom: 0px;
      }
    }
    .custom-screen {
      max-width: 220px;

      @media screen and (max-width: 1400px) {
        max-width: 150px;
      }
      @media screen and (max-width: 1024px) {
        max-width: 220px;
      }
    }
  }

}
</style>