<template>
  <div>
    <DefaultLayout>
      <template #mainHeader>
        <SitePortalHeader :flagUserRentalCompany="flagUserRentalCompany" :flagSupervisor="flagSupervisor" />
        <div @click="isShowCancel = true" id="tooltipDelete" ref="tooltipDelete" class="tooltipDelete" :style="{'display' : isDisplay, 'top': top, 'left': left}">
          <v-icon style="color: black; align-items: center">mdi-delete-outline</v-icon>
          <span style="font-size: 16px; font-weight: 400; color: black; text-align: center">予約キャンセル</span>
        </div>
      </template>
      <template #page="{ layoutParams }">
        <Loading v-if="isLoading" />
        <TableLayout class="custom-color" :layoutParams="layoutParams"
          :hideFooter="searchParams.pageCount >= searchParams.total_item">
          <template #tableHeader="{ updateHeader }">
            <BookingHeader ref="tableHeader" :pageTitle="PAGE_TITLE" :updateHeader="updateHeader"
              :searchParams="searchParams" :isAddNew="false" :isDelete="false" @onInput="onChangeSortParams"
              :typePicker="typeDatePicker">
              <SearchFormWrapper>
                <!-- select company booking -->
                <div style="width:200px"  class="mr-1">
                  <LabelSearchMachine label="予約班" :editable="true" class="title-input-item">
                    <Select :items="PARTNERS_ARRAY" :editable="true" name="booking_field_group_id" :values="searchParams"
                      :single_line="false" @onInput="onChangeSearchParams">
                    </Select>
                  </LabelSearchMachine>
                </div>

                <!-- select field places -->
                <div style="width:200px"  class="mr-1">
                  <LabelSearchMachine label="留置場所" :editable="true" class="title-input-item">
                    <Select :items="FIELD_PLACES_ARRAY" :editable="true" name="field_place_id" :values="searchParams"
                      :single_line="false" @onInput="onChangeSearchParams">
                    </Select>
                  </LabelSearchMachine>
                </div>

                <!-- Input field 現場認識番号 -->
                <div style="width:200px"  class="mr-1">
                  <LabelSearchMachine label="現場認識番号" :editable="true" class="title-input-item resize">
                    <InputText validation_label="現場認識番号" validation_rules="max:20" :editable="true" name="field_identification_number" :values="searchParams"
                      :single_line="false" @onInput="onChangeSearchParams">
                    </InputText>
                  </LabelSearchMachine>
                </div>

                <!-- booking status -->
                <div class="tab-select-header mt-4">
                  <div class="px-2 mb-3">予約状況</div>
                  <TabSelect name="is_booking" :items="BOOKING_STATUS" :editable="true" :values="searchParams"
                    @onInput="onChangeSearchParams" />
                </div>
                <!-- kinds status -->
                <div class="tab-select-header mt-4">
                  <div class="px-2 mb-3">種別</div>
                  <TabSelect name="is_shared" :items="KINDS_STATUS" :editable="true" :values="searchParams"
                    @onInput="onChangeSearchParams" />
                </div>
                <!-- lifting method -->
                <div class="tab-select-header mt-4">
                  <div class="px-2 mb-3">昇降</div>
                  <TabSelect name="lifting_method" :items="LIFTING_METHOD" :editable="true" :values="searchParams"
                    @onInput="onChangeSearchParams" />
                </div>
                <!-- undercarriage -->
                <div class="tab-select-header mt-4">
                  <div class="px-2 mb-3">足回り</div>
                  <TabSelect name="undercarriage" :items="UNDERCARRIAGE" :editable="true" :values="searchParams"
                    @onInput="onChangeSearchParams" />
                </div>
                <!-- working floor -->
                <div class="tab-select-header mt-4">
                  <div class="px-2 mb-3">作業床</div>
                  <TabSelect name="working_floor" :items="WORKING_FLOOR" :editable="true" :values="searchParams"
                    @onInput="onChangeSearchParams" />
                </div>
                <v-spacer></v-spacer>
                <v-btn class="mr-6 mt-4" color="primary" depressed @click="onSearch">
                  検索
                </v-btn>
              </SearchFormWrapper>
            </BookingHeader>

            <!-- 
                (共通)
                ソートのレイアウトを調整するラッパーコンポーネントです
            -->
            <div class="tableSortWrapper">
              <v-spacer></v-spacer>
              <div class="sortLabel">表示件数:</div>
              <div class="sortElement">
                <v-select v-model="searchParams.pageCount" dense :items="PAGE_COUNT_OPTIONS"
                  @change="pageCountUpdate"></v-select>
              </div>

              <div class="sortLabel">総件数:</div>
              <div class="sortElement total_item">{{ searchParams.total_item || 0 }} 件</div>
            </div>
            <v-row class="cst-row">
              <v-col cols="12"
                :class="userRole === 1 || userRole === 2 ? 'cst-col col-sm-4 col-md-4 col-lg-2' : 'cst-col col-sm-2 col-md-2 col-lg-1'">
                <!-- タブ -->
                <v-tabs v-model="selectedTab">
                  <v-tab>
                    {{ BOOKING_TAB_SELECT.BOOKING_TAB.name }}
                  </v-tab>
                  <v-tab v-if="userRole === 1 || userRole === 2">
                    {{ BOOKING_TAB_SELECT.ACHIEVEMENT_TAB.name }}
                  </v-tab>
                </v-tabs>
              </v-col>
            </v-row>
          </template>
          <!--
            (共通)テーブル
            v-data-table自体は共通ですが、
            カラムによって変更をしたい場合はそれぞれ実装してください。
            -->
          <template #tableBody="{ tableHeight }">
            <div class="d-flex mt-4 ml-4 pt-1" v-if="TAB_SELECT[selectedTab].id === BOOKING_TAB_SELECT.BOOKING_TAB.id">
              <BookingForm :machine="selectedItems.length != 0 ? selectedItems[0] : null" :selectedPlaceName="selectedPlaceName"
                @close-booking-modal="closeModalBooking" @reload-data="getItems" :searchParams="searchParams"
                ref="bookingForm" />
              <AddRequestForm :fieldId="parseInt(field_id)" :searchParams="searchParams" :isAddRequest="isAddRequest" v-if="roleUser != ROLE_FOREMAN"/>
            </div>
            <div class="d-flex mt-4 pt-1" v-else-if="TAB_SELECT[selectedTab].id === BOOKING_TAB_SELECT.ACHIEVEMENT_TAB.id">
              <FormListTenken ref="formListTenken" :role="userRole" />
            </div>
            <v-data-table v-model="selectedItems"
              :headers="TAB_SELECT[selectedTab].id === BOOKING_TAB_SELECT.BOOKING_TAB.id ? TABLE_LABELS : TABLE_ACHIEVEMENT_LABELS"
              :items="items" :items-per-page="searchParams.pageCount" item-key="field_machine_id" fixed-header
              :height="TAB_SELECT[selectedTab].id === BOOKING_TAB_SELECT.BOOKING_TAB.id ? tableHeight - 53 : tableHeight - 5"
              hide-default-footer disable-sort class="ml-0" sort-by="updatedAt" noDataText="データがありません。" @update:page="$vuetify.goTo(0)" :key="searchParams.currentPage">
              <template v-slot:[`item.machine_field_name`]="{ item }">
                <div>
                  {{ convertName(item.machine_field_name, 50) }}
                </div>
              </template>
              <template v-slot:[`header.model`]="{}">
                <div class="d-flex pt-6 pb-1"
                  style="height:100%;">
                  ナンバー / 機種
                </div>
              </template>
              <template v-slot:[`header.place_name`]="{}">
                <div class="d-flex pt-6 pb-1"
                  style="height:100%;">
                  留置場所
                </div>
              </template>
              <template v-slot:[`header.booking_updated_at`]="{}">
                <div class="d-flex pt-6 pb-1"
                  style="height:100%;">
                  最終更新
                </div>
              </template>
              <template v-slot:[`header.booking_rate`]="{}">
                <div class="d-flex pt-6 pb-1"
                  style="height:100%;">
                  予約率
                </div>
              </template>
              <template v-slot:[`header.date`]="{}" class="tab_abc">
                <div id="app" class="py-4 d-flex" style="height:100%; padding: 0px !important">
                  <to-header-date v-for="i of totalDate" :key="i" :index="i" :total-date="totalDate"
                    :list-date="listDate" />
                </div>
              </template>
              <template v-slot:[`item.model`]="{ item }">
                <div class="d-flex align-center" :class="{'up-height': TAB_SELECT[selectedTab].id !== BOOKING_TAB_SELECT.BOOKING_TAB.id}">
                  <v-radio-group v-if="TAB_SELECT[selectedTab].id === BOOKING_TAB_SELECT.BOOKING_TAB.id" dense
                    v-model="selectedRadioId">
                    <v-radio :value="item.field_machine_id"></v-radio>
                  </v-radio-group>
                  <div class="d-flex flex-column">
                    <div>
                      <span>{{ item.number_plate }}</span>
                      <span v-if="item.field_identification_number">（{{ item.field_identification_number }}）</span>
                    </div>
                    <div>
                      {{ item.floor_height.toFixed(2) }}m {{ item.lifting_method }} {{ item.undercarriage }} {{
                          item.working_floor
                      }}
                    </div>
                  </div>
                </div>

              </template>
              <template v-slot:[`item.place_name`]="{ item }">
                <div style="word-break:break-all;">
                  {{ item.place_name }}
                </div>
              </template>
              <template v-slot:[`item.booking_rate`]="{ item }">
                <div>
                  {{ Math.floor(item.booking_rate) }}%
                </div>
              </template>
              <template v-slot:[`item.booking_updated_at`]="{ item }">
                <div>
                  {{ item.booking_updated_at ? diffTime(item.booking_updated_at) : null }}
                </div>
              </template>
              <template v-slot:[`item.date`]="{ item }" class="mx-0 px-0">
                <div id="app" class="container d-flex" style="height:100%">
                  <template v-for="i of totalDate">
                    <div :key="i" class="d-flex">
                      <to-press :index="i" period="0" 
                        :row-id="item.field_machine_id" 
                        :total-date="totalDate"
                        :list-date="listDate"
                        :booked-time="item.bookings"
                        :result-tenken="item.result_tenken"
                        :current-booking="currentBooking" 
                        :tab="selectedTab" 
                        :start-bookable="item.start_bookable_date" @delete-booking="deleteBooking"
                        :date-range-repair="item.dateRangeRepair"
                        :date-range-repair-stock="item.dateRangeRepairStock"
                        :current-deleting="currentDeleting"
                        :return-date="item.return_date"
                        @click-booking="clickBooking" @open-booked="OpenBooked"  @open-form-list-tenken="openFormListTenken" 
                        :ref="item.field_machine_id + '_' + i + '_0'"
                        :placeName="item.place_name"
                        :useTime="item.use_time"
                        />
                      <to-press :index="i" period="1"
                        :row-id="item.field_machine_id"
                        :total-date="totalDate"
                        :list-date="listDate"
                        :booked-time="item.bookings"
                        :result-tenken="item.result_tenken"
                        :current-booking="currentBooking"
                        :tab="selectedTab"
                        :start-bookable="item.start_bookable_date" @delete-booking="deleteBooking"
                        :date-range-repair="item.dateRangeRepair"
                        :date-range-repair-stock="item.dateRangeRepairStock"
                        :current-deleting="currentDeleting"
                        :return-date="item.return_date"
                        @click-booking="clickBooking" @open-booked="OpenBooked" @open-form-list-tenken="openFormListTenken"
                        :ref="item.field_machine_id + '_' + i + '_1'"
                        :placeName="item.place_name"
                        :useTime="item.use_time"
                        />
                    </div>
                  </template>
                </div>
              </template>
            </v-data-table>
            <!-- <template v-else-if="TAB_SELECT[selectedTab].id === BOOKING_TAB_SELECT.ACHIEVEMENT_TAB.id">
                <div>achievement tab</div>
              </template> -->
          </template>
          <template #tableFooter>
            <!-- (共通) ページネーション -->
            <Pagination :current="searchParams.currentPage" :total="searchParams.totalPage" @pageUpdate="pageUpdate" />
          </template>
        </TableLayout>
      </template>
    </DefaultLayout>
    <Popup width="480px" :dialog="isShowCancel">
        <ConfirmCloseDialog  v-model="isShowCancel" :title="(flgInspectionResult === 1 && allowedCancelBooking === 1) ? '選択項目の削除' : '選択した予約のキャンセル'"
          :text1="(flgInspectionResult === 1 && allowedCancelBooking === 1) ? '選択したデータを削除します。<br />点検実績がある場合、予約をキャンセルしますが<br />本当によろしいでしょうか？' : '選択した予約をキャンセルします。<br />本当にキャンセルしてもよろしいですか？'"
          :text2="(flgInspectionResult === 1 && allowedCancelBooking === 1) ? 'この処理を元に戻すことはできません。' : ''"
          :textSubmit="(flgInspectionResult === 1 && allowedCancelBooking === 1) ? '解除' : 'はい'"
          :warning="true" @close="closeDialogConfirm()" @yes="submitDelete()"/>
    </Popup>
    <Popup width="480px" :dialog="isHasPlaceName">
        <ConfirmCloseDialog  v-model="isHasPlaceName" title="予約不可のお知らせ"
          text1="まだ荷受けしていない（留置場所未設定）ため、予約できません。" :btnDisable="true"
          :warning="true" @yes="closePopUpUnKnowPlaceName"/>
    </Popup>
  </div>
</template>
<script>
import { Store } from "@/store/Store.js";
import DefaultLayout from "@/components/layout/DefaultLayout";
import TableLayout from "@/components/layout/TableLayout";
import TableHeader from "@/components/masterTable/elements/TableHeader";
import Pagination from "@/components/masterTable/elements/Pagination";
import TabSelect from "@/components/forms/elements/TabSelect";
import SearchFormWrapper from "@/components/masterTable/elements/SearchFormWrapper";
import BookingHeader from "./components/BookingHeader";
import ToHeaderDate from "./components/ToHeaderDate";
import ToPress from "./components/ToPress";
import TableSortWrapper from "@/components/masterTable/elements/TableSortWrapper";
import TableSort from "@/components/masterTable/elements/TableSort";
import CompanyHeader from "@/components/companyHeader/CompanyHeader";
import SitePortalHeader from "@/components/globalHeader/SitePortalHeader";

import {
  TABLES_PER_PAGE,
  TABLE_SORT_ORDERS,
  NO_DATA_MESSAGE,
  ROLE_SUPERVISOR,
  ROLE_USER_RENTAL_COMPANY,
  ROLE_FOREMAN
} from "@/constants/COMMON"; //絞り込みフォームで使用

import CrudForm from "@/components/forms/crud/CrudForm";
import InputText from "@/components/forms/elements/InputText"; //絞り込みフォームで使用
import Select from "@/components/forms/elements/Select"; //絞り込みフォームで使用
import Label from "@/components/forms/elements/Label"; //絞り込みフォームで使用
import {
  CRUD_SORT_ITEMS,
} from "@/constants/CRUDS"; //絞り込みフォームで使用

import {
  BOOKING_STATUS,
  KINDS_STATUS,
  LIFTING_METHOD,
  WORKING_FLOOR,
  UNDERCARRIAGE,
  BOOKING_TAB_SELECT,
  BOOKING_TAB_TABLE_LABELS,
  ACHIEVEMENT_TAB_TABLE_LABELS,
  FOMAT_DATE
} from "@/constants/BOOKING"
import {
  HEADER_MENU_ITEMS_FIELD_PORTAL,
  HEADER_MENU_ITEMS_INHOUSE
} from "@/constants/GLOBALHEADER"; //グローバルヘッダーメニュー

import BookingForm from "../../components/forms/booking/BookingForm.vue";
import LabelSearchMachine from "../../components/forms/elements/LabelSearchMachine.vue";
import AddRequestForm from "../../components/forms/booking/AddRequestForm.vue";
import moment from "moment";
import Loading from "@/components/loading/Loading";
import FormListTenken from "./components/FormListTenken.vue";
import ConfirmCloseDialog from "@/components/dialog/ConfirmCloseDialog.vue";
import Popup from "@/components/common/Popup.vue";

//タイトル
const PAGE_TITLE = "予約状況";

//１ページあたりのテーブル件数
const PAGE_COUNT = 25;

//１ページあたりのテーブル件数オプション
const PAGE_COUNT_OPTIONS = TABLES_PER_PAGE;

// 昇順降順オプション
const SORT_ORDERS = TABLE_SORT_ORDERS;

//ストア
// const STORE = "Cruds";

//テーブルヘッダーラベル
const TABLE_LABELS = BOOKING_TAB_TABLE_LABELS;
const TABLE_ACHIEVEMENT_LABELS = ACHIEVEMENT_TAB_TABLE_LABELS;

//ソート要素
const SORT_ITEMS = CRUD_SORT_ITEMS;


export default {
  head: {
    title() {
      return { inner: "AirLiza", separator: "|", complement: PAGE_TITLE };
    },
  },
  props:{
    tabDefault: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      /**
       * (共通)
       */
      PAGE_TITLE,
      TABLE_LABELS,
      TABLE_ACHIEVEMENT_LABELS,
      SORT_ITEMS,
      SORT_ORDERS,
      PAGE_COUNT_OPTIONS,
      NO_DATA_MESSAGE,
      ROLE_SUPERVISOR,
      ROLE_USER_RENTAL_COMPANY,
      ROLE_FOREMAN,
      flagUserRentalCompany: false,
      flagSupervisor: false,
      /**
       * (共通)
       * 一覧データ
       */
      items: [

      ],
      moment,
      PARTNERS_ARRAY: [],
      FIELD_PLACES_ARRAY: [],
      field_id: null,
      selectedItems: [],
      typeDatePicker: 'date',
      current_time: new Date(),
      isLoading: false,
      selectedRadioId: null,
      currentBooking: {
        startRef: null,
        use_start_date: null,
        use_start_time: null,
        endRef: null,
        use_end_date: null,
        use_end_time: null,
        rowId: null,
      },
      searchParams: this.initSearchParams(),
      popups: {
        // 追加/編集フォーム
        isShowItemForm: false,
        // 削除確認ダイアログ表示
        isShowRemoveDialog: false,
      },
      BOOKING_TAB_SELECT,
      selectedTab: this.tabDefault,
      totalDate: 31,
      isAddRequest: false,
      currentDeleting: {
        startRef: null,
        use_start_date: null,
        use_start_time: null,
        endRef: null,
        use_end_date: null,
        use_end_time: null,
        rowId: null,
        booking_id: null
      },
      booking: {
        field_id: "",
        booking_id: "",
        delete_start_date: "",
        delete_start_time: FOMAT_DATE[0].value,
        delete_end_date: "",
        delete_end_time: FOMAT_DATE[1].value
      },
      isDisplay: 'none',
      top: 'unset',
      left: 'unset',
      isShowCancel: false,
      isHasPlaceName: false,
      selectedPlaceName: "",
      roleUser: "",
      allowedCancelBooking: '',
      flgInspectionResult: ''
    };
  },

  components: {
    //共通のコンポーネント
    DefaultLayout,
    TableLayout,
    TableHeader,
    Pagination,
    SearchFormWrapper,
    TableSortWrapper,
    TableSort,
    CompanyHeader,
    CrudForm,
    InputText,
    Select,
    TabSelect,
    Label,
    BookingHeader,
    ToHeaderDate,
    ToPress,
    BookingForm,
    AddRequestForm,
    SitePortalHeader,
    FormListTenken,
    LabelSearchMachine,
    Loading,
    Popup,
    ConfirmCloseDialog
  },
  created() {
    this.roleUser = JSON.parse(sessionStorage.getItem("CURRENT_SITE")).role;
    if (JSON.parse(sessionStorage.getItem("CURRENT_SITE")) === null)
      this.$router.push("/master/fields")
  },
  destroyed() {
    const self = this
    this.$el.removeEventListener('click', function(event) {
      const tooltipDelete = self.$refs.tooltipDelete
      const target = event.target;
      if (this.isDisplay == 'block' && tooltipDelete?.contains(target) == false) {
        self.closeTooltipDelete();
      }
    });
  },
  async mounted() {
    const self = this
    this.$el.addEventListener('click', function(event) {
      const tooltipDelete = self.$refs.tooltipDelete
      const target = event.target;
      if (self.isDisplay == 'block' && tooltipDelete?.contains(target) == false) {
        self.closeTooltipDelete();
      }
    });

    history.pushState(null, null, location.href);
    window.onpopstate = function () {
      history.go(1);
    };
    this.field_id = JSON.parse(sessionStorage.getItem("CURRENT_SITE")).field_id;
    /**
     * グローバルヘッダーメニューを更新
     */
    Store.dispatch("GlobalHeader/setInHouseMenu", {
      menuId: HEADER_MENU_ITEMS_INHOUSE.SITES.id,
    });

    Store.dispatch("GlobalHeader/setSiteMenu", {
      menuId: HEADER_MENU_ITEMS_FIELD_PORTAL.RESERVATION_STATUS.id,
    });

    /**
     * (共通)
     * ここからapiにリクエストします
     */
    if(this.TAB_SELECT[this.tabDefault].id === BOOKING_TAB_SELECT.ACHIEVEMENT_TAB.id) {
      this.typeDatePicker = 'month',
      this.searchParams.designated_date = moment().startOf('month').format('YYYY/MM/DD');
    }
    this.getItems();

    this.getPartners();
    this.getFieldPlaces();
    this.getFieldGroups();

    this.$watch(
      () => Store.getters["Fields/getSpace"],
      (data) => {
        let _data = [...data];
        _data = _data.map(e => {
          e.name = e.display_name;
          return e;
        });
        this.FIELD_PLACES_ARRAY = [{ id: null, name: "" }, ..._data];
      },
      {
        immediate: true,
        deep: true
      }
    );
    this.$watch(
      () => Store.getters["FieldGroups/getData"],
      (data) => {
        let _data = [...data].filter(item => !item.is_main_contractor);
        this.PARTNERS_ARRAY = [{ id: null, name: "" }, ..._data];
      },
      {
        immediate: true,
        deep: true
      }
    );
    this.$watch(
      () => Store.getters["Fields/getListMachineForBooking"],
      (data) => {
        this.items = data.data.machines;
        //MMSV2-1573-start
        let dateRangeRepair = data.data.dateRangeRepair
        let dateRangeRepairStock = data.data.dateRangeRepairStock
        this.items.forEach((e,i) => {
            const dateRangeRepairStockItem = dateRangeRepairStock.filter(ex => {
              return ex.field_machine_id == e.field_machine_id}
            )
            if(dateRangeRepair[e.field_machine_id]) {
              this.items[i] = {
                ...e,
                'dateRangeRepair': dateRangeRepair[e.field_machine_id],
                'dateRangeRepairStock': dateRangeRepairStockItem,
              }
            }
            
        });
        //MMSV2-1573-end
        this.current_time = data.data.current_time;
        this.searchParams.total_item = data.pagination.total_item;
        this.searchParams.currentPage = data.pagination.current;
        this.searchParams.totalPage = data.pagination.total;
        this.isAddRequest = true;
      },
    );
    this.$watch(
      () => Store.getters["Fields/getIsLoading"],
      (data) => {
        this.isLoading = data;
      },
      {
        immediate: true,
        deep: true,
      }
    );
    this.$watch(
      () => Store.getters["Sites/getUserRole"],
      (data) => {
        if (data == this.ROLE_SUPERVISOR) {
          this.flagSupervisor = true;
        } else if (data == this.ROLE_USER_RENTAL_COMPANY) {
          this.flagUserRentalCompany = true;
        }
      },
      {
        immediate: true,
        deep: true,
      }
    );
    this.$watch(
      () => Store.getters["GlobalHeader/getCurrentSite"],
      (data, oldData) => {
        if (data?.field_id !== oldData?.field_id) {
          this.field_id = data.field_id;
          this.getItems();
        }
      },
      {
        deep: true,
        immediate: true,
      }
    );
  },

  /**
   * computedの使いわけのイメージとしては、
   * 1.リアクティブではない定数をdataに追加してdataを肥大化したくない
   * 2.状態を明示的に定義したい
   */
  computed: {
    /**
     * (共通)
     * 削除ボタンの活性・非活性
     * selectedItems.length === 0
     * 自体はtemplate側で記述できますが、
     * ここに明示的に状態を定義して可読性を担保してます
     */
    listDate() {
      let currentDate = this.TAB_SELECT[this.selectedTab].id === BOOKING_TAB_SELECT.BOOKING_TAB.id ? new Date(this.searchParams.designated_date) :
        new Date(moment(this.searchParams.designated_date).format('YYYY/MM/DD'));
      const listDateTemp = [];
      for (let i = 0; i < this.totalDate; i++) {
        let newDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate(), 0, 0, 0);
        listDateTemp.push(newDate);
        currentDate.setDate(currentDate.getDate() + 1);
      }
      return listDateTemp;
    },
    /**
     * API Param
     */
    apiParams() {
      return {
        name: this.searchParams.name,
        description: this.searchParams.description,
        order: this.searchParams.sort,
        asc: this.searchParams.asc ? 1 : 0,
        current_page: this.searchParams.currentPage,
        page_size: this.searchParams.pageCount,
      };
    },
    COMPANY_USER() {
      return JSON.parse(sessionStorage.getItem("COMPANY_USER")).Login
        .company_user;
    },
    BOOKING_STATUS() {
      return Object.keys(BOOKING_STATUS).map((key) => {
        return BOOKING_STATUS[key];
      });
    },
    KINDS_STATUS() {
      return Object.keys(KINDS_STATUS).map((key) => {
        return KINDS_STATUS[key];
      });
    },
    LIFTING_METHOD() {
      return Object.keys(LIFTING_METHOD).map((key) => {
        return LIFTING_METHOD[key];
      });
    },
    UNDERCARRIAGE() {
      return Object.keys(UNDERCARRIAGE).map((key) => {
        return UNDERCARRIAGE[key];
      });
    },
    WORKING_FLOOR() {
      return Object.keys(WORKING_FLOOR).map((key) => {
        return WORKING_FLOOR[key];
      });
    },
    TAB_SELECT() {
      return Object.keys(BOOKING_TAB_SELECT).map((key) => {
        return BOOKING_TAB_SELECT[key];
      });
    },
    userRole() {
      return Store.getters["GlobalHeader/getCurrentSite"] ? Store.getters["GlobalHeader/getCurrentSite"].role : JSON.parse(sessionStorage.getItem("CURRENT_SITE")).role;
    }
  },

  watch: {
    selectedRadioId(newValue) {
      if (this.selectedItems.length === 0) {
        this.selectedItems = this.items.filter(item => item.field_machine_id === newValue)
      } else if (newValue != this.selectedItems[0].field_machine_id) {
        this.selectedItems = this.items.filter(item => item.field_machine_id === newValue)
      }
    },
    selectedItems(newValue) {
      if (newValue.length == 0) {
        this.selectedRadioId = null;
      } else if (newValue[0].field_machine_id != this.selectedRadioId) {
        this.selectedRadioId = newValue[0].field_machine_id;
      }
      this.selectedPlaceName = newValue[0]?.place_name;
    },
    selectedTab(newValue) {
      this.searchParams = this.initSearchParams();
      if (this.TAB_SELECT[newValue].id === BOOKING_TAB_SELECT.BOOKING_TAB.id) {
        this.typeDatePicker = "date"
        this.searchParams.designated_date = new Date()
      } else {
        this.typeDatePicker = "month"
        this.searchParams.designated_date = moment().startOf('month').format('YYYY/MM/DD');
      }
      this.selectedItems = [];
      this.$refs.tableHeader.closeSearch();
      this.getItems();
    },
    tabDefault(newValue) {
      if(this.selectedTab != newValue) {
        this.selectedTab = newValue;
      }
    }
  },

  methods: {
    closeTooltipDelete () {
      this.isDisplay = 'none';
      if(this.$refs[this.currentDeleting.startRef]) {
        this.$refs[this.currentDeleting.startRef][0].inDelete();
      }
      if(this.$refs[this.currentDeleting.endRef]) {
        this.$refs[this.currentDeleting.endRef][0].inDelete();
      }
      this.currentDeleting = {
        startRef: null,
        endRef: null,
        use_end_date: null,
        use_end_time: null,
        rowId: null,
        booking_id: null,
      };
      this.booking = {
        field_id: "",
        booking_id: "",
        delete_start_date: "",
        delete_start_time: FOMAT_DATE[0].value,
        delete_end_date: "",
        delete_end_time: FOMAT_DATE[1].value
      }
    },

    initSearchParams() {
      return {
        word: "",
        pageCount: PAGE_COUNT,
        designated_date: new Date(),
        booking_field_group_id: null,
        field_place_id: null,
        field_identification_number: null,
        currentPage: 1,
        totalPage: 1,
        total_item: 0,
        sort: null, //sortする項目
        asc: true,
        companyBranchId: null,
      }
    },

    // get partners company
    async getPartners() {
      this.field_id = JSON.parse(sessionStorage.getItem("CURRENT_SITE")).field_id;
      const params = {
        field_id: this.field_id,
      };
      await Store.dispatch('Fields/getListPartnerCompanyByFieldId', params);
    },

    // get field places 
    async getFieldPlaces() {
      this.field_id = JSON.parse(sessionStorage.getItem("CURRENT_SITE")).field_id;
      const params = {
        field_id: this.field_id,
      };
      await Store.dispatch('Fields/getListSpaceByFieldId', params);
    },

    async getFieldGroups() {
      this.field_id = JSON.parse(sessionStorage.getItem("CURRENT_SITE")).field_id;
      const params = {
        field_id: this.field_id,
      };
      await Store.dispatch(`FieldGroups/getListFieldGroupInfo`, {params: params});
    },

    closeModalBooking() {
      if (this.currentBooking.use_start_date && this.currentBooking.use_end_date) {
        this.$refs[this.currentBooking.startRef][0].inActive();
        this.$refs[this.currentBooking.endRef][0].inActive();
        this.currentBooking = {
          startRef: null,
          endRef: null,
          use_end_date: null,
          use_end_time: null,
          rowId: null,
        }
      }
    },
    openFormListTenken(data) {
      let currentMachine = this.items.filter(machine => machine.field_machine_id === data.field_machine_id);
      this.$refs.formListTenken.openModalFormListTenken({
        ...data,
        machine: currentMachine.length != 0 ? currentMachine[0] : null
      });
    },
    deleteBooking(item) {
      this.flgInspectionResult = item?.bookedId?.flg_inspection_result;
      this.allowedCancelBooking = item?.bookedId?.allowed_cancel_booking;
      if(this.$refs[this.currentBooking.startRef]) {
        this.$refs[this.currentBooking.startRef][0].inActive();
      }
      this.currentBooking = {
        startRef: null,
        endRef: null,
        use_end_date: null,
        use_end_time: null,
        rowId: null,
      }
      let currentMachine = this.items.filter(machine => machine.field_machine_id === item.rowId)
      if(this.$refs[this.currentDeleting.endRef] && this.$refs[this.currentDeleting.startRef]) {
        this.$refs[this.currentDeleting.endRef][0].inDelete();
        this.$refs[this.currentDeleting.startRef][0].inDelete();
        this.$refs[item.ref][0].inDelete();
        return;
      } else if (!this.currentDeleting.use_start_date) {
        ({ date: this.currentDeleting.use_start_date, period: this.currentDeleting.use_start_time, rowId: this.currentDeleting.rowId, ref: this.currentDeleting.startRef, booking_id: this.currentDeleting.booking_id } = item);
        this.selectedItems = currentMachine;
        return;
      }
      //end rowId != start rowId -> replace
      if (item.rowId != this.currentDeleting.rowId || (this.currentDeleting.booking_id && item.booking_id != this.currentDeleting.booking_id)) {
        if(this.$refs[this.currentDeleting.startRef]) {
          this.$refs[this.currentDeleting.startRef][0].inDelete();
        }
        if(this.$refs[this.currentDeleting.endRef]) {
          this.$refs[this.currentDeleting.endRef][0].inDelete();
        }
        ({ date: this.currentDeleting.use_start_date, period: this.currentDeleting.use_start_time, rowId: this.currentDeleting.rowId, ref: this.currentDeleting.startRef, booking_id: this.currentDeleting.booking_id } = item);
        this.selectedItems = currentMachine;
        return;
      }
      //endDate < startDate -> swap
      if (item.date.getTime() < this.currentDeleting.use_start_date.getTime()) {
        this.currentDeleting.use_end_date = this.currentDeleting.use_start_date;
        this.currentDeleting.use_end_time = this.currentDeleting.use_start_time;
        this.currentDeleting.endRef = this.currentDeleting.startRef;
        this.currentDeleting.use_start_date = item.date;
        this.currentDeleting.use_start_time = item.period;
        this.currentDeleting.startRef = item.ref;
        this.currentDeleting.booking_id = item.booking_id;
        this.currentDeleting = { ...this.currentDeleting };
        this.setDataBooking(item, this.field_id, this.currentDeleting);
        setTimeout(() => {
          this.isDisplay = 'block'
        }, 100);
        this.top = (item.clientY - 50) + 'px';
        this.left = (item.clientX) + 'px';
        if (item.x + 157 > item.innerWidth) {
          this.left = (item.clientX - 160) + 'px';
        }
        return;
      }
      this.currentDeleting.use_end_date = item.date;
      this.currentDeleting.use_end_time = item.period;
      this.currentDeleting.endRef = item.ref;
      this.currentDeleting.booking_id = item.booking_id;
      if (this.currentDeleting.use_end_date.getTime() == this.currentDeleting.use_start_date.getTime()
          && this.currentDeleting.use_end_time < this.currentDeleting.use_start_time
      ) {
        this.currentDeleting.use_end_time = this.currentDeleting.use_start_time;
        this.currentDeleting.use_start_time = item.period;
      }
      this.currentDeleting = { ...this.currentDeleting };
      this.setDataBooking(item, this.field_id, this.currentDeleting);
      setTimeout(() => {
        this.isDisplay = 'block'
      }, 100);
      this.top = (item.clientY - 50) + 'px';
      this.left = (item.clientX) + 'px';
      if (item.x + 157 > item.innerWidth) {
        this.left = (item.clientX - 160) + 'px';
      }
    },
    setDataBooking(items, fieldId, currentDeleting) {
      this.booking.field_id = fieldId;
      this.booking.booking_id = items.booking_id;
      this.booking.delete_start_date = moment(new Date(currentDeleting.use_start_date)).format('YYYY-MM-DD');
      this.booking.delete_start_time = currentDeleting.use_start_time;
      this.booking.delete_end_date = moment(new Date(currentDeleting.use_end_date)).format('YYYY-MM-DD');
      this.booking.delete_end_time = currentDeleting.use_end_time;
      this.booking = { ...this.booking };
    },
    async submitDelete() {
      if(this.$refs[this.currentDeleting.startRef]) {
        this.$refs[this.currentDeleting.startRef][0].inDelete();
      }
      if(this.$refs[this.currentDeleting.endRef]) {
        this.$refs[this.currentDeleting.endRef][0].inDelete();
      }
      this.isDisplay = 'none';
      let response = await Store.dispatch('Booking/deletePeriodBookingInfo', this.booking, true);
      if (!response.hasError) {
        Store.dispatch("Toast/show", {
          status: 200,
          message: "削除しました。",
        });
      } else {
        if (response?.response?.data?.message) {
          Store.dispatch("Error/show", {
            status: 500,
            message: response.response.data.message,
          });
        } else {
          Store.dispatch("Error/show", {
            status: 500,
            message: "予約情報削除に失敗しました。",
          });
        }
      }
      this.isShowCancel = false;
      this.closeTooltipDelete();
      this.getItems();
    },
    closeDialogConfirm() {
      this.isShowCancel = false;
      this.closeTooltipDelete();
    },
    clickBooking(item) {
      if(this.$refs[this.currentDeleting.startRef]) {
        this.$refs[this.currentDeleting.startRef][0].inDelete();
      }
      if(this.$refs[this.currentDeleting.endRef]) {
        this.$refs[this.currentDeleting.endRef][0].inDelete();
      }
      this.currentDeleting = {
        startRef: null,
        endRef: null,
        use_end_date: null,
        use_end_time: null,
        rowId: null,
        booking_id: null,
      }
      let currentMachine = this.items.filter(machine => machine.field_machine_id === item.rowId)
      if (!this.currentBooking.use_start_date) {
        ({ date: this.currentBooking.use_start_date, period: this.currentBooking.use_start_time, rowId: this.currentBooking.rowId, ref: this.currentBooking.startRef } = item);
        this.selectedItems = currentMachine;
        return;
      }
      //end rowId != start rowId -> replace
      if (item.rowId != this.currentBooking.rowId) {
        this.$refs[this.currentBooking.startRef][0].inActive();
        ({ date: this.currentBooking.use_start_date, period: this.currentBooking.use_start_time, rowId: this.currentBooking.rowId, ref: this.currentBooking.startRef } = item);
        this.selectedItems = currentMachine;
        return;
      }
      //check exist booked between 2 date
      let isExistBooked = false;
      currentMachine[0]?.bookings.forEach(booked => {
        let startDate = new Date(booked.use_start_date).getTime();
        let startTime = booked.use_start_time;
        let currentBookingDate = new Date(moment(this.currentBooking.use_start_date).format('YYYY-MM-DD')).getTime();
        let currentBookingTime = this.currentBooking.use_start_time;
        let itemDate = new Date(moment(item.date).format('YYYY-MM-DD')).getTime();
        let itemTime = item.period;
        if (((startDate > currentBookingDate || (startDate == currentBookingDate && startTime > currentBookingTime)) && (startDate < itemDate || (startDate == itemDate && startTime < itemTime))) ||
          ((startDate > itemDate || (startDate == itemDate && startTime > itemTime)) && (startDate < currentBookingDate || (startDate == currentBookingDate && startTime < currentBookingTime)))) {
          isExistBooked = true;
        }
      })
      //exist booked between 2 date -> remove date 1, update start date = item
      if (isExistBooked) {
        this.$refs[this.currentBooking.startRef][0].inActive();
        ({ date: this.currentBooking.use_start_date, period: this.currentBooking.use_start_time, rowId: this.currentBooking.rowId, ref: this.currentBooking.startRef } = item);
        this.selectedItems = currentMachine;
        return;
      }
      if(!item.placeName || !item.placeName.trim()) {
        this.currentBooking.use_end_date = item.date;
        this.currentBooking.use_end_time = item.period;
        this.currentBooking.endRef = item.ref;
        this.currentBooking = { ...this.currentBooking };
        this.isHasPlaceName = true;
         return;
      }
      //endDate < startDate -> swap
      if (
        (item.date.getTime() < this.currentBooking.use_start_date.getTime()) || 
        (item.date.getTime() == this.currentBooking.use_start_date.getTime() && 
         item.period == 0 && this.currentBooking.use_start_time == 1)
        ) {
        this.currentBooking.use_end_date = this.currentBooking.use_start_date;
        this.currentBooking.use_end_time = this.currentBooking.use_start_time;
        this.currentBooking.endRef = this.currentBooking.startRef;
        this.currentBooking.use_start_date = item.date;
        this.currentBooking.use_start_time = item.period;
        this.currentBooking.startRef = item.ref;
        this.currentBooking = { ...this.currentBooking };
        this.$refs.bookingForm.openModalBooking(null, this.currentBooking, this.selectedPlaceName);
        return;
      }

      this.currentBooking.use_end_date = item.date;
      this.currentBooking.use_end_time = item.period;
      this.currentBooking.endRef = item.ref;
      this.currentBooking = { ...this.currentBooking };
      this.$refs.bookingForm.openModalBooking(null, this.currentBooking, this.selectedPlaceName);
      //
    },
    /**
     * (共通)
     * ページネーションイベント
     * @param Number
     */
    pageUpdate(n) {
      let searchParams = { ...this.searchParams };
      searchParams.currentPage = n;
      this.searchParams = searchParams;
      this.getItems();
    },
    pageCountUpdate() {
      let searchParams = { ...this.searchParams };
      searchParams.currentPage = 1;
      this.searchParams = searchParams;
      this.getItems();
    },

    diffTime(time) {
      let duration = moment.duration(moment(this.current_time).diff(moment(time)));
      if (duration.years() >= 1) {
        return duration.years() + '年前'; //xx years ago
      }
      if (duration.months() >= 1) {
        return duration.months() + 'ヶ月前'; //xx months ago
      }
      if (duration.days() >= 1) {
        return duration.days() + '日前'; //xx days ago
      }
      if (duration.hours() >= 1) {
        return duration.hours() + '時間前'; //xx hours ago
      }
      return duration.minutes() + '分前'
    },

    /**
     * (共通)
     * 検索
     */
    onSearch() {
      this.searchParams["currentPage"] = 1;
      this.getItems();
    },

    onChangeSearchParams({ name, value }) {
      let searchParams = { ...this.searchParams };
      searchParams[name] = value;
      this.searchParams = searchParams;
    },

    onChangeSortParams({ name, value }) {
      this.selectedRadioId = null;
      let searchParams = { ...this.searchParams };
      if(name === 'designated_date'){
        searchParams.designated_date = moment(value).isValid() ? moment(value).format('YYYY-MM-DD') : moment(value, 'YYYY/MM').format('YYYY-MM-DD')
      } else {
        searchParams[name] = value;
      }
      name == "pageCount" ? (searchParams["currentPage"] = 1) : "";
      this.searchParams = searchParams;
      this.getItems();
    },

    OpenBooked(booked) {
      this.closeTooltipDelete();
      let currentMachine = this.items.filter(machine => machine.field_machine_id === booked.field_machine_id)
      this.selectedItems = currentMachine
      this.$refs.bookingForm.openModalBooking(booked.booking_id, null, this.selectedItems[0].place_name);
      this.$refs.bookingForm.setFlg(booked);
    },

    async getItems(isShowLoading = true) {
      this.field_id = JSON.parse(sessionStorage.getItem("CURRENT_SITE")).field_id;
      let isBoth = 0;
      if (this.searchParams.is_shared?.length === 2) {
        isBoth = 1;
      }
      const params = {
        field_id: this.field_id,
        designated_date: moment(this.searchParams.designated_date).format('YYYY-MM-DD'),
        booking_field_group_id: this.searchParams.booking_field_group_id,
        field_place_id: this.searchParams.field_place_id,
        field_identification_number: this.searchParams.field_identification_number,
        is_booking: this.searchParams.is_booking?.length === 1 ? this.searchParams.is_booking[0] : null,
        is_shared: this.searchParams.is_shared?.length === 1 ? this.searchParams.is_shared[0] : null,
        lifting_method: this.searchParams.lifting_method ? this.searchParams.lifting_method: null ,
        undercarriage: this.searchParams.undercarriage ? this.searchParams.undercarriage : null,
        working_floor: this.searchParams.working_floor ? this.searchParams.working_floor : null,
        page: this.searchParams.currentPage,
        page_size: this.searchParams.pageCount,
        is_both: isBoth
      }
      await Store.dispatch('Fields/getListMachineForBooking', params, isShowLoading);
    },

    closePopUpUnKnowPlaceName() {
      this.$refs[this.currentBooking.startRef][0].inActive();
        this.$refs[this.currentBooking.endRef][0].inActive();
        this.currentBooking = {
          startRef: null,
          endRef: null,
          use_end_date: null,
          use_end_time: null,
          rowId: null,
        }
      this.isHasPlaceName = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.tab-select-header {
  color: #000000;
  font-size: 12px;
}

.tableSortWrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0 8px;
  height: 32px;

  .sortLabel {
    font-size: 12px;
    height: 32px;
    margin-right: 16px;
    color: #000000;
  }

  .sortElement {
    max-width: 128px;
    margin-right: 24px;
    color: #000000;
  }

  .total_item {
    padding-bottom: 15px;
  }
}

.cst-row {
  border-bottom: 1px solid;
  padding-top: 12px;
  border-color: #E5E5E5;
  padding-left: 30px;

  .cst-col {
    padding-top: 0px;
    padding-bottom: 0px;
    padding-left: 0;
  }
}

@media (max-width: 1024px) {
  .resize {
    width: 75px !important;
  }
}


.page .theme--light.v-data-table {
  color: rgba(0,0,0,.87) !important;
}

.title-input-item .label {
    color: #000000 !important;
  }

.v-data-table__wrapper ::v-deep .v-data-table__wrapper {
  margin-right: 10px;
  overflow-y: hidden !important;
}

.custom-color ::v-deep .tabLabel {
  height: 24px;
  font-size: 14px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 1.25px;
}
.up-height{
  padding: 10px 0px;
}

.custom-color ::v-deep .SearchFormWrapper .theme--light.v-label {
  color: rgba(0, 0, 0, 0.38);
}

.custom-color ::v-deep {

  .v-data-table--fixed-header>.v-data-table__wrapper>table>thead>tr>th, .v-data-table--fixed-header>.v-data-table__wrapper>table>thead>tr>td {
    z-index: 3;
  }
}

.custom-color ::v-deep table>tbody>tr>td:nth-child(1),
.custom-color ::v-deep table>thead>tr>th:nth-child(1) {
  position: sticky !important;
  position: -webkit-sticky !important;
  left: 0;
  background: white;
}

.custom-color ::v-deep table>tbody>tr>td:nth-child(2),
.custom-color ::v-deep table>thead>tr>th:nth-child(2) {
  position: sticky !important;
  position: -webkit-sticky !important;
  left: 330px;
  background: white;
}

.custom-color ::v-deep table>tbody>tr>td.sticky,
.custom-color ::v-deep table>thead>tr>th.sticky {
  position: sticky !important;
  position: -webkit-sticky !important;
  left: 450px;
  background: white;
}

.custom-color ::v-deep table>thead>tr>th:nth-child(1),
.custom-color ::v-deep table>thead>tr>th:nth-child(2),
.custom-color ::v-deep table>thead>tr>th.sticky{
  z-index: 4!important;
}

.custom-color ::v-deep table>tbody>tr>td:nth-child(1),
.custom-color ::v-deep table>tbody>tr>td:nth-child(2),
.custom-color ::v-deep table>tbody>tr>td.sticky{
  z-index: 3!important;
}

.custom-color ::v-deep table>thead>tr>th:nth-child(1),
.custom-color ::v-deep table>thead>tr>th:nth-child(2) {
  z-index: 2;
}

.square {
  display: none;
  position: absolute;
  min-height: 38px;
  min-width: 150px;
  padding: 5px 10px;
  border-radius: 10px;
  background-color: pink;
  margin: auto;
  border: #FF0000 solid 2px;
  z-index: 5;
}

.arrow::after {
  content: '';
  height: 10px;
  width: 10px;
  position: absolute;
  background-color: pink;
  bottom: -7px;
  left: 20%;
  border-top: #FF0000 solid 2px;
  border-left: #FF0000 solid 2px;
  transform: rotate(225deg);
}
.tooltipDelete {
  display: none;
  position: absolute;
  background: #F7B3C0;
  border: 1px solid #B00020;
  padding: 5px 10px 5px 5px;
  border-radius: 10px;
  z-index: 5;
  color: black;
  min-width: 157px;
  min-height: 34px;
  cursor:pointer;
}
</style>
