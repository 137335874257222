<template>
  <v-container>
    <!-- 自社ポータルheader -->
    <InhousePortalHeader :companyId="companyId" />
  </v-container>
</template>

<script>
import InhousePortalHeader from "@/components/globalHeader/InhousePortalHeader";
import { Store } from "@/store/Store.js";

export default {
  data() {
    return {
      // 会社id
      companyId: null,
    };
  },

  components: {
    InhousePortalHeader,
  },

  async mounted() {
    this.getCompanyId();
  },

  methods: {
    async getCompanyId() {
      // 会社idを取得
      this.companyId = await Store.getters["Login/getUser"].company_id;
    },
  },
};
</script>
