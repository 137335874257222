<template>
  <div class="FormMain">
    <div class="FormMainBody" :style="getBodyStyle">
      <v-card flat>
        <v-card-text>
          <ValidationObserver>
            <v-form autocomplete="off">
              <v-container>
                <v-row>
                  <v-col cols="12" sm="6" md="6">
                    <v-col v-if="editable">
                      <Label label="商号又は名称" class="search-company title-input-item" :editable="editable" required>
                        <v-row>
                          <v-col cols="9" style="padding: 0">
                            <v-list-item-content>
                              <SearchSelectListHight
                                name="name"
                                :values="formValues"
                                :editable="editable"
                                @formUpdate="openListCompany"
                                @search="searchListCompany"
                                validation_label="商号又は名称"
                              />
                            </v-list-item-content>
                          </v-col>
                        </v-row>
                      </Label>
                    </v-col>
                    <v-col>
                      <v-row>
                        <v-col>
                          <v-list-item-content>
                            <Label label="法人番号" class="title-input-item" :editable="editable">
                              <InputText
                                name="corporate_number"
                                :values="formValues"
                                placeholder=""
                                :disabled="true"
                                validation_label="法人番号"
                                validation_rules="required|max:255"
                                @onInput="onInput('', $event)"
                              />
                            </Label>
                          </v-list-item-content>
                        </v-col>
                        <v-col>
                          <v-list-item-content>
                            <Label label="会社名" class="title-input-item" :editable="editable">
                              <InputText
                                name="name"
                                :values="formValues"
                                :disabled="true"
                                placeholder=""
                                validation_label="会社名"
                                validation_rules="required|max:255"
                                @onInput="onInput('', $event)"
                              />
                            </Label>
                          </v-list-item-content>
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-list-item>
                      <v-list-item-content>
                        <v-row >
                          <v-col cols="24" sm="12" md="12">
                            <Label label="所在地" class="title-input-item" :editable="editable">
                              <InputText
                                name="address"
                                :values="formValues"
                                :disabled="true"
                                validation_label="所在地"
                                validation_rules="max:120"
                                @onInput="onInput('', $event)"
                              />
                            </Label>
                          </v-col>
                        </v-row>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                      <v-row>
                        <v-col cols="24" sm="12" md="6">
                          <Label label="種別" class="title-input-item" :editable="editable">
                            <RadioGroup
                              name="company_type"
                              :values="formValues"
                              :items="COMPANY_TYPE"
                              :editable="actions === MODE_CREATE"
                              @onInput="onInput('', $event)"
                            />
                          </Label>
                        </v-col>
                      </v-row>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-content>
                        <v-row >
                          <v-col cols="24" sm="12" md="6">
                            <Label label="CCUS事業者ID" class="title-input-item" :editable="editable">
                              <InputText
                                name="ccus_id"
                                :values="formValues"
                                :editable="editable"
                                validation_label="CCUS事業者ID"
                                validation_rules="ccusid:CCUS事業者ID"
                                @onInput="onInput('', $event)"
                              />
                            </Label>
                          </v-col>
                          <v-col cols="24" sm="12" md="6" v-if="actions === MODE_EDIT">
                            <Label label="会社コード" class="title-input-item" :editable="editable">
                              <InputText
                                name="company_code"
                                :values="formValues"
                                :disabled="true"
                                validation_label="会社コード"
                                @onInput="onInput('', $event)"
                              />
                            </Label>
                          </v-col>
                        </v-row>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item v-if="formValues?.company_type == COMPANY_RENTAL">
                      <v-list-item-content>
                        <v-row >
                          <v-col cols="24" sm="12" md="6">
                            <Label label="ナンバープレート会社名" class="title-input-item" :editable="editable">
                              <InputText
                                name="plate_number_prefix"
                                :values="formValues"
                                :editable="editable"
                                validation_label="ナンバープレート会社名"
                                validation_rules="max:16"
                                @onInput="onInput('', $event)"
                              />
                            </Label>
                          </v-col>
                        </v-row>
                      </v-list-item-content>
                    </v-list-item>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-col>
                    <Label label="初期登録者情報" class="title-input-item" :editable="editable">
                      <v-row>
                        <v-col>
                          <v-list-item-content>
                            <Label label="メールアドレス" :editable="editable" required>
                              <InputText
                                name="email"
                                :values="formValues?.users"
                                :editable="editable && actions === MODE_CREATE"
                                placeholder=""
                                validation_label="メールアドレス"
                                validation_rules="required|max:255|email"
                                @onInput="onInput('users', $event)"
                              />
                            </Label>
                          </v-list-item-content>
                        </v-col>
                        <v-col>
                          <v-list-item-content>
                            <Label label="電話番号" class="title-input-item" :editable="editable" required>
                              <InputText
                                name="phone"
                                :values="formValues?.users"
                                :editable="editable && actions === MODE_CREATE"
                                placeholder=""
                                validation_label="電話番号"
                                validation_rules="required|max:16|phone"
                                @onInput="onInput('users', $event)"
                              />
                            </Label>
                          </v-list-item-content>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col>
                          <v-list-item-content>
                            <Label label="姓" class="title-input-item" :editable="editable" required>
                              <InputText
                                name="name_sei"
                                :values="formValues?.users"
                                :editable="editable && actions === MODE_CREATE"
                                placeholder=""
                                validation_label="姓"
                                validation_rules="required|max:64"
                                @onInput="onInput('users', $event)"
                              />
                            </Label>
                          </v-list-item-content>
                        </v-col>
                        <v-col>
                          <v-list-item-content>
                            <Label label="名" class="title-input-item" :editable="editable" required>
                              <InputText
                                name="name_mei"
                                :values="formValues?.users"
                                :editable="editable && actions === MODE_CREATE"
                                placeholder=""
                                validation_label="名"
                                validation_rules="required|max:64"
                                @onInput="onInput('users', $event)"
                              />
                            </Label>
                          </v-list-item-content>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col>
                          <v-list-item-content>
                            <Label label="セイ" class="title-input-item" :editable="editable">
                              <InputText
                                name="kana_sei"
                                :values="formValues?.users"
                                :editable="editable && actions === MODE_CREATE"
                                placeholder=""
                                validation_label="セイ"
                                validation_rules="max:128|kana"
                                @onInput="onInput('users', $event)"
                              />
                            </Label>
                          </v-list-item-content>
                        </v-col>
                        <v-col>
                          <v-list-item-content>
                            <Label label="メイ" class="title-input-item" :editable="editable">
                              <InputText
                                name="kana_mei"
                                :values="formValues?.users"
                                :editable="editable && actions === MODE_CREATE"
                                placeholder=""
                                validation_label="メイ"
                                validation_rules="max:128|kana"
                                @onInput="onInput('users', $event)"
                              />
                            </Label>
                          </v-list-item-content>
                        </v-col>
                      </v-row>
                      <v-row v-if="actions == MODE_EDIT">
                        <v-col cols="24" sm="12" md="6">
                          <v-list-item-content>
                            <Label label="登録日" class="title-input-item" :editable="editable">
                              <InputDatepicker
                                name="created_at"
                                @onInput="onInput"
                                :values="formValues"
                                validation_label="登録日"
                                :disabled="true"
                              />
                            </Label>
                          </v-list-item-content>
                        </v-col>
                      </v-row>
                    </Label>
                    </v-col>
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
          </ValidationObserver>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>
<script>
import { ValidationObserver } from "vee-validate";
import Label from "@/components/forms/elements/Label";
import SectionLabel from "@/components/forms/elements/SectionLabel";
import InputText from "@/components/forms/elements/InputText";
import SelectSearch from "@/components/forms/elements/SelectSearch";
import SearchSelectListHight from "@/components/forms/elements/SearchSelectListHight";
import InputDatepicker from "./InputDatepicker.vue";
import RadioGroup from "@/components/forms/elements/RadioGroup.vue";
import Select from "@/components/forms/elements/Select.vue";
import FileInput from "@/components/forms/elements/FileInput";
import { Store } from "@/store/Store.js";
import {COMPANY_RENTAL, COMPANY_SCENE, MODE_CREATE, MODE_EDIT} from "@/constants/COMMON";
import { COMPANY_TYPE, USER_INIT } from "@/constants/COMPANIES";
import _ from "lodash";
import {kuroshiro} from "@/utils/convertKana";

export default {
  data: () => {
    return {
      formValues: {},
      resetValue: {},
      COMPANY_TYPE: COMPANY_TYPE,
      COMPANY_SCENE,
      COMPANY_RENTAL,
      MODE_CREATE,
      MODE_EDIT,
    };
  },
  components: {
    ValidationObserver,
    Label,
    SectionLabel,
    InputText,
    InputDatepicker,
    Select,
    FileInput,
    SelectSearch,
    SearchSelectListHight,
    RadioGroup,
  },
  props: {
    editable: {
      type: Boolean,
      default: false,
    },
    detailData: Object,
    mainHeight: {
      type: Number,
      default: 0,
    },
    dataSelectCompany: Object,
    formUpdate: Function,
    actions: String,
  },
  mounted() {
    this.$watch(
      () => this.detailData,
      (data) => {
        this.formValues = _.cloneDeep(data);
      },
      {
        immediate: true,
        deep: true
      }
    );
    this.$watch(
      () => this.actions,
      (data) => {
        if (data === this.MODE_CREATE) {
          const formValues = { ...this.formValues };
          formValues.company_type = COMPANY_SCENE;
          this.formValues = formValues;
          this.$emit("formUpdate", formValues);
        }
      },
      {
        immediate: true,
        deep: true
      }
    );
    this.$watch(
      () => [this.dataSelectCompany],
      (data) => {
        if (data[0]) {
          const formValues = { ...this.formValues };
          formValues.corporate_number = data[0].corporateNumber ?? '';
          formValues.name = data[0].name ?? '';
          formValues.name_kana =  typeof data[0].furigana === 'object' ? '' : data[0].furigana ?? '';
          formValues.address = data[0].prefectureName + data[0].cityName + data[0].streetNumber;
          this.formValues = formValues;
          this.$emit("formUpdate", formValues);
        }
      },
      {
        immediate: true,
        deep: true,
      }
    );
  },

  computed: {
    getBodyStyle() {
      let style = `height:${this.mainHeight}px; min-height: 300px`;
      return style;
    },
  },

  methods: {
    onInput(parent_name, { name, value }) {
      const formValues = { ...this.formValues };
      if (parent_name) {
        if (!formValues[parent_name]) {
          formValues[parent_name] = {};
        }
        formValues[parent_name][name] = value;
      } else {
        formValues[name] = value;
      }
      if (name == 'name_sei' || name == 'name_mei') {
        this.getNameKana( name, formValues.users[name]);
      }
      this.formValues = formValues;
      this.$emit("formUpdate", formValues);
    },
    async getNameKana( name, value) {
      let result = await kuroshiro.convert(value, {
        mode: "normal",
        to: "katakana",
      });
      const formValues =  _.cloneDeep(this.formValues);
      if (name == 'name_sei') {
        formValues.users.kana_sei = result;
      }
      if (name == 'name_mei') {
        formValues.users.kana_mei = result;
      }
      this.formValues = formValues?.users;
      this.$emit("formUpdate", formValues);
    },
    async getCompanyInfoByCorporateNumber(id) {
      const formValues = { ...this.formValues };
      let users = {};
      const res = await Store.dispatch('Register/getCompanyInfoByCorporateNumber', {
        corporate_number: id,
      });
      let itemRes = res.data.contents;
      if (!itemRes) {
        formValues.users = USER_INIT;
        formValues.ccus_id = '';
        formValues.plate_number_prefix = '';
        formValues.company_code = '';
      } else {
        formValues.company_type = itemRes.company_type ?? '';
        formValues.plate_number_prefix = itemRes.plate_number_prefix ?? '';
        if (itemRes.id) {
          formValues.id = itemRes.id;
        }
        if (itemRes.company_code) {
          formValues.company_code = itemRes.company_code ?? '';
        }
        if (itemRes.corporate_number) {
          formValues.corporate_number = itemRes.corporate_number;
        }
        if (itemRes.name) {
          formValues.name = itemRes.name;
        }
        if (itemRes.name_kana) {
          formValues.name_kana = itemRes.name_kana;
        }
        formValues.ccus_id = itemRes.ccus_id ?? '';
      }
      if (itemRes?.users && itemRes?.users?.email) {
        users = itemRes?.users
      }
      formValues.users = users;
      this.formValues = formValues;
      this.$emit("formUpdate", formValues);
    },

    openListCompany(name) {
      this.$emit('openListCompany', name)
    },

    searchListCompany() {
      this.$emit('searchListCompany')
    }
  },
};
</script>
<style lang="scss">
@import "@/components/forms/style/forms.scss";
.title-input-item .label{
  color: #000000;
}
.title-input-item .v-input--radio-group--column {
  margin-top: 0 !important;
}
</style>
